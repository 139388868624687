import { Box, Icon, Stack, Typography } from '@mui/material';
import {
  DATE_FORMAT_FULL_MONTH_FNS,
  DEFAULT_DATE_FORMAT_FNS,
  Event,
  FilterKeys,
  GetEventsQueryFilters,
  SORT_DIRECTION,
  useGetEventsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { SearchInputFilterIconButton } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  Counter,
  Grid,
  GridContainer,
  Loading,
  ModalSearch,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../../components/common/AccessDenied';
import {
  ModalContentStickyTitle,
  ModalContentStickyWrapper,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { normalizeEvent } from '../../../../context/events/WithEvent';
import { useGroup } from '../../../../context/groups/useGroup';
import { useSchool } from '../../../../hooks/useSchool';
import { EVENT_CREATE_GROUP_ID_SEARCH_PARAM } from '../../../Events/EventCreateModal/EventCreateModal';
import { GroupPreviewModalContent } from '../GroupPreviewModalMessages/GroupPreviewModalMessages.styled';
import { GroupTabEmptyStub } from '../GroupTabEmptyStub';
import { GroupPreviewModalEventRow } from './GroupPreviewModalEventRow';
import { GroupPreviewModalEventsFiltersPopup } from './GroupPreviewModalEventsFiltersPopup';

export const PAGE_SIZE = 30;
export const SKELETON_COLS = 6;

export enum GroupEventsTab {
  Events,
  SignUps,
}

export const GroupPreviewModalEvents: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId = '' } = useSchool();
  const { group, groupId = '' } = useGroup();
  const { permissions } = useAuth();
  const navigate = useNavigate();
  const [isFiltersPopupVisible, openFiltersPopup, closeFiltersPopup] = useFlag(false);

  const defaultFilters: GetEventsQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        group?.validity?.date_from || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        group?.validity?.date_to || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
      [FilterKeys.Group]: [groupId],
    }),
    [group?.validity, groupId],
  );

  const { data, hasNextPage, isFetchingNextPage, isLoading, params, setParams, fetchNextPage } =
    useGetEventsQuery(
      {
        query: '',
        schoolId,
        filters: defaultFilters,
        pageSize: PAGE_SIZE,
        sort: { columnTextId: 'title', direction: SORT_DIRECTION.ASC },
      },
      { refetchOnMount: 'always', enabled: !!group },
    );
  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetEventsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const events = useMemo(
    () =>
      data?.pages.reduce<Event[]>(
        (prev, curr) => [...prev, ...curr.results.map((e) => normalizeEvent(e))],
        [],
      ) ?? [],
    [data?.pages],
  );

  const total = data?.pages[0]?.count;

  const eventsByMonths = useMemo(() => {
    return (
      events?.reduce<Record<string, Event[]>>((prev, event) => {
        const month = format(
          newDateTimezoneOffset(event.start),
          DATE_FORMAT_FULL_MONTH_FNS,
        ).toUpperCase();

        if (!prev[month]) {
          prev[month] = [];
        }

        prev[month].push(event);

        return prev;
      }, {}) || {}
    );
  }, [events]);

  const noResults = !isLoading && !events.length;

  const filtersApplied = Boolean(
    params.filters.recurrence_id?.length ||
      params.filters.event_status?.length ||
      params.filters.invite_type?.length,
  );

  const renderContent = useCallback(() => {
    if (!permissions.includes('event_viewer')) {
      return <AccessDenied />;
    }

    if (!data) return <Loading />;

    return (
      <Stack gap={2.5} width="100%" pb={3}>
        {noResults ? (
          <GroupTabEmptyStub textId="events-NoMatches" />
        ) : (
          <>
            {Object.entries(eventsByMonths).map(([month, events]) => (
              <Box key={month}>
                <Box mb={1.25}>{month}</Box>
                <GridContainer>
                  <Grid>
                    {events.map((event) => (
                      <GroupPreviewModalEventRow key={event.id} event={event} groupId={groupId} />
                    ))}
                  </Grid>
                </GridContainer>
              </Box>
            ))}
            {hasNextPage && (
              <Box py={3}>
                <Loading />
                <div ref={loaderRef} />
              </Box>
            )}
          </>
        )}
      </Stack>
    );
  }, [data, eventsByMonths, groupId, hasNextPage, loaderRef, noResults, permissions]);

  return (
    <ModalContentStickyWrapper>
      <ModalContentStickyTitle
        sx={{
          paddingTop: 0.5,
        }}
      >
        <Stack direction="row" mb={2.5} pt={2} justifyContent="space-between" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="section-Events" />
            {!!total && <Counter>{data.pages[0].count}</Counter>}
          </Typography>

          <Stack direction="row" gap={2.5} alignItems="center">
            <ModalSearch
              value={params.query || ''}
              onChange_MemoizedCallbackOnly={handleSetFiltersQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
              withDebounce
            />
            <Icon
              sx={{
                position: 'relative',
                height: 22,
                '.MuiButtonBase-root': {
                  right: 0,
                  color: 'text.primary',
                  ':hover': {
                    color: 'primary.main',
                  },
                },
              }}
            >
              <SearchInputFilterIconButton
                hasAppliedIndicator={filtersApplied}
                onClick={openFiltersPopup}
              />
            </Icon>

            <SimpleButton
              onClick={() =>
                navigate(`/events/new?${EVENT_CREATE_GROUP_ID_SEARCH_PARAM}=${groupId}`)
              }
              sx={(theme) => ({
                color: theme.palette.text.primary,
                '&:hover': {
                  color: theme.palette.primary.main,
                },
              })}
              startIcon={<PlusIcon />}
            >
              <FormattedMessage id="event-new" />
            </SimpleButton>
          </Stack>
        </Stack>
      </ModalContentStickyTitle>
      <GroupPreviewModalContent empty={isLoading || (!!data && !data.pages[0]?.count)}>
        {renderContent()}
      </GroupPreviewModalContent>
      {isFiltersPopupVisible && (
        <GroupPreviewModalEventsFiltersPopup
          groupId={groupId}
          onClose={closeFiltersPopup}
          onSetFilters={handleSetFilters}
          filters={params.filters}
        />
      )}
    </ModalContentStickyWrapper>
  );
};
