import { Button, Stack, Typography } from '@mui/material';
import { useGetBillingCountsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeader } from '@schooly/components/filters';
import { ArrowRightIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';

import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';
import { allowRenderSchoolLegalEntities } from '../../../helpers/renderRoutesByPermissions';
import AccessDeniedPage from '../../AccessDenied';
import { SchoolProductsContent } from './SchoolProductsContent';
import { SchoolProductsSkeleton } from './SchoolProductsSkeleton';

export const SchoolProducts: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolId = '', permissions } = useAuth();

  const canView =
    permissions.includes('product_and_invoice_viewer') ||
    permissions.includes('registration_manager');
  const hasLegalEntitiesAccess = allowRenderSchoolLegalEntities(permissions);

  const { data: countsData } = useGetBillingCountsQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });
  const hasLegalEntities = Boolean(countsData?.legal_entities.active);

  const renderContent = () => {
    if (!canView) {
      return <AccessDeniedPage />;
    }

    if (!countsData) {
      return <SchoolProductsSkeleton />;
    }

    if (!hasLegalEntities)
      return (
        <>
          <PageHeader pageTitleTextId="products-Title" />
          <Stack alignItems="center" justifyContent="center" flex={1}>
            <Typography variant="h1" textAlign="center" mb={1} maxWidth={450}>
              {formatMessage({ id: 'legalEntities-NoLegalEntities' })}
            </Typography>
            <Typography variant="h3" maxWidth={230} mb={3} textAlign="center">
              {formatMessage({ id: 'products-NoLegalEntitiesDescription' })}
            </Typography>

            {hasLegalEntitiesAccess && (
              <Link to="/settings/legal_entities">
                <Button endIcon={<ArrowRightIcon />}>
                  {formatMessage({ id: 'section-LegalEntitiesAndIntegrations' })}
                </Button>
              </Link>
            )}
          </Stack>
        </>
      );

    return <SchoolProductsContent />;
  };

  return (
    <MainLayout>
      {renderContent()}
      <Outlet />
    </MainLayout>
  );
};
