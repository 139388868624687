import { Stack } from '@mui/material';
import { PaymentFrequencyType } from '@schooly/api';
import { FC, useCallback, useRef } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderFrequencyTags } from './FrequencyExpandedSelect';

type FrequencySelectProps = {
  selectedTypes: PaymentFrequencyType[];
  onSelectType: (v: PaymentFrequencyType) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const PAYMENT_FREQUENCY_TYPES = [
  PaymentFrequencyType.Weekly,
  PaymentFrequencyType.Monthly,
  PaymentFrequencyType.Termly,
  PaymentFrequencyType.Annually,
  PaymentFrequencyType.Biannually,
];

export const FrequencySelectMultiple: FC<FrequencySelectProps> = ({
  selectedTypes,
  onSelectType,
  placeholder,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderFrequencyTags({
          types: PAYMENT_FREQUENCY_TYPES,
          tagProps: (type) => ({
            variant: selectedTypes.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      placeholder={placeholder}
      hasValues={selectedTypes.length > 0}
      renderContent={renderContent}
    >
      {() =>
        renderFrequencyTags({
          types: selectedTypes,
          tagProps: { sx: { maxWidth: 200 }, size: 'small' },
          onDelete: onSelectType,
        })
      }
    </DropdownSelect>
  );
};
