import {
  Chip,
  IconButton,
  Stack,
  TableHeadProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DATE_FORMAT_RANGE_FNS,
  GetPayableFeesQuerySort,
  PayableFee,
  PayerType,
  SORT_DIRECTION,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  ChevronLeftIcon,
  CompanyIcon,
  CopyIcon,
  GridCell,
  GridHead,
  NewTabIcon,
  Price,
  ProfileIcon,
  SortableCell,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { formatPhoneNumberWithCode } from '@schooly/utils/phone-number';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { differenceInDays, format } from 'date-fns';
import { FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { payableFeeStatusColor } from '../../components/common/PayableFees/helpers';

const SPACING = 8 * 2;

export const PAYABLE_FEES_COL_WIDTH = {
  invoiceNum_1: 80 + SPACING,
  invoiceDate_2: 70 + SPACING,
  dueDate_3: 70 + SPACING,
  payer_4: 50 + SPACING,
  student_5: 50 + SPACING,
  discount_6: 100 + SPACING,
  paid_7: 100 + SPACING,
  due_8: 100 + SPACING,
  total_9: 100 + SPACING,
  status_10: undefined, // status is flexible width
  expand_11: 20 + SPACING,
};

type PayableFeesHeaderProps = {
  sort?: GetPayableFeesQuerySort;
  onChangeSort: (v: GetPayableFeesQuerySort) => void;
  rightIcon?: ReactNode;
} & TableHeadProps;

export const PayableFeesHeader: FC<PayableFeesHeaderProps> = ({
  onChangeSort,
  rightIcon,
  sort,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const handleSort = (by: GetPayableFeesQuerySort['by']) => () => {
    onChangeSort({
      by,
      direction:
        by === sort?.by
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  const cellSort = sort ? { ...sort, columnTextId: sort.by } : undefined;

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.invoiceNum_1}>
        {formatMessage({ id: 'payableFees-invoice' })} #
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.invoiceDate_2}
        label={formatMessage({ id: 'payableFees-invoice-date' })}
        sort={cellSort}
        columnTextId="issue_date"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.dueDate_3}
        label={formatMessage({ id: 'payableFees-due-date' })}
        sort={cellSort}
        columnTextId="due_date"
        onChangeSort={handleSort}
      />
      <GridCell width={PAYABLE_FEES_COL_WIDTH.payer_4}>
        {formatMessage({ id: 'payableFees-payer' })}
      </GridCell>
      <GridCell width={PAYABLE_FEES_COL_WIDTH.student_5}>
        {formatMessage({ id: 'payableFees-student' })}
      </GridCell>
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.discount_6}
        label={formatMessage({ id: 'payableFees-discount' })}
        sort={cellSort}
        columnTextId="discount"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.paid_7}
        label={formatMessage({ id: 'payableFees-paid' })}
        sort={cellSort}
        columnTextId="paid"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.due_8}
        label={formatMessage({ id: 'payableFees-due' })}
        sort={cellSort}
        columnTextId="due"
        onChangeSort={handleSort}
      />
      <SortableCell
        width={PAYABLE_FEES_COL_WIDTH.total_9}
        label={formatMessage({ id: 'payableFees-total' })}
        sort={cellSort}
        columnTextId="total"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={formatMessage({ id: 'payableFees-status' })}
        sort={cellSort}
        columnTextId="overdue"
        onChangeSort={handleSort}
      />
      <GridCell width={PAYABLE_FEES_COL_WIDTH.expand_11} />
    </GridHead>
  );
};

type PayableFeeRowProps = {
  payableFee: PayableFee;
};

export const PayableFeeRow: FC<PayableFeeRowProps> = ({ payableFee }) => {
  const [isExpanded, setExpanded] = useState(false);

  const handleExpandClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded((e) => !e);
  };

  const discount = useMemo(() => {
    return payableFee.items.reduce<number>((acc, i) => acc + i.discount_amount, 0);
  }, [payableFee.items]);

  return (
    <>
      <TableRow
        sx={(theme) => ({
          '.avatar, .checkbox': {
            transition: 'opacity .2s',
          },
          td: {
            backgroundColor: isExpanded
              ? `${theme.palette.background.default} !important`
              : undefined,
          },
          '&:hover td': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <GridCell>
          <TypographyWithOverflowHint>
            {payableFee.invoice_number || '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint>
            {payableFee.invoice_date
              ? format(newDateTimezoneOffset(payableFee.invoice_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <TypographyWithOverflowHint>
            {payableFee.due_date
              ? format(newDateTimezoneOffset(payableFee.due_date), DATE_FORMAT_RANGE_FNS)
              : '–'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <PayableFeePayerCellContent fee={payableFee} />
        </GridCell>
        <GridCell>
          <PayableFeeStudentsCellContent fee={payableFee} />
        </GridCell>

        <GridCell>
          <Typography>
            {discount ? (
              <Price
                variant="body1"
                price={discount}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <Typography>
            {payableFee.total_paid ? (
              <Price
                variant="body1"
                price={payableFee.total_paid}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            ) : (
              '–'
            )}
          </Typography>
        </GridCell>
        <GridCell>
          <Price
            variant="body1"
            price={payableFee.total_payment - (payableFee.total_paid || 0)}
            currency={CURRENCY_SYMBOLS[payableFee.currency]}
          />
        </GridCell>
        <GridCell>
          <Price
            variant="body1"
            price={payableFee.total_payment}
            currency={CURRENCY_SYMBOLS[payableFee.currency]}
          />
        </GridCell>
        <GridCell>
          <PayableFeeStatusCellContent fee={payableFee} />
        </GridCell>

        <GridCell>
          <IconButton
            inverse
            onClick={handleExpandClick}
            size="medium"
            sx={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(-90deg)' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </GridCell>
      </TableRow>
      {isExpanded &&
        payableFee.items.map((feeItem, i) => (
          <TableRow key={i}>
            <GridCell borderBottom={false} />
            <GridCell
              py={1.5}
              sx={(theme) => ({
                backgroundColor: `${theme.palette.background.default} !important`,
              })}
              colSpan={4}
            >
              <TypographyWithOverflowHint>{feeItem.label}</TypographyWithOverflowHint>
            </GridCell>
            <GridCell
              py={1.5}
              sx={(theme) => ({
                backgroundColor: `${theme.palette.background.default} !important`,
              })}
            >
              <Typography>
                {feeItem.discount_amount ? (
                  <Price
                    variant="body1"
                    price={feeItem.discount_amount}
                    currency={CURRENCY_SYMBOLS[payableFee.currency]}
                  />
                ) : (
                  '–'
                )}
              </Typography>
            </GridCell>
            <GridCell
              py={1.5}
              sx={(theme) => ({
                backgroundColor: `${theme.palette.background.default} !important`,
              })}
            >
              <Typography>–</Typography>
            </GridCell>
            <GridCell
              py={1.5}
              sx={(theme) => ({
                backgroundColor: `${theme.palette.background.default} !important`,
              })}
            >
              <Typography>–</Typography>
            </GridCell>
            <GridCell
              py={1.5}
              colSpan={3}
              sx={(theme) => ({
                backgroundColor: `${theme.palette.background.default} !important`,
              })}
            >
              <Price
                variant="body1"
                price={feeItem.price_full}
                currency={CURRENCY_SYMBOLS[payableFee.currency]}
              />
            </GridCell>
          </TableRow>
        ))}
    </>
  );
};

const payableFeePayerCellIcon: { [k in PayerType]: ReactNode } = {
  [PayerType.Company]: <CompanyIcon />,
  [PayerType.Default]: <ProfileIcon />,
};

const PayableFeePayerCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderContacts = ({ email, telephone }: { email?: string; telephone?: string }) => {
    if (!email && !telephone) return null;

    return (
      <Stack gap={0.5} mt={1} flex={1}>
        {email && (
          <ContactButton
            title={email}
            copyMessage={formatMessage({ id: 'clipboard-EmailCopied' })}
            copyContent={email}
          />
        )}
        {telephone && (
          <ContactButton
            title={formatPhoneNumberWithCode(telephone)}
            copyMessage={formatMessage({ id: 'clipboard-PhoneCopied' })}
            copyContent={telephone}
          />
        )}
      </Stack>
    );
  };

  const getInfoContent = () => {
    switch (fee.payer.type) {
      case PayerType.Company:
        return (
          <Stack>
            <Typography color="text.primary" textAlign="center" maxWidth={300}>
              {fee.payer.data.name}
            </Typography>
            {renderContacts(fee.payer.data)}
          </Stack>
        );
      case PayerType.Default:
        return (
          <Stack>
            <Typography color="text.primary" textAlign="center" maxWidth={300}>
              {getUserFullName(fee.payer.data)}
            </Typography>
            {renderContacts(fee.payer.data)}
          </Stack>
        );
      default:
        const _: never = fee.payer;
        return _;
    }
  };

  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 'unset',
            px: 1,
          },
        },
      }}
      title={getInfoContent()}
    >
      <IconButton
        sx={(theme) => ({
          border: `1px solid ${theme.palette.divider}`,
          my: -0.75,
          p: 0.75,
          fontSize: theme.spacing(2),
        })}
      >
        {payableFeePayerCellIcon[fee.payer.type]}
      </IconButton>
    </Tooltip>
  );
};

const ContactButton: FC<{ title: ReactNode; copyContent: string; copyMessage: string }> = ({
  title,
  copyContent,
  copyMessage,
}) => {
  const { showNotification } = useNotifications();

  return (
    <Stack
      alignSelf="stretch"
      flexDirection="row"
      justifyContent="space-between"
      py={0.25}
      px={1}
      gap={2}
      sx={(theme) => ({
        borderRadius: theme.spacing(0.5),
        '&:hover': { backgroundColor: theme.palette.background.default, '.icon': { opacity: 1 } },
      })}
    >
      <Typography variant="h3">{title}</Typography>
      <IconButton
        className="icon"
        sx={{ opacity: 0, transition: 'all .2s' }}
        onClick={(e) => {
          e.preventDefault();
          navigator.clipboard.writeText(copyContent);
          showNotification({ message: copyMessage });
        }}
      >
        <CopyIcon />
      </IconButton>
    </Stack>
  );
};

const PayableFeeStudentsCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  if (!fee.students.length) return '–';

  return (
    <Tooltip
      title={
        <Stack m={-1}>
          {fee.students.map((s) => (
            <Stack
              key={s.id}
              flexDirection="row"
              justifyContent="space-between"
              py={0.25}
              px={1}
              gap={2}
              sx={(theme) => ({
                borderRadius: theme.spacing(0.5),
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                  '.icon': { opacity: 1 },
                },
              })}
            >
              <TypographyWithOverflowHint variant="h3">
                {getUserFullName(s)}
              </TypographyWithOverflowHint>
              <IconButton
                className="icon"
                sx={{ fontSize: 15, opacity: 0, transition: 'all .2s' }}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(`/students/${s.id}#payablefees`, '_blank');
                }}
              >
                <NewTabIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      }
    >
      <Typography>{fee.students.length}</Typography>
    </Tooltip>
  );
};

const PayableFeeStatusCellContent: FC<{ fee: PayableFee }> = ({ fee }) => {
  const { formatMessage } = useIntl();

  const renderBadge = () => {
    switch (fee.status) {
      case 'overdue':
      case 'paid':
        const fullyPaidDate = fee.fully_paid_date
          ? newDateTimezoneOffset(fee.fully_paid_date)
          : null;
        const compareDate = fee.status === 'overdue' ? new Date() : fullyPaidDate;

        const formatDistance = compareDate
          ? differenceInDays(newDateTimezoneOffset(fee.due_date), new Date())
          : null;
        const color = fee.status === 'overdue' ? 'error' : 'success';

        if (!formatDistance) return null;

        const label = formatMessage(
          { id: 'payableFees-overdue-days-late' },
          { days: Math.abs(formatDistance) },
        );

        return (
          <Chip
            variant="outlined"
            label={label}
            color={color}
            sx={(theme) => ({
              borderColor: `${theme.palette[color].main} !important`,
              height: theme.spacing(2.5),
              '.MuiChip-label': {
                padding: `${theme.spacing(0, 0.75)} !important`,
              },
            })}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Stack flexDirection="row" alignItems="center" gap={0.5}>
      <Typography color={payableFeeStatusColor[fee.status]}>
        {formatMessage({ id: `payableFees-status-${fee.status}` })}
      </Typography>
      {renderBadge()}
    </Stack>
  );
};
