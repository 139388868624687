import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 2C11.9346 2 14.8 4.86538 14.8 8.4C14.8 11.9346 11.9346 14.8 8.4 14.8C4.86538 14.8 2 11.9346 2 8.4C2 4.86538 4.86538 2 8.4 2ZM11.7535 5.75365C11.9487 5.55839 11.9487 5.24181 11.7535 5.04654C11.5582 4.85128 11.2416 4.85128 11.0463 5.04654L8.3999 7.69299L5.75346 5.04654C5.55819 4.85128 5.24161 4.85128 5.04635 5.04654C4.85109 5.24181 4.85109 5.55839 5.04635 5.75365L7.6928 8.4001L5.04635 11.0465C4.85109 11.2418 4.85109 11.5584 5.04635 11.7536C5.24161 11.9489 5.55819 11.9489 5.75346 11.7536L8.3999 9.1072L11.0463 11.7537C11.2416 11.9489 11.5582 11.9489 11.7535 11.7537C11.9487 11.5584 11.9487 11.2418 11.7535 11.0465L9.10701 8.4001L11.7535 5.75365Z"
      fill="#EB5757"
    />
  </svg>
);
