import { PayableFeeStatus } from '@schooly/api';
import { useSearchParams } from 'react-router-dom';

import { HSBColor } from './chartBar/utils';

export const CHARTS_SEARCH_PARAM = 'charts';
export const ARRANGE_BY_SEARCH_PARAM = 'arrange_by';
export const ZEROES_SEARCH_PARAM = 'zeroes';

export const useChartOpenFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const chartsQueryParamValue = searchParams.get(CHARTS_SEARCH_PARAM);
  const arrangeByQueryParamValue = searchParams.get(ARRANGE_BY_SEARCH_PARAM);

  return (
    chartsQueryParamValue === 'true' ||
    Boolean(arrangeByQueryParamValue && chartsQueryParamValue == null)
  );
};

export const useChartZeroesFromSearchParams = () => {
  const [searchParams] = useSearchParams();
  const zeroesQueryParamValue = searchParams.get(ZEROES_SEARCH_PARAM);

  return zeroesQueryParamValue === 'true' || zeroesQueryParamValue == null;
};

interface PayableFeesStatusColor {
  key: PayableFeeStatus;
  color: HSBColor;
}

export const payableFeesStatusColorPalette: PayableFeesStatusColor[] = [
  {
    key: 'paid',
    color: {
      h: 145,
      s: 46,
      b: 81,
    },
  },
  {
    key: 'due',
    color: {
      h: 28,
      s: 69,
      b: 95,
    },
  },
  {
    key: 'overdue',
    color: {
      h: 0,
      s: 63,
      b: 92,
    },
  },
  {
    key: 'projected',
    color: {
      h: 240,
      s: 60,
      b: 40,
    },
  },
];

export const numberFormatter = (value: number) => new Intl.NumberFormat('en-US').format(value);

export const payableFeesSeriesLabelFormatter = (params: any) => {
  return numberFormatter(params.value as number);
};

export const payableFeesAxisValueFormatter = (value: number) => {
  if (value >= 1000000) return `${value / 1000000}M`;
  if (value >= 1000) return `${value / 1000}k`;
  return value;
};

export const getPayableFeesStatusLabel = (key: string) => {
  return [
    { key: 'paid', label: 'payableFees-status-paid' },
    { key: 'overdue', label: 'payableFees-status-overdue' },
    { key: 'due', label: 'payableFees-due' },
    { key: 'projected', label: 'payableFees-status-projected' },
  ].find(({ key: status }) => key === status)?.label;
};
