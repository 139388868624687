import { Icon, Stack, styled, Tooltip, Typography } from '@mui/material';
import { SchoolVerificationStatus, useGetSchoolQuery, UserRolePermission } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AssessmentsIcon,
  AttendanceIcon,
  Attention2Icon,
  CompanyIcon,
  ConductIcon,
  EventsIcon,
  EyeIcon,
  GroupsIcon,
  InvoiceIcon,
  MailIcon,
  ParentIcon,
  PlannerIcon,
  ReportsIcon,
  SettingsIcon,
  SimpleButton,
  StaffIcon,
  StudentIcon,
  UserRoleIcon,
  WithShowOnClick,
} from '@schooly/style';
import { mobileDetect } from '@schooly/utils/mobile';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatch, useNavigate } from 'react-router';

import {
  allowRenderAssessments,
  allowRenderAttendance,
  allowRenderCompanies,
  allowRenderConduct,
  allowRenderEvents,
  allowRenderGroups,
  allowRenderMessages,
  allowRenderParents,
  allowRenderPayableFees,
  allowRenderSchoolProducts,
  allowRenderStaff,
  allowRenderStudents,
  allowRenderUserRoles,
} from '../../helpers/renderRoutesByPermissions';
import { useSchool } from '../../hooks/useSchool';
import useUserCounts from '../../hooks/useUserCounts';
import NavItem from './NavItem';

const NavMenu: React.FC = () => {
  const { isSchoolAdmin } = useSchool();
  const { userCounts } = useUserCounts();
  const { formatMessage } = useIntl();
  const { permissions, schoolId = '' } = useAuth();
  const isMobile = mobileDetect.mobile();
  const navigate = useNavigate();

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  const isSignUpsPath = useMatch('/signups/*');

  const { non_member_adults = 0, application = 0 } = userCounts || {};

  const showAnnualPlanner =
    permissions.includes('planner_viewer') &&
    (['assessment_viewer', 'event_viewer'] as UserRolePermission[]).some((perm) =>
      permissions.includes(perm),
    );

  const showPeopleSection = (
    ['student_viewer', 'parent_viewer', 'staff_viewer'] as UserRolePermission[]
  ).find((perm) => permissions.includes(perm));

  const showPayableFees = allowRenderPayableFees(permissions);

  const showActivitySection = (
    [
      'message_viewer',
      'assessment_viewer',
      'conduct_viewer',
      'attendance_viewer',
      'group_viewer',
    ] as UserRolePermission[]
  ).find((perm) => permissions.includes(perm));

  const showUserRoles = allowRenderUserRoles(permissions);
  const showSchoolSettings = isSchoolAdmin || allowRenderSchoolProducts(permissions);
  const showConfigurationSection = showSchoolSettings || showUserRoles;
  const showInvoicingSection = showPayableFees;

  const { studentsIndicatorContent, staffIndicatorContent } = useMemo(() => {
    const applicationViewer = permissions.includes('application_viewer');
    const staffManager =
      permissions.includes('staff_manager') && permissions.includes('employment_manager');

    const studentsIndicatorContent =
      applicationViewer && !!application ? (
        <Stack gap={1} alignItems="flex-start">
          <Typography>{formatMessage({ id: 'applications-thereAreApplications' })}</Typography>
          <SimpleButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigate('/applications');
            }}
            startIcon={<EyeIcon />}
          >
            {formatMessage({ id: 'staff-ViewList' })}
          </SimpleButton>
        </Stack>
      ) : undefined;
    const staffIndicatorContent =
      staffManager && !!non_member_adults ? (
        <Stack gap={1} alignItems="flex-start">
          <Typography>{formatMessage({ id: 'staff-thereAreProfiles' })}</Typography>
          <SimpleButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              navigate('/staff/new');
            }}
            startIcon={<EyeIcon />}
          >
            {formatMessage({ id: 'staff-ViewList' })}
          </SimpleButton>
        </Stack>
      ) : undefined;

    return { staffIndicatorContent, studentsIndicatorContent };
  }, [permissions, application, formatMessage, non_member_adults, navigate]);

  return (
    <>
      {showAnnualPlanner && (
        <Section className="list-unstyled">
          <NavItem
            link="/planner"
            icon={<PlannerIcon />}
            titleTextId="section-AnnualPlanner"
            testId="sidenav-annualPlanner"
          />
        </Section>
      )}

      {showPeopleSection && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-People" />
          </SectionTitle>
          <Section className="list-unstyled">
            {allowRenderStudents(permissions) && (
              <NavItem
                link="/students"
                icon={<StudentIcon />}
                titleTextId="section-Students"
                indicatorTooltip={studentsIndicatorContent}
                testId="sidenav-students"
              />
            )}
            {allowRenderParents(permissions) && (
              <NavItem
                link="/parents"
                icon={<ParentIcon />}
                titleTextId="section-Parents"
                testId="sidenav-parents"
              />
            )}
            {allowRenderStaff(permissions) && (
              <NavItem
                link="/staff"
                icon={<StaffIcon />}
                titleTextId="section-Staff"
                indicatorTooltip={staffIndicatorContent}
                testId="sidenav-staff"
              />
            )}
            {allowRenderCompanies(permissions) && (
              <NavItem
                link="/companies"
                icon={<CompanyIcon />}
                titleTextId="section-Companies"
                testId="sidenav-companies"
              />
            )}
          </Section>
        </>
      )}
      {showActivitySection && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-Activity" />
          </SectionTitle>
          <Section className="list-unstyled">
            {allowRenderGroups(permissions) && (
              <NavItem
                link="/groups"
                icon={<GroupsIcon />}
                titleTextId="section-Groups"
                testId="sidenav-groups"
              />
            )}
            {allowRenderMessages(permissions) && (
              <NavItem
                link="/messages"
                icon={<MailIcon />}
                titleTextId="section-Messages"
                testId="sidenav-messages"
              />
            )}
            {allowRenderAssessments(permissions) && (
              <>
                <NavItem
                  link="/assessments"
                  icon={<AssessmentsIcon />}
                  titleTextId="section-Assessments"
                  testId="sidenav-assessments"
                />
                <NavItem
                  link="/reports"
                  icon={<ReportsIcon />}
                  titleTextId="section-Reports"
                  testId="sidenav-reports"
                />
              </>
            )}

            {allowRenderAttendance(permissions) && (
              <NavItem
                link="/attendance"
                icon={<AttendanceIcon />}
                titleTextId="section-Attendance"
                testId="sidenav-attendance"
              />
            )}

            {allowRenderConduct(permissions) && (
              <NavItem
                link="/conduct"
                icon={<ConductIcon />}
                titleTextId="section-Conduct"
                testId="sidenav-conduct"
              />
            )}
            {allowRenderEvents(permissions) && (
              <NavItem
                link={isSignUpsPath ? '/signups' : '/events'}
                icon={<EventsIcon />}
                titleTextId="section-EventsAndSignUps"
                testId="sidenav-events"
              />
            )}
          </Section>
        </>
      )}
      {showInvoicingSection && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-Invoicing" />
          </SectionTitle>
          <Section className="list-unstyled">
            {showPayableFees && (
              <NavItem
                link="/payablefees"
                icon={<InvoiceIcon />}
                titleTextId="section-payableFees"
                testId="sidenav-payableFees"
              />
            )}
          </Section>
        </>
      )}

      {showConfigurationSection && (
        <>
          <SectionTitle variant="h4">
            <FormattedMessage id="section-Configuration" />
          </SectionTitle>
          <Section className="list-unstyled">
            {showSchoolSettings && (
              <Stack
                flexDirection="row"
                alignItems="center"
                mb={0.5}
                mr={1}
                justifyContent="space-between"
                gap={1}
              >
                <NavItem
                  link="/settings"
                  icon={<SettingsIcon />}
                  titleTextId="section-SchoolSettings"
                  testId="sidenav-settings"
                />
                {!!currentSchool &&
                  currentSchool.verification_status === SchoolVerificationStatus.NotRequested && (
                    <WithShowOnClick showOnClick={!!isMobile}>
                      {(onClick, show) => (
                        <Tooltip
                          open={show}
                          title={<FormattedMessage id="school-updateSchoolDetails" />}
                        >
                          <Icon
                            sx={(theme) => ({
                              position: 'relative',
                              marginBottom: theme.spacing(0.75),
                              left: theme.spacing(-0.75),

                              '.svg-icon': {
                                path: { fill: theme.palette.warning.main },
                                ':hover path': { fill: theme.palette.text.primary },
                              },
                            })}
                            onClick={onClick}
                          >
                            <Attention2Icon className="reset-svg-currentColor" />
                          </Icon>
                        </Tooltip>
                      )}
                    </WithShowOnClick>
                  )}
              </Stack>
            )}

            {showUserRoles && (
              <NavItem
                link="/userroles"
                icon={<UserRoleIcon />}
                titleTextId="section-UserRoles"
                testId="sidenav-userroles"
              />
            )}
          </Section>
        </>
      )}
    </>
  );
};

const SectionTitle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0.5, 1),
}));

const Section = styled('ul')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export default NavMenu;
