import { Typography } from '@mui/material';
import { Product, ProductTypeId, SORT_DIRECTION, useGetProductsListQuery } from '@schooly/api';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { ProductSelectRow } from './ProductSelectRow';
import { ProductTagSelect, ProductTagSelectProps } from './ProductTagSelect';
import { ProductTypeTagSelect, ProductTypeTagSelectProps } from './ProductTypeTagSelect';

type ProductExpandedSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedProductIds: string[];
  selectedProductTypeIds: ProductTypeId[];
  onSelectProductId: (v: string) => void;
  onSelectProductTypeId: (v: ProductTypeId) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const ProductExpandedSelect: FC<ProductExpandedSelectProps> = ({
  schoolId,
  selectedProductIds,
  selectedProductTypeIds,
  onSelectProductId,
  onSelectProductTypeId,
  onClose,
  onClear,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { isLoading, data, params, isFetchingNextPage, fetchNextPage, hasNextPage, setParams } =
    useGetProductsListQuery(
      { schoolId, query: '', sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC } },
      { refetchOnMount: 'always' },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Product[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    const handleSelectProductType = (typeId: ProductTypeId) => {
      const relatedProduct = entries.find((p) => p.id === typeId.productId);

      if (
        relatedProduct &&
        selectedProductTypeIds.length + 1 === relatedProduct.types.length &&
        !selectedProductTypeIds.some((id) => id.isEqual(typeId))
      ) {
        onSelectProductId(relatedProduct.id);
        return;
      }

      onSelectProductTypeId(typeId);
    };

    return (
      <>
        {entries.map((product) => (
          <ProductSelectRow
            onSelectProduct={(id) => {
              onSelectProductId(id);
              handleChangeQuery('');
            }}
            onSelectProductType={handleSelectProductType}
            key={product.id}
            product={product}
            selectedProductTypes={selectedProductTypeIds.filter(
              (id) => id.productId === product.id,
            )}
            isSelected={selectedProductIds.includes(product.id)}
          />
        ))}
      </>
    );
  }, [
    data,
    selectedProductTypeIds,
    selectedProductIds,
    onSelectProductTypeId,
    onSelectProductId,
    handleChangeQuery,
  ]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedProductIds.length > 0 || selectedProductTypeIds.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
      width={400}
    >
      {renderProductTags({
        ids: selectedProductIds,
        tagProps: { sx: { maxWidth: 200 }, size: 'small' },
        onDelete: onSelectProductId,
      })}
      {renderProductTypeTags({
        ids: selectedProductTypeIds,
        tagProps: { sx: { maxWidth: 200 }, size: 'small' },
        onDelete: onSelectProductTypeId,
      })}

      <SelectSearchInput
        ref={inputRef}
        autoFocus
        value={params.query || ''}
        onChangeText={handleChangeQuery}
      />
    </ExpandedSelect>
  );
};

type RenderProductTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps?: Omit<ProductTagSelectProps, 'id'> | ((v: string) => Omit<ProductTagSelectProps, 'id'>);
};

export const renderProductTags = ({ ids, onDelete, onClick, tagProps }: RenderProductTagsParams) =>
  ids.map((v) => (
    <ProductTagSelect
      key={v}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));

type RenderProductTypeTagsParams = {
  ids: ProductTypeId[];
  onDelete?: (v: ProductTypeId) => void;
  onClick?: (v: ProductTypeId) => void;
  tagProps?:
    | Omit<ProductTypeTagSelectProps, 'id'>
    | ((v: ProductTypeId) => Omit<ProductTypeTagSelectProps, 'id'>);
};

export const renderProductTypeTags = ({
  ids,
  onDelete,
  onClick,
  tagProps,
}: RenderProductTypeTagsParams) =>
  ids.map((v) => (
    <ProductTypeTagSelect
      key={v.toString()}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
