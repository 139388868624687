import { useUserContext } from '@schooly/components/authentication';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useAppLocation from '../hooks/useAppLocation';

export const RouteRequireAuth: React.FC = () => {
  const { search } = useAppLocation();
  const { user: syncUser } = useUserContext();

  if (!syncUser) {
    return <Navigate to={{ pathname: '/', search }} />;
  }

  return <Outlet />;
};
