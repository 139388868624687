import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';

const DISPLAY_ROWS = 2;

export const SchoolGeneralSubjects: FC = () => {
  const { schoolId = '' } = useAuth();
  const { activeSubjects, isLoading } = useSubjects(
    { schoolId },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const [expanded, setExpanded] = useState(false);

  const count = activeSubjects?.length ?? 0;
  const hasRows = count > 0;
  const canExpand = !isLoading && count > DISPLAY_ROWS;

  const rows = useMemo(
    () => activeSubjects?.slice(0, !canExpand || expanded ? undefined : DISPLAY_ROWS) ?? [],
    [activeSubjects, canExpand, expanded],
  );

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  return (
    <Card component={Stack} sx={{ minHeight: 224 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage id="school-tabs-Subjects" />
            {isLoading ? (
              <Skeleton
                variant="circular"
                sx={(theme) => ({
                  ml: 1,
                  width: theme.spacing(2.5),
                  height: theme.spacing(2.5),
                })}
              />
            ) : hasRows ? (
              <Counter>{count}</Counter>
            ) : null}
          </Stack>
        }
        action={
          hasRows ? (
            <Link to="/settings/subjects">
              <IconButton inverse>
                <EditIcon />
              </IconButton>
            </Link>
          ) : undefined
        }
      />
      <CardContent
        component={Stack}
        sx={{ flex: '1', justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <GridContainer
          sx={{
            borderRadius: 0,
            borderBottom: (theme) =>
              !isLoading && hasRows ? theme.mixins.borderValue() : undefined,
          }}
        >
          {isLoading ? (
            <Stack>
              {new Array(3).fill(true).map((_, index) => (
                <GridRowStyled key={index} noBorder noBorderRadius>
                  <GridRowItem>
                    <GridRowName>
                      <Skeleton />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Stack>
          ) : hasRows ? (
            rows.map((subject) => (
              <GridRowStyled key={subject.id} noBorderRadius>
                <GridRowItem>
                  <GridRowName>{subject.name}</GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))
          ) : (
            <Typography variant="h3">
              <FormattedMessage id="school-tabs-Subjects-NoActive" />
            </Typography>
          )}
        </GridContainer>

        {canExpand && (
          <SimpleButton sx={{ mt: 2.5 }} onClick={toggleExpand}>
            {expanded ? (
              <FormattedMessage id="action-Hide" />
            ) : (
              <FormattedMessage
                id="action-ViewMore-number"
                values={{ number: count - rows.length }}
              />
            )}
          </SimpleButton>
        )}

        {!hasRows && (
          <Link to="/settings/subjects">
            <SimpleButton sx={{ mt: 2.5 }} startIcon={<PlusIcon />} onClick={toggleExpand}>
              <FormattedMessage id="school-tabs-Subjects-AddSubject" />
            </SimpleButton>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};
