import { Stack } from '@mui/material';
import { PayableFeeStatus } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { PAYABLE_FEE_STATUSES } from './FeeStatusSelectMultiple';
import { FeeStatusTagSelect, FeeStatusTagSelectProps } from './FeeStatusTagSelect';

type FeeStatusExpandedSelectProps = PropsWithChildren<{
  selectedStatuses: PayableFeeStatus[];
  onSelectStatus: (v: PayableFeeStatus) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const FeeStatusExpandedSelect: FC<FeeStatusExpandedSelectProps> = ({
  selectedStatuses,
  onSelectStatus,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderFeeStatusTags({
          statuses: PAYABLE_FEE_STATUSES,
          tagProps: (type) => ({
            variant: selectedStatuses.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectStatus,
        })}
      </Stack>
    );
  }, [onSelectStatus, selectedStatuses]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedStatuses.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
    >
      {renderFeeStatusTags({
        statuses: selectedStatuses,
        tagProps: { sx: { maxWidth: 200 }, size: 'small' },
        onDelete: onSelectStatus,
      })}
    </ExpandedSelect>
  );
};

type RenderFeeStatusTagsParams = {
  statuses: PayableFeeStatus[];
  onDelete?: (v: PayableFeeStatus) => void;
  onClick?: (v: PayableFeeStatus) => void;
  tagProps?:
    | Omit<FeeStatusTagSelectProps, 'status'>
    | ((v: PayableFeeStatus) => Omit<FeeStatusTagSelectProps, 'status'>);
};

export const renderFeeStatusTags = ({
  statuses,
  onDelete,
  onClick,
  tagProps,
}: RenderFeeStatusTagsParams) =>
  statuses.map((v) => (
    <FeeStatusTagSelect
      key={v}
      status={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
