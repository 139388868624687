import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 2C11.9346 2 14.8 4.86538 14.8 8.4C14.8 11.9346 11.9346 14.8 8.4 14.8C4.86538 14.8 2 11.9346 2 8.4C2 4.86538 4.86538 2 8.4 2ZM4.37582 7.92506L7.80007 11.3493L12.7251 6.42449C12.9595 6.19018 12.9595 5.81028 12.7252 5.57596C12.4908 5.34164 12.1109 5.34163 11.8766 5.57594L7.80009 9.65228L5.22435 7.07653C4.99003 6.84222 4.61013 6.84222 4.37582 7.07653C4.1415 7.31085 4.1415 7.69075 4.37582 7.92506Z"
      fill="#6FCF97"
    />
  </svg>
);
