import { DialogProps } from '@mui/material/Dialog/Dialog';
import { useFlag } from '@schooly/hooks/use-flag';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import React, { ReactNode, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { ConfirmationDialog } from './ConfirmationDialog';

export interface IDialogConfig extends Omit<DialogProps, 'open' | 'children' | 'content'> {
  message?: string;
  textId?: string;
  textValues?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
  confirmTextId?: string;
  cancelTextId?: string;
  callback: (value?: boolean) => void;
  content?: ReactNode;
  cancelOnClose?: boolean;
}

interface IConfirmationDialogContext {
  openDialog: (config: IDialogConfig) => void;
}

const defaultContext: IConfirmationDialogContext = {
  openDialog: () => {},
};

export const ConfirmationDialogContext = React.createContext(defaultContext);

ConfirmationDialogContext.displayName = 'ConfirmationDialogContext';

export const ConfirmationDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const { formatMessage } = useIntl();
  const [isDialogOpen, showDialog, hideDialog] = useFlag();
  const [dialogConfig, setDialogConfig] = useState<IDialogConfig>();

  const openDialog = useCallback(
    ({
      message,
      textId,
      textValues,
      confirmTextId,
      cancelTextId,
      callback,
      onClick,
      sx,
      content,
      cancelOnClose = true,
    }: IDialogConfig) => {
      showDialog();
      setDialogConfig({
        message,
        textId,
        textValues,
        confirmTextId,
        cancelTextId,
        callback,
        onClick,
        sx,
        content,
        cancelOnClose,
      });
    },
    [showDialog],
  );

  const resetDialog = () => {
    hideDialog();
    setDialogConfig(undefined);
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig?.callback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig?.callback(false);
  };

  const onClose = () => {
    resetDialog();
    dialogConfig?.callback();
  };

  const message = dialogConfig?.textId
    ? formatMessage(
        {
          id: dialogConfig.textId,
          defaultMessage: dialogConfig.message,
        },
        dialogConfig.textValues,
      )
    : dialogConfig?.message;

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      {children}
      <ConfirmationDialog
        isOpen={isDialogOpen}
        onConfirm={onConfirm}
        onClose={dialogConfig?.cancelOnClose ? undefined : onClose}
        onCancel={onDismiss}
        titleTextId={message}
        confirmTextId={dialogConfig?.confirmTextId}
        cancelTextId={dialogConfig?.cancelTextId}
        onClick={dialogConfig?.onClick}
        disablePortal
        disableEnforceFocus
        disableScrollLock
        sx={dialogConfig?.sx}
        content={dialogConfig?.content}
      />
    </ConfirmationDialogContext.Provider>
  );
};
