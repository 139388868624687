import { Icon, IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  SchoolYear,
  SchoolYearPeriod,
  SchoolYearPeriodGroup,
} from '@schooly/api';
import { ArrowAngleIcon, MinusIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { isNotEmpty } from '@schooly/utils/predicates';
import { addDays, format, isAfter, isBefore, isPast } from 'date-fns';
import { FC, useCallback, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { SchoolPeriodRangePicker } from './SchoolPeriodRangePicker';
import { SchoolPeriodGroupsForm } from './SchoolPeriodUpdateContent';
import { usePeriodValidation } from './usePeriodValidation';

export interface PeriodRowProps {
  groupIdx: number;
  periodIdx: number;
  schoolYear?:
    | (Omit<SchoolYear, 'period_groups'> & {
        period_groups?: Array<
          Omit<SchoolYearPeriodGroup, 'periods'> & {
            periods: Array<SchoolYearPeriod & { isStarted?: boolean }>;
          }
        >;
      } & { isCurrent?: boolean })
    | null;
  isStarted: boolean;
  onPeriodsSort: () => void;
  onDelete: (idx: number) => void;
  canEdit: boolean;
}

export interface PeriodGroupRef {
  validate: () => void;
}
export const PeriodRow: FC<PeriodRowProps> = ({
  groupIdx,
  periodIdx,
  schoolYear,
  isStarted,
  onPeriodsSort,
  onDelete,
  canEdit,
}) => {
  const { watch, control, setError, formState, getValues, clearErrors } =
    useFormContext<SchoolPeriodGroupsForm>();
  const { $t } = useIntl();
  const currentPeriodPath = `period_groups.${groupIdx}.periods.${periodIdx}` as const;
  const periodsPath = `period_groups.${groupIdx}.periods` as const;
  const periodStartDatePath = `${currentPeriodPath}.date_from` as const;
  const periodEndDatePath = `${currentPeriodPath}.date_to` as const;

  const periods = watch(periodsPath);
  const currentPeriod = watch(currentPeriodPath);
  const errors = formState.errors.period_groups?.[groupIdx]?.periods?.[periodIdx];
  const [isNotEdited, setIsNotEdited] = useState(!currentPeriod.originId);
  const { validatePastDate, validatePeriodsOverlap } = usePeriodValidation();

  const { update } = useFieldArray({
    control,
    name: periodsPath,
  });

  const startDate = !!currentPeriod.date_from
    ? newDateTimezoneOffset(currentPeriod.date_from)
    : null;
  const endDate = !!currentPeriod.date_to ? newDateTimezoneOffset(currentPeriod.date_to) : null;

  const validateDate = useCallback(
    (startDate: string, endDate: string, currentSchoolYear: SchoolYear) => {
      if (startDate && !endDate) {
        return [
          {
            path: periodEndDatePath,
            message: $t({ id: 'school-schoolPeriods-ErrorDates' }),
          },
        ];
      }

      // Checks after submit
      if (!startDate || !endDate || isStarted) return;

      if (errors?.date_from) clearErrors(periodStartDatePath);
      if (errors?.date_to) clearErrors(periodEndDatePath);

      const isStartDateInPast = validatePastDate(startDate);
      if (isStartDateInPast) {
        return [
          {
            path: periodStartDatePath,
            message: $t({ id: 'school-schoolPeriods-ErrorPeriodInPast' }),
          },
        ];
      }

      const isBeforeSchoolYear = isBefore(
        newDateTimezoneOffset(startDate),
        newDateTimezoneOffset(currentSchoolYear.start),
      )
        ? {
            path: periodStartDatePath,
            message: $t({ id: 'school-schoolPeriods-ErrorPeriodOverlapsTheYear' }),
          }
        : null;

      const isAfterSchoolYear = isAfter(
        newDateTimezoneOffset(endDate),
        newDateTimezoneOffset(currentSchoolYear.end),
      )
        ? {
            path: periodEndDatePath,
            message: $t({ id: 'school-schoolPeriods-ErrorPeriodOverlapsTheYear' }),
          }
        : null;

      if (isBeforeSchoolYear || isAfterSchoolYear) {
        return [isBeforeSchoolYear, isAfterSchoolYear].filter(isNotEmpty);
      }

      const overlapErrors = [];
      const overlapErrorMessage = $t({ id: 'school-schoolPeriods-ErrorPeriodOverlapsPrevPeriod' });
      for (const [index, { date_from, date_to, id }] of periods.entries()) {
        if (!date_from || !date_to) continue;

        const isPeriodOverlaps = validatePeriodsOverlap(
          date_from,
          date_to,
          periods.filter((p) => p.id !== id && !!p.date_from && !!p.date_to),
        );

        if (isPeriodOverlaps && id === currentPeriod.id) {
          overlapErrors.push(
            { path: periodStartDatePath, message: overlapErrorMessage },
            { path: periodEndDatePath, message: overlapErrorMessage },
          );
        }

        if (isPeriodOverlaps || id === currentPeriod.id) continue;

        // Removes overlap error in other period if needed
        const startDatePath = `period_groups.${groupIdx}.periods.${index}.date_from` as const;
        const endDatePath = `period_groups.${groupIdx}.periods.${index}.date_to` as const;
        const currentPeriodErrors = formState.errors.period_groups?.[groupIdx]?.periods?.[index];

        if (currentPeriodErrors?.date_from?.message === overlapErrorMessage) {
          clearErrors(startDatePath);
        }
        if (currentPeriodErrors?.date_to?.message === overlapErrorMessage) {
          clearErrors(endDatePath);
        }
      }

      return overlapErrors.length ? overlapErrors : undefined;
    },
    [
      $t,
      clearErrors,
      currentPeriod.id,
      errors?.date_from,
      errors?.date_to,
      formState.errors,
      groupIdx,
      isStarted,
      periodEndDatePath,
      periodStartDatePath,
      periods,
      validatePastDate,
      validatePeriodsOverlap,
    ],
  );

  const handleSetDate = useCallback(
    (date: Date) => {
      if (isNotEdited) setIsNotEdited(false);

      const dateFrom = currentPeriod.date_from;
      const dateTo = currentPeriod.date_to;
      const newDate = format(date, DEFAULT_DATE_FORMAT_FNS);

      if (dateFrom && dateTo) {
        update(periodIdx, { ...currentPeriod, date_from: newDate, date_to: '' });
      } else if (!dateFrom) {
        update(periodIdx, { ...currentPeriod, date_from: newDate });
      } else if (isBefore(date, newDateTimezoneOffset(dateFrom))) {
        update(periodIdx, {
          ...currentPeriod,
          date_from: newDate,
          date_to: dateFrom,
        });
      } else if (!dateTo) {
        update(periodIdx, {
          ...currentPeriod,
          date_to: newDate,
        });
      }
    },
    [currentPeriod, isNotEdited, periodIdx, update],
  );

  const handlePeriodRemove = useCallback(() => {
    onDelete(periodIdx);
  }, [onDelete, periodIdx]);

  const handleRangePickerClose = useCallback(() => {
    if (isNotEdited) setIsNotEdited(false);
    if (!schoolYear) {
      throw new Error($t({ id: 'school-schoolPeriods-ErrorSchoolYear' }));
    }

    const { date_from, date_to } = getValues(currentPeriodPath);
    const validationData = validateDate(date_from, date_to, schoolYear);

    if (validationData?.length) {
      validationData.forEach(({ path, message }) => {
        setError(path, {
          type: 'validate',
          message,
        });
      });
    } else onPeriodsSort();
  }, [
    $t,
    getValues,
    isNotEdited,
    onPeriodsSort,
    currentPeriodPath,
    schoolYear,
    setError,
    validateDate,
  ]);

  const presetDate = useMemo(() => {
    if (currentPeriod.date_from) return;

    const prevPeriods = periods.slice(0, periodIdx).reverse();
    const prevPeriodEndDate = prevPeriods.find((period) => period.date_to)?.date_to;
    if (prevPeriodEndDate) {
      const nextDay = addDays(newDateTimezoneOffset(prevPeriodEndDate), 1);
      return !isPast(nextDay) ? nextDay : undefined;
    }

    const schoolYearStart = newDateTimezoneOffset(schoolYear?.start);
    return !isPast(schoolYearStart) ? schoolYearStart : undefined;
  }, [currentPeriod.date_from, periodIdx, periods, schoolYear?.start]);

  if (!schoolYear) return null;

  return (
    <Stack direction="row" alignItems="flex-start" gap={1}>
      <Icon
        sx={(theme) => ({
          display: 'flex',
          mt: 1,
          color: theme.palette.common.grey,
        })}
      >
        <ArrowAngleIcon />
      </Icon>
      <Stack
        sx={(theme) => ({
          padding: theme.spacing(1),
          border: theme.mixins.borderValue(),
          borderColor: theme.palette.common.light3,
          borderRadius: theme.spacing(1),
          flex: '1 1 36%',
          whiteSpace: 'nowrap',
          maxWidth: 210,
          overflow: 'hidden',
        })}
      >
        {currentPeriod.name}
      </Stack>

      <Stack flex="1 1 60%">
        <SchoolPeriodRangePicker
          startDate={startDate}
          endDate={endDate}
          onSetDate={handleSetDate}
          disabled={!canEdit}
          startDateError={errors?.date_from}
          endDateError={errors?.date_to}
          opened={isNotEdited || undefined}
          onClose={handleRangePickerClose}
          presetDate={presetDate}
          DateRangeSelectContentProps={{
            disablePast: true,
            shouldDisableDay: (date) =>
              isBefore(date, newDateTimezoneOffset(schoolYear.start)) ||
              isAfter(date, newDateTimezoneOffset(schoolYear.end)),
          }}
        />
      </Stack>

      <IconButton
        sx={{ pt: 1.25, pl: 0.75, display: 'flex', visibility: canEdit ? 'visible' : 'hidden' }}
        size="small"
        inverse
        onClick={handlePeriodRemove}
      >
        <MinusIcon />
      </IconButton>
    </Stack>
  );
};
