import { Box, MenuItem, MenuList, Stack, Tooltip } from '@mui/material';
import { ParentSchoolRelation, useGetEnrollments } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ModalSidebarItem } from '../../components/ui/ModalSidebar';
import { ModalSideBar } from '../../components/uikit-components/Modal/Modal.styled';
import { ProfileModalMode } from '../../context/profile/helpers';
import { useProfile } from '../../context/profile/useProfile';
import useAppLocation from '../../hooks/useAppLocation';
import { useSchool } from '../../hooks/useSchool';

export const ProfileModalLeftSidebar: FC = () => {
  const location = useAppLocation();
  const { mode, actions, userType, user } = useProfile();
  const { permissions } = useAuth();
  const { schoolId = '' } = useSchool();
  const studentRelationId = user?.relation_id ?? '';
  const isStudent = userType === 'student';
  const { $t } = useIntl();

  const { data } = useEnrollmentPooling({
    params: { schoolId, studentId: studentRelationId },
    enabled: Boolean(schoolId && isStudent && studentRelationId),
  });

  const handleClick = useCallback(
    (to: ProfileModalMode) => () => {
      actions.setMode(to);
    },
    [actions],
  );

  const tabs: ModalSidebarItem<ProfileModalMode>[] = useMemo(() => {
    const hasMessageOrEventViewer =
      permissions.includes('message_viewer') || permissions.includes('event_viewer');

    switch (userType) {
      case 'staff': {
        const baseTabs = [
          { id: ProfileModalMode.About, title: 'profile-About' },
        ] as ModalSidebarItem<ProfileModalMode>[];

        if (permissions.includes('staff_manager')) {
          baseTabs.push({ id: ProfileModalMode.EmploymentCases, title: 'profile-EmploymentCases' });
        }

        if (permissions.includes('group_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Groups, title: 'profile-Groups' });
        }

        if (permissions.includes('message_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Messages, title: 'profile-Messages' });
        }

        if (permissions.includes('userrole_manager') || permissions.includes('userrole_viewer')) {
          baseTabs.push({ id: ProfileModalMode.UserRoles, title: 'profile-UserRoles' });
        }

        return baseTabs;
      }
      case 'parent': {
        const baseTabs = [
          { id: ProfileModalMode.About, title: 'profile-About' },
          { id: ProfileModalMode.Family, title: 'profile-Family' },
        ] as ModalSidebarItem<ProfileModalMode>[];

        if (permissions.includes('message_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Messages, title: 'profile-Messages' });
        }

        if (permissions.includes('payer_and_product_assignment_viewer')) {
          baseTabs.push({
            id: ProfileModalMode.DependantsAndProducts,
            title: 'profile-DependantsAndProducts',
          });
        }

        if (
          (permissions.includes('product_and_invoice_viewer') &&
            //Temporary solution until TR-8046 is done
            permissions.includes('payer_and_product_assignment_viewer')) ||
          permissions.includes('registration_manager')
        ) {
          baseTabs.push({ id: ProfileModalMode.PayableFees, title: 'profile-PayableFees' });
        }

        if (permissions.includes('parent_viewer') && hasMessageOrEventViewer) {
          baseTabs.push({ id: ProfileModalMode.ConsentForms, title: 'profile-ConsentForms' });
        }

        return baseTabs;
      }
      case 'student': {
        const baseTabs = [
          { id: ProfileModalMode.About, title: 'profile-About' },
        ] as ModalSidebarItem<ProfileModalMode>[];

        if (permissions.includes('student_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Registrations, title: 'profile-Registrations' });
        }

        baseTabs.push({ id: ProfileModalMode.Family, title: 'profile-Family' });

        if (permissions.includes('group_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Groups, title: 'profile-Groups' });
        }

        if (permissions.includes('assessment_viewer')) {
          baseTabs.push(
            { id: ProfileModalMode.Assessments, title: 'profile-Assessments' },
            { id: ProfileModalMode.Reports, title: 'profile-Reports' },
          );
        }

        if (permissions.includes('attendance_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Attendance, title: 'profile-Attendance' });
        }

        if (permissions.includes('conduct_viewer')) {
          baseTabs.push({ id: ProfileModalMode.Conduct, title: 'profile-Conduct' });
        }

        if (permissions.includes('payer_and_product_assignment_viewer')) {
          baseTabs.push({
            id: ProfileModalMode.PayersAndProducts,
            title: 'profile-ProductsAndPayers',
          });
        }
        if (
          (permissions.includes('product_and_invoice_viewer') &&
            //Temporary solution until TR-8046 is done
            permissions.includes('payer_and_product_assignment_viewer')) ||
          permissions.includes('registration_manager')
        ) {
          baseTabs.push({ id: ProfileModalMode.PayableFees, title: 'profile-PayableFees' });
        }

        if (permissions.includes('student_viewer') && hasMessageOrEventViewer) {
          baseTabs.push({ id: ProfileModalMode.ConsentForms, title: 'profile-ConsentForms' });
        }

        return baseTabs;
      }

      default: {
        const tabs = [
          { id: ProfileModalMode.About, title: 'profile-About' },
        ] as ModalSidebarItem<ProfileModalMode>[];

        const showFamilyTab =
          userType !== 'adult' ||
          Boolean(
            (user as unknown as ParentSchoolRelation)?.children?.length ||
              user?.adult_associations?.length,
          );

        if (showFamilyTab) {
          tabs.push({ id: ProfileModalMode.Family, title: 'profile-Family' });
        }

        return tabs;
      }
    }
  }, [permissions, user, userType]);

  return (
    <ModalSideBar
      sx={{
        paddingTop: (theme) => theme.spacing(1),
      }}
    >
      <MenuList>
        {tabs.map((tab) => {
          const isStudentRegistrationTab = isStudent && tab.id === 'registrations';
          const disableRegistrationTitle =
            isStudentRegistrationTab && data?.rollover_in_process && user
              ? $t(
                  { id: 'students-AnnualRollover-DisableRegistration' },
                  { fullName: getUserFullName(user) },
                )
              : '';
          return (
            <Tooltip title={disableRegistrationTitle}>
              <Box
                sx={{
                  a: disableRegistrationTitle && {
                    pointerEvents: 'none',
                  },
                }}
              >
                <Link
                  key={tab.id}
                  onClick={handleClick(tab.id)}
                  to={{ hash: `#${tab.id}` }}
                  state={location.state}
                  replace
                >
                  <MenuItem selected={mode === tab.id} key={tab.id}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormattedMessage id={tab.title} />
                      {disableRegistrationTitle && <Spin fontSize="18px" color="primary.main" />}
                    </Stack>
                  </MenuItem>
                </Link>
              </Box>
            </Tooltip>
          );
        })}
      </MenuList>
    </ModalSideBar>
  );
};

type UseEnrollmentPoolingProps = {
  params: { schoolId: string; studentId: string };
  enabled: boolean;
};

const useEnrollmentPooling = ({ params, enabled }: UseEnrollmentPoolingProps) => {
  const ENROLLMENT_UPDATE_INTERVAL = 20 * 1000;

  const [refetchInterval, setRefetchInterval] = useState<number>();

  const data = useGetEnrollments(params, {
    onSuccess: ({ rollover_in_process }) => {
      if (!rollover_in_process && !!refetchInterval) {
        setRefetchInterval(undefined);
        return;
      }

      if (rollover_in_process && !refetchInterval) {
        setRefetchInterval(ENROLLMENT_UPDATE_INTERVAL);
      }
    },
    onError: () => {
      if (!!refetchInterval) setRefetchInterval(undefined);
    },
    enabled,
    refetchOnMount: 'always',
    refetchInterval,
  });

  return data;
};
