import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { EditIcon } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../../components/uikit/Counter/Counter.styled';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../../components/uikit-components/Grid/Grid';
import { SchoolTuneSubjectsEmptyStub } from './SchoolTuneSubjectsEmptyStub';

export const SchoolTuneSubjects: FC = () => {
  const { schoolId = '' } = useAuth();
  const { subjects } = useSubjects({ schoolId }, { refetchOnMount: 'always', enabled: !!schoolId });

  if (!subjects.length) {
    return <SchoolTuneSubjectsEmptyStub />;
  }

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="school-tabs-Subjects" />
          </Typography>
          {!!subjects.length && <Counter>{subjects.length}</Counter>}
        </Stack>

        <Link to="subjects">
          <IconButton inverse>
            <EditIcon />
          </IconButton>
        </Link>
      </Stack>

      <Box sx={{ overflow: 'auto' }}>
        <GridContainer>
          {subjects.map((subject) => (
            <GridRowStyled key={subject.id}>
              <GridRowItem>
                <GridRowName>{subject.name}</GridRowName>
              </GridRowItem>
            </GridRowStyled>
          ))}
        </GridContainer>
      </Box>
    </Stack>
  );
};
