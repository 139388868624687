import { Product } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useCallback, useMemo, useState } from 'react';

import useSchoolYears from '../../../hooks/useSchoolYears';
import { RegistrationStatusWarning } from './RegistrationStatusWarning';

interface RegistrationMissingStatusesProps {
  schoolId: string;
  selectedStatuses: {
    applies_from?: string;
    school_property_id?: string;
  }[];
  products: Product[];
}

export const useRegistrationMissingStatuses = ({
  schoolId,
  selectedStatuses,
  products,
}: RegistrationMissingStatusesProps) => {
  const { schoolYears } = useSchoolYears();
  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });

  const filteredProducts = useMemo(
    () => products.filter((product) => product.obligatory),
    [products],
  );

  const hasLastUnsuccessfulStatus = useMemo(() => {
    const lastSelectedStatusId =
      selectedStatuses?.[selectedStatuses.length - 1]?.school_property_id;
    const lastSelectedStatus = activePropertiesMap['status'].find(
      (status) => status.id === lastSelectedStatusId,
    );
    return lastSelectedStatus?.category?.name === 'Unsuccessful';
  }, [selectedStatuses, activePropertiesMap]);

  const [obligatoryProductStatuses, setObligatoryProductStatuses] = useState<
    RegistrationStatusWarning[]
  >([]);

  const findMissingStatuses = useCallback(
    ({
      schoolYearId,
      ageGroupPropertyId,
      halfDay,
    }: {
      schoolYearId: string;
      ageGroupPropertyId: string;
      halfDay: boolean;
    }) => {
      const res: RegistrationStatusWarning[] = [];

      filteredProducts.forEach((product) => {
        const productFits = product.types.some((type) => {
          const schoolYear = schoolYears.find(
            (year) =>
              year.id === schoolYearId &&
              year.start === type.active_from &&
              year.end === type.active_to,
          );

          if (!schoolYear) {
            return false;
          }

          return type.variants.some((variant) => {
            return (
              variant.age_groups.includes(ageGroupPropertyId || '') &&
              !!variant.half_day === !!halfDay
            );
          });
        });

        if (productFits) {
          product.triggers.forEach((trigger) => {
            const statusIndex = activePropertiesMap['status'].findIndex(
              (status) => status.id === trigger.extra_data?.status,
            );
            const status =
              statusIndex !== -1 ? activePropertiesMap['status'][statusIndex] : undefined;

            if (status) {
              res.push({
                statusId: status.id,
                statusName: status.name,
                statusIndex,
                shown: false,
              });
            }
          });
        }
      });

      setObligatoryProductStatuses(
        res.filter(
          (status, index, self) => index === self.findIndex((s) => s.statusId === status.statusId),
        ),
      );
    },
    [activePropertiesMap, filteredProducts, schoolYears],
  );

  const removeStatusWarning = (statusId: string) => {
    setObligatoryProductStatuses((prev) =>
      prev.map((status) =>
        status.statusId === statusId
          ? {
              ...status,
              shown: true,
            }
          : status,
      ),
    );
  };

  return {
    removeStatusWarning,
    findMissingStatuses,
    obligatoryProductStatuses,
    hasLastUnsuccessfulStatus,
    originalStatuses: activePropertiesMap['status'],
    filteredProducts,
  };
};
