import { Box, Stack } from '@mui/material';
import { ApplicationCustomField, DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import {
  GENDER_OPTIONS,
  LANGUAGE_AREAS_OPTIONS,
  LANGUAGE_OPTIONS,
  NATIONALITY_OPTIONS,
  USER_NATIONALITIES_MAX_COUNT,
} from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { convertChildFormToApplicationChild } from '@schooly/utils/application-helpers';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
// eslint-disable-next-line @nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormRadioGroup } from 'apps/web/src/components/uikit-components/FormCheckbox/FormRadioGroup';
import { format, isAfter } from 'date-fns';
import isEqual from 'lodash.isequal';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ChildInfoFormContainer } from '../../ChildInfoFormContainer';
import { CreateChildForm } from '../../CreateChildContent.type';
import { CustomFieldFormContainer } from '../../CustomFieldsFormContainer';
import { LanguageFormContainer } from '../../LanguageFormContainer';
import { PreviousSchoolPreviewFormContainer } from '../../PreviousSchoolPreviewFormContainer';
import { PreviewFormType } from '../ApplicationEditablePreviewContent';
import { ApplicationChildrenPreview } from '../ApplicationPreviewCommon/ApplicationChildrenPreview';
import { EditDialog } from './EditDialog';

export interface ApplicationEditablePreviewChildProps {
  child: CreateChildForm;
  onSubmit: (v: CreateChildForm, type: string) => void;
  canEdit: boolean;
  withNotes?: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
}

export const ApplicationEditablePreviewChild: FC<ApplicationEditablePreviewChildProps> = ({
  child,
  canEdit,
  withNotes,
  updating,
  updatingIcon,
  onSubmit,
}) => {
  const { $t } = useIntl();
  const [isFormOpen, showFormModal, hideFormModal] = useFlag();

  const [previewType, setPreviewType] = useState<PreviewFormType | null>(null);
  const [selectedCustomField, setSelectedCustomField] =
    useState<ApplicationCustomField | null>(null);

  useEffect(() => {
    if (previewType && !isFormOpen) {
      setPreviewType(null);
    }
  }, [previewType, isFormOpen]);

  const form = useForm<CreateChildForm>({
    mode: 'onChange',
    values: child,
  });

  const nationalities = form.watch('nationalities');

  const formContent = useMemo(() => {
    switch (previewType) {
      case 'language':
        return (
          <LanguageFormContainer
            options={LANGUAGE_OPTIONS}
            gap={1}
            levelOptions={LANGUAGE_AREAS_OPTIONS}
            sx={{
              '.languageLevelGroups': {
                flexWrap: 'wrap',
              },
            }}
          />
        );
      case 'schoolHistory': {
        const schoolHistory = form.getValues('school_history');
        if (!schoolHistory.length) {
          form.setValue('school_history', [
            {
              school_name: undefined,
              start_date: undefined,
              end_date: undefined,
              country: undefined,
              language: undefined,
            },
          ]);
        }
        return <PreviousSchoolPreviewFormContainer />;
      }
      case 'gender': {
        return (
          <FormRadioGroup
            options={[...GENDER_OPTIONS].reverse()}
            name="gender"
            rules={{ required: true }}
          />
        );
      }
      case 'nationalities': {
        return (
          <FormSelect2
            name="nationalities"
            labelTextId={
              nationalities.length <= 1
                ? 'peopleDetail-Nationality'
                : 'peopleDetail-Nationality-plural'
            }
            options={NATIONALITY_OPTIONS}
            rules={{ required: true }}
            multiple
            maxCount={USER_NATIONALITIES_MAX_COUNT}
          />
        );
      }
      case 'dateOfBirth':
      case 'startedDate': {
        const today = newDateTimezoneOffset();

        return (
          <Stack height="100%">
            <Controller
              control={form.control}
              name={previewType === 'dateOfBirth' ? 'date_of_birth' : 'preferred_start_date'}
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <DateSelect
                    ref={field.ref}
                    onSetDate={(date) => {
                      if (isAfter(date, today)) return;

                      field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                    }}
                    date={field.value}
                    placeholder={$t({
                      id:
                        previewType === 'dateOfBirth'
                          ? 'peopleDetail-DateOfBirth'
                          : 'applications-PreferredStartingDate',
                    })}
                    requiredLabel="required"
                    error={fieldState.error}
                    onClear={() => field.onChange('')}
                    popperZIndex={(theme) => theme.zIndex.tooltip}
                    hideTodayButton
                    CalendarProps={{
                      shouldDisableDate:
                        previewType === 'dateOfBirth'
                          ? (value) => isAfter(value, today)
                          : undefined,
                    }}
                  />
                );
              }}
            />
          </Stack>
        );
      }
      case 'userInfo': {
        return <ChildInfoFormContainer />;
      }

      default:
        return null;
    }
  }, [$t, form, nationalities.length, previewType]);

  useEffect(() => {
    if (!form.formState.isValid) {
      form.trigger();
    }
  }, [form, child]);

  const normalizedChild = convertChildFormToApplicationChild(child);

  return (
    <FormProvider {...form}>
      <form>
        <ApplicationChildrenPreview
          key={normalizedChild.id}
          child={normalizedChild}
          index={0}
          updating={updating}
          editable={canEdit}
          onEdit={(v, customField) => {
            setPreviewType(v);
            setSelectedCustomField(customField ?? null);
            showFormModal();
          }}
          updatingIcon={updatingIcon}
        >
          {withNotes && (
            <Box
              pt={2}
              sx={{
                '& .MuiFormControl-root': { bgcolor: 'background.paper' },
                '& .FormTextField-clear': { visibility: 'hidden' },
                ':hover': { '& .FormTextField-clear': { visibility: 'visible' } },
              }}
              onBlur={() => {
                onSubmit(form.getValues(), previewType ?? '');
              }}
            >
              <ControlTextField
                name={'notes'}
                control={form.control}
                label={$t({ id: 'applications-AdmissionNotes' })}
                fullWidth
                canClear={canEdit}
                onClear={() => {
                  onSubmit(form.getValues(), previewType ?? '');
                }}
                disabled={!canEdit}
                data-test-id="application-note-block"
              />
            </Box>
          )}
        </ApplicationChildrenPreview>

        <EditDialog
          name={getUserFullName(normalizedChild)}
          isOpen={isFormOpen}
          key={`edit-${child.id}`}
          onClose={() => {
            form.reset(child);
            hideFormModal();
          }}
          onConfirm={() => {
            form.trigger();
            if (!form.formState.isValid || Boolean(Object.keys(form.formState.errors).length)) {
              return;
            }

            const updatedChild = form.getValues();

            if (!isEqual(updatedChild, child)) {
              onSubmit(updatedChild, previewType ?? '');
            }

            hideFormModal();
          }}
        >
          {previewType === 'customFields' && selectedCustomField ? (
            <CustomFieldFormContainer selectedCustomField={selectedCustomField} />
          ) : (
            formContent
          )}
        </EditDialog>
      </form>
    </FormProvider>
  );
};
