import { IconButton, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, Product } from '@schooly/api';
import { DateSelect, PropertyTypeSelect } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { RegistrationStatusWarning } from './RegistrationStatusWarning';
import {
  AddRegistrationStatus,
  DisabledRegistrationStatus,
  RegistrationForm,
} from './StudentRegistrationForm';
import { useRegistrationMissingStatuses } from './useRegistrationMissingStatuses';

export interface RegistrationStatusComponent {
  validateYear: ({ start, end }: { start: string; end: string }) => void;
  validateDateOrder: (statuses: AddRegistrationStatus[]) => void;
}
export interface RegistrationStatusProps {
  containerRef?: React.RefObject<HTMLElement>;
  schoolId: string;
  disabledStatuses: DisabledRegistrationStatus[];
  initial?: boolean;
  products: Product[];
}

export const RegistrationStatus: FC<RegistrationStatusProps> = ({
  containerRef,
  schoolId,
  disabledStatuses,
  initial,
  products,
}) => {
  const { $t } = useIntl();
  const { control, watch, formState } = useFormContext<RegistrationForm>();

  const {
    fields: statusFields,
    append: appendStatus,
    remove: removeStatus,
    insert: insertStatus,
  } = useFieldArray({
    control,
    name: 'statuses',
  });

  const statuses = watch('statuses');
  const selectedStatuses = statuses?.filter((status) => status.school_property_id);

  const {
    hasLastUnsuccessfulStatus,
    obligatoryProductStatuses,
    originalStatuses,
    removeStatusWarning,
    findMissingStatuses,
  } = useRegistrationMissingStatuses({
    schoolId,
    selectedStatuses,
    products,
  });

  const addField = useCallback(() => {
    appendStatus({
      formId: uuidv4(),
      school_property_id: '',
      applies_from: '',
    });

    if (containerRef) {
      requestAnimationFrame(() => {
        const container = containerRef.current;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth',
          });
        }
      });
    }
  }, [appendStatus, containerRef]);

  const insertField = useCallback(
    (index: number, statusId: string) => {
      return () => {
        insertStatus(index, {
          formId: uuidv4(),
          school_property_id: statusId,
          applies_from: '',
        });

        if (containerRef) {
          requestAnimationFrame(() => {
            const container = containerRef.current;
            if (container) {
              container.scrollTo({
                top: container.scrollHeight,
                behavior: 'smooth',
              });
            }
          });
        }
      };
    },
    [containerRef, insertStatus],
  );

  useEffect(() => {
    if (!statusFields.length) {
      addField();
    }
  }, [statusFields.length, addField]);

  useEffect(
    () =>
      findMissingStatuses({
        ageGroupPropertyId: formState?.defaultValues?.age_group_property_id || '',
        halfDay: !!formState?.defaultValues?.half_day,
        schoolYearId: formState?.defaultValues?.school_year_id || '',
      }),
    // we need to trigger this function once on component mount (it doesn't get triggered on initial render by itself)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const { unsubscribe } = watch((value, type) => {
      if (!type.name) {
        return;
      }

      findMissingStatuses({
        ageGroupPropertyId: value.age_group_property_id || '',
        halfDay: !!value.half_day,
        schoolYearId: value.school_year_id || '',
      });
    });

    return () => unsubscribe();
  }, [watch, findMissingStatuses, statusFields]);

  const statusWarningsDisabled = !initial || !selectedStatuses?.length || hasLastUnsuccessfulStatus;

  return (
    <Stack gap={2}>
      {statuses?.map((statusField, index) => {
        const statusError = formState.errors['statuses']?.[index]?.school_property_id;
        const dateError = formState.errors['statuses']?.[index]?.applies_from;
        const disabledStatus = disabledStatuses.find((s) => s.formId === statusField.formId);
        const required = index === 0 || disabledStatus?.required;
        const requiredLabel = required ? 'required' : 'optional';

        const currentStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statusField.school_property_id,
        );
        const nextStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statuses[index + 1]?.school_property_id,
        );

        return (
          <>
            {!statusWarningsDisabled &&
              !!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex < currentStatusIndex &&
                  index === 0
                ) {
                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(0, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}

            <Stack
              key={statusField.formId}
              direction="row"
              gap={1.25}
              sx={{
                '& .MuiSelect-select': {
                  maxHeight: 44,
                },
              }}
            >
              <Stack
                flex={1}
                position="relative"
                sx={{
                  '& .MuiFormControl-root': {
                    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              >
                <Controller
                  name={`statuses.${index}.school_property_id`}
                  control={control}
                  rules={{
                    required,
                  }}
                  render={({ field }) => {
                    const hasError = Boolean(statusError);
                    const errorMessage = getControllerErrorText(statusError, undefined, $t);
                    return (
                      <PropertyTypeSelect
                        adornmentLabel={requiredLabel}
                        userRole={SchoolUserRole.Student}
                        label={$t({ id: 'schoolProperty-Status' })}
                        propertyType={SchoolPropertyType.Status}
                        schoolId={schoolId}
                        errorMessage={errorMessage}
                        hasError={hasError}
                        fullWidth
                        {...field}
                        disabled={disabledStatus?.disabledFields.includes('school_property_id')}
                        optionalLabel={disabledStatus?.tag}
                        renderEndIcon={
                          disabledStatus ? () => disabledStatus.endAdornment : undefined
                        }
                      />
                    );
                  }}
                />
              </Stack>

              <Stack flex={1}>
                <Controller
                  name={`statuses.${index}.applies_from`}
                  control={control}
                  rules={{
                    required,
                  }}
                  render={({ field }) => {
                    const errorMessage = dateError
                      ? getControllerErrorText(dateError, undefined, $t)
                      : '';

                    const disabled = disabledStatus?.disabledFields.includes('applies_from');

                    return (
                      <DateSelect
                        onSetDate={(date) => {
                          field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                        }}
                        date={field.value}
                        opened={(!field.value && !!disabledStatus) || undefined}
                        hasValues={Boolean(field.value)}
                        placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                        requiredLabel={requiredLabel}
                        disabled={disabled}
                        renderRightIcon={disabled ? () => <></> : undefined}
                        error={
                          errorMessage
                            ? {
                                message: errorMessage,
                                type: 'validate',
                              }
                            : undefined
                        }
                      />
                    );
                  }}
                />
              </Stack>
              {(statusFields.length > 1 || disabledStatus?.lockIcon) && (
                <IconButton
                  inverse
                  onClick={() => !disabledStatus?.lockIcon && removeStatus(index)}
                  sx={(theme) => ({
                    alignSelf: 'flex-start',
                    mt: 1.25,
                    color: theme.palette.common.grey,
                    '&:hover': { color: theme.palette.text.primary },
                  })}
                >
                  {disabledStatus?.lockIcon ? disabledStatus?.lockIcon : <DeleteIcon />}
                </IconButton>
              )}
            </Stack>

            {!statusWarningsDisabled &&
              !!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex > currentStatusIndex &&
                  status.statusIndex < nextStatusIndex
                ) {
                  const prevStatusIndex = statuses.findIndex((selectedStatus) => {
                    const prevStatusId = originalStatuses.find(
                      (el, index) => index === status.statusIndex - 1,
                    )?.id;
                    return selectedStatus.school_property_id === prevStatusId;
                  });

                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(prevStatusIndex + 1, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}
          </>
        );
      })}

      <SimpleButton
        sx={{
          alignSelf: 'flex-start',
          '&.Mui-disabled': {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
        onClick={addField}
        startIcon={<PlusIcon />}
      >
        <FormattedMessage id="schoolProperty-AddStatus" />
      </SimpleButton>
    </Stack>
  );
};
