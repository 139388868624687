import { Icon, Stack, Typography } from '@mui/material';
import { Product, ProductTypeId } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ChevronDownSmallIcon,
  ChevronUpSmallIcon,
  DoneIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC } from 'react';

type ProductSelectRowProps = {
  isSelected: boolean;
  onSelectProduct: (id: string) => void;
  onSelectProductType: (id: ProductTypeId) => void;
  product: Product;
  selectedProductTypes: ProductTypeId[];
};

export const ProductSelectRow: FC<ProductSelectRowProps> = ({
  isSelected,
  product,
  selectedProductTypes,
  onSelectProduct,
  onSelectProductType,
}) => {
  const [isExpanded, expand, collapse] = useFlag(false);
  const isExpandable = product.types.length > 1;

  const ChevronIcon = isExpanded ? ChevronUpSmallIcon : ChevronDownSmallIcon;

  return (
    <>
      <Stack
        onClick={() => onSelectProduct(product.id)}
        key={product.id}
        sx={(theme) => ({
          mx: 0.5,
          px: 0.5,
          flexDirection: 'row',
          alignItems: 'center',
          gap: theme.spacing(1),
          cursor: 'pointer',
          transition: 'all .2s',
          py: isExpanded ? 1 : 0.5,
          borderTop: isExpanded ? `1px solid ${theme.palette.divider}` : `1px solid transparent`,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <Stack flex={1} flexDirection="row" alignItems="center" gap={1}>
          <TypographyWithOverflowHint
            color={!isSelected ? 'common.grey2' : undefined}
            variant="h3"
            noWrap
          >
            {product.name}
          </TypographyWithOverflowHint>
          {isExpandable && (
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                cursor: 'pointer',
                transition: 'all .2s',
                '&:hover': {
                  opacity: 0.5,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                isExpanded ? collapse() : expand();
              }}
            >
              <Typography
                sx={(theme) => ({
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: theme.spacing(1.25),
                  height: theme.spacing(2.5),
                  minWidth: theme.spacing(2.5),
                  textAlign: 'center',
                  mr: 0.25,
                  px: 0.5,
                })}
              >
                {selectedProductTypes.length ? `${selectedProductTypes.length}/` : ''}
                {product.types.length}
              </Typography>
              <ChevronIcon color="black" />
            </Stack>
          )}
        </Stack>
        <Icon
          sx={{
            visibility: isSelected ? 'visible' : 'hidden',
          }}
        >
          <DoneIcon />
        </Icon>
      </Stack>
      {isExpanded &&
        product.types.map((productType) => (
          <Stack
            onClick={() => onSelectProductType(new ProductTypeId(productType.id, product.id))}
            key={productType.id}
            sx={(theme) => ({
              flexDirection: 'row',
              alignItems: 'center',
              gap: theme.spacing(1),
              cursor: 'pointer',
              py: 0.5,
              px: 0.5,
              mx: 0.5,
              '&:hover': {
                backgroundColor: theme.palette.background.default,
              },
            })}
          >
            <TypographyWithOverflowHint
              color={!isSelected ? 'common.grey2' : undefined}
              variant="h3"
              noWrap
            >
              {productType.name}
            </TypographyWithOverflowHint>

            <Icon
              sx={{
                visibility:
                  isSelected || selectedProductTypes.some((pt) => pt.typeId === productType.id)
                    ? 'visible'
                    : 'hidden',
              }}
            >
              <DoneIcon />
            </Icon>
          </Stack>
        ))}
      <Stack
        sx={(theme) => ({
          transition: 'all .2s',
          mx: 0.5,
          px: 0.5,
          borderBottom: isExpanded ? `1px solid ${theme.palette.divider}` : `1px solid transparent`,
        })}
      ></Stack>
    </>
  );
};
