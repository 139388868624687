import formbricks from '@formbricks/js';

type InitParams = {
  isDev?: boolean;
  userId: string;
  email: string;
};

const FORMBRICKS_URL = 'https://app.formbricks.com';
const ACTION_KEY = 'accept_survey';
const ENV_ID = 'cm23g48ec000hly7xr9z4su2y';
const ENV_ID_DEV = 'cm23g48ch000dly7x0shgutr3';

const LOG_PREFIX = 'Formbricks:';
type FormbricksState =
  | {
      kind: 'uninitialized';
    }
  | ({
      kind: 'initializing' | 'initialized';
    } & InitParams);

class Formbricks {
  state: FormbricksState = {
    kind: 'uninitialized',
  };

  init = async ({ isDev, userId, email }: InitParams) => {
    if (this.state.kind === 'initializing') return;
    if (this.state.kind === 'initialized') {
      if (userId === this.state.userId) return;
      await formbricks.logout();
    }

    if (typeof window !== 'undefined') {
      await formbricks.init({
        environmentId: isDev ? ENV_ID_DEV : ENV_ID,
        apiHost: FORMBRICKS_URL,
        userId,
        attributes: {
          email,
        },
      });

      this.state = {
        isDev,
        userId,
        email,
        kind: 'initialized',
      };

      if (isDev) {
        // eslint-disable-next-line no-console
        console.log(LOG_PREFIX, 'init with', { userId, email });
      }
    }
  };

  showSurvey = async () => {
    if (this.state.kind !== 'initialized') {
      console.error(LOG_PREFIX, 'not initialized');
      return;
    }

    formbricks.track(ACTION_KEY);

    if (this.state.isDev) {
      // eslint-disable-next-line no-console
      console.log(LOG_PREFIX, 'track', ACTION_KEY);
    }
  };
}

export default new Formbricks();
