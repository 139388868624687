import { Box, Button, Chip, Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  DateTime,
  Event,
  EventsStatuses,
  EventType,
  SignUpType,
  useGetSchoolYears,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { isTimeInPast } from '@schooly/components/filters';
import { RichText } from '@schooly/components/form-rich-text';
import { useNotifications } from '@schooly/components/notifications';
import {
  getFollowingDatesByMonth,
  isDayOfMothOccurrence,
  RecurringSelect,
} from '@schooly/components/recurring';
import { EventsInvite, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ButtonWithDescription,
  CrossIcon,
  DeleteIcon,
  HolidayIcon,
  LockIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  PlusIcon,
  PreviewModalCard,
  ShowHtml,
  Spin,
} from '@schooly/style';
import { isDateInPast, isTimesRangeValid, newDateTimezoneOffset } from '@schooly/utils/date';
import {
  eachDayOfInterval,
  getISODay,
  getWeekOfMonth,
  isBefore,
  isToday,
  startOfToday,
} from 'date-fns';
import isEqual from 'lodash.isequal';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  ConsentForm,
  CreateConsentForm,
} from '../../../components/common/ConsentForms/ConsentForm';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { EventForm, useEvent } from '../../../context/events/WithEvent';
import { useRouter } from '../../../context/router/useRouter';
import { SignUpForm } from '../../../context/signUps/WithSignUp';
import { useSchoolYearPresets } from '../../../hooks/useSchoolYearPresets';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { SignUpDateSelector } from '../../SignUps/SignUpCreateModal/SignUpDateSelector';
import { SignUpDuration } from '../../SignUps/SignUpCreateModal/SignUpDuration';
import { SignUpPlaces } from '../../SignUps/SignUpCreateModal/SignUpPlaces';
import { SignUpTypeSelector } from '../../SignUps/SignUpCreateModal/SignUpTypeSelector';
import { RichTextWrapper } from './EventCreateContent.styled';
import { EventDateAndTimeWrapper } from './EventDateAndTimeWrapper';
import { InviteesForm } from './InviteesForm';
import { SignUpShortPreview } from './SignUpPreview';

const HTML_TAGS_REGEXP = /<[^>]*(>|$)/g;

export const defaultValues: Omit<EventForm, 'id' | 'status' | 'can_be_edited' | 'event_type'> & {
  event_type?: EventType;
} = {
  title: '',
  start: '',
  end: '',
  description: '',
  date_times: [],
  event_type: undefined,
  recurring_state: null,
  criteria: {
    student_status: [],
  },
};

export interface EventCreateContentProps {
  onClose?: () => void;
  defaultValues?: typeof defaultValues;
}

export const convertEventForForm = (e: Event) => {
  const { event_status, criteria, ...event } = e;
  const { groups, ...rest } = criteria;

  return {
    ...event,
    criteria: {
      ...rest,
      group: groups,
    },
  };
};

export const hasSameTimeValidationError = (times: DateTime, allDays: Date[], start: string) => {
  const today = startOfToday();
  const startDateInPast = isBefore(new Date(start), today);
  const todayIndex = allDays.findIndex((day) => isToday(day));
  const withToday = todayIndex >= 0;
  const startTime = times[0];
  const endTime = times[1];

  if (!startDateInPast) {
    if (withToday) {
      return (
        times.some((time) => time && isTimeInPast(time)) ||
        (startTime && endTime && !isTimesRangeValid(startTime, endTime))
      );
    } else {
      return startTime && endTime && !isTimesRangeValid(startTime, endTime);
    }
  }
  return false;
};

export const hasTimeValidationError = (dateTimes: DateTime[], start: string, end: string) => {
  const allDays = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end),
  });
  const sameTime = dateTimes.length === 1 && allDays.length > 1;

  if (sameTime) {
    return hasSameTimeValidationError(dateTimes[0], allDays, start);
  } else {
    const todayIndex = allDays.findIndex((day) => isToday(day));

    return allDays.some((_, index) => {
      const times = dateTimes[index];

      if (times && times[0] && times[1]) {
        if (index === todayIndex) {
          return (
            times.some((time) => time && isTimeInPast(time)) ||
            !isTimesRangeValid(times[0], times[1])
          );
        }
        return !isTimesRangeValid(times[0], times[1]);
      }
      return false;
    });
  }
};

export const EventCreateContent: FC<EventCreateContentProps> = ({
  onClose,
  defaultValues: propDefaultValues,
}) => {
  const { $t } = useIntl();
  const [searchParams] = useSearchParams();
  const { goBack } = useRouter();
  const { schoolId = '' } = useAuth();

  const {
    event,
    id: eventId,
    isActive,
    canCreate,
    canEdit,

    actions: {
      creating,
      updating,
      publishing,
      deleting,

      createEvent,
      updateEvent,
      publishEvent,
      deleteEvent,
    },
  } = useEvent();
  const navigate = useNavigate();

  const [withSignUp, setWithSignUp] = useState(false);
  const [consentFormShowed, showConsentForm, hideConsentForm] = useFlag();

  const [publishSubmitted, setPublishSubmitted] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const { showError } = useNotifications();
  const { activePropertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const linkedConsentForm = event?.consent_form ?? event?.sign_ups?.[0]?.consent_form;

  const close = onClose ?? goBack;

  const eventType: EventType =
    propDefaultValues?.event_type ??
    event?.event_type ??
    (searchParams.get('type') === 'holiday' ? 'holiday' : 'default');
  const isHoliday = eventType === 'holiday';

  const defaultEventFormData =
    (event && convertEventForForm(event)) ??
    propDefaultValues ??
    (isHoliday
      ? {
          ...defaultValues,
          invitee_type: EventsInvite.StudentsOnly,
          criteria: {
            student_status: activePropertiesMap.status
              .filter((status) => status.category?.current)
              .map((status) => status.id),
          },
        }
      : defaultValues);

  const form = useForm<EventForm>({
    defaultValues: {
      ...defaultEventFormData,
      event_type: eventType,
    },
  });

  const signUpForm = useForm<SignUpForm>({
    defaultValues: {
      event: {},
      withEvent: true,
    },
  });

  const consentForm = useForm<CreateConsentForm>({
    defaultValues: {
      description: event?.consent_form?.description ? event.consent_form.description : '',
    },
    mode: 'onChange',
  });

  const handleHideConsentForm = useCallback(() => {
    consentForm.reset();
    hideConsentForm();
  }, [consentForm, hideConsentForm]);

  const setValue = form.setValue;
  const trigger = form.trigger;

  const title = form.watch('title');
  const description = form.watch('description');
  const startDate = form.watch('start');
  const endDate = form.watch('end');
  const dateTimes = form.watch('date_times');
  const inviteeType = form.watch('invitee_type') ?? undefined;
  const recurringState = form.watch('recurring_state');
  const signUps = form.watch('sign_ups');

  const hasTimeSlotSignUp = useMemo(() => {
    if (!eventId || !signUps?.length) return false;

    return signUps.some((s) => s.type === SignUpType.Slots);
  }, [eventId, signUps]);

  const descriptionText = description ? description.replaceAll(HTML_TAGS_REGEXP, '') : '';
  const signUpType = signUpForm.watch('type');

  const prevWithSignUp = usePrevious(withSignUp);
  const prevStartDate = usePrevious(startDate);
  const prevDateTimes = usePrevious(dateTimes);
  const prevSignUpType = usePrevious(signUpType);
  const isSingleDateSelected = Boolean(startDate && startDate === endDate);

  const formData = form.getValues();
  const { start: formDataStart, end: formDataEnd } = formData;
  const recurringStateIsChanged = !isEqual(
    form.formState.defaultValues?.recurring_state,
    recurringState,
  );

  const initFollowingCount = event?.recurring_state?.following_count ?? 0;
  const currentFollowingCount =
    isSingleDateSelected && recurringState?.following_count ? recurringState.following_count : 0;
  const followingCount = recurringStateIsChanged ? currentFollowingCount : initFollowingCount;
  const hasFollowingEvents = Boolean(followingCount);
  // When there is no recurrence state or following count is zero
  const addingFollowingEvents = !initFollowingCount && hasFollowingEvents;

  const totalEvents = followingCount + 1;

  useEffect(() => {
    // We don't change recurring state on date change if it is an existing event
    if (eventId && !recurringStateIsChanged) return;

    const subscription = form.watch(({ start, end }, { name }) => {
      const occurrence = recurringState?.repeat_every?.occurrence;
      if (!occurrence || recurringState.repeat_every.period === 'week') return;

      // The date is changed and recurring by month
      if (name === 'end' && start === end) {
        const newStartDate = newDateTimezoneOffset(start);
        const isByDayOfMonth = isDayOfMothOccurrence(occurrence);

        form.setValue(
          'recurring_state.repeat_every.occurrence',
          isByDayOfMonth
            ? {
                ...occurrence,
                day_of_month: newStartDate.getDate(),
              }
            : {
                ...occurrence,
                day_of_week: [getISODay(newStartDate)],
                week_of_month: getWeekOfMonth(newStartDate),
              },
        );
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    eventId,
    form,
    recurringState?.repeat_every?.occurrence,
    recurringState?.repeat_every.period,
    recurringStateIsChanged,
    startDate,
  ]);

  /** sets actual event data to a sign up form on event change */
  useEffect(() => {
    signUpForm.setValue('event', {
      start: startDate,
      end: endDate,
      date_times: dateTimes,
    } as unknown as Event);
  }, [dateTimes, endDate, signUpForm, startDate]);

  /** resets sign up form on `Add/Delete sign up` toggle */
  useEffect(() => {
    if (prevWithSignUp && !withSignUp) {
      signUpForm.resetField('type');
      signUpForm.resetField('end');
      signUpForm.resetField('duration');
    }
  }, [prevWithSignUp, signUpForm, withSignUp]);

  /** resets sign up type if time slots have been deleted */
  useEffect(() => {
    if (prevDateTimes?.length && !dateTimes?.length) {
      signUpForm.resetField('type');
    }
  }, [dateTimes?.length, prevDateTimes?.length, signUpForm]);

  /** triggers event time slots if sign up type Slots is selected */
  useEffect(() => {
    if (
      prevSignUpType !== SignUpType.Slots &&
      signUpType === SignUpType.Slots &&
      !dateTimes.length
    ) {
      setValue('date_times', [['', '']]);
      showError({ reason: $t({ id: 'events-SignUp-Duration-NoTimeSlots' }) });

      setTimeout(() => {
        trigger('date_times');
      });
    }
  }, [
    $t,
    dateTimes?.length,
    prevDateTimes?.length,
    prevSignUpType,
    setValue,
    showError,
    signUpForm,
    signUpType,
    trigger,
  ]);

  /** re-validates `signUp.end` field on Event's start date change */
  useEffect(() => {
    if (withSignUp && prevStartDate !== startDate) {
      setTimeout(() => {
        signUpForm.trigger('end');
      });
    }
  }, [prevStartDate, signUpForm, startDate, withSignUp]);

  const { dates, times } = useMemo(() => {
    return {
      dates: startDate && endDate ? ([startDate, endDate] as [string, string]) : undefined,
      times: dateTimes ?? undefined,
    };
  }, [dateTimes, endDate, startDate]);

  const descriptionError = form.formState.errors.description?.message;
  const showRecurringDateRangeWarning = Boolean(
    formDataStart && formDataEnd && formDataStart !== formDataEnd && event?.recurring_state,
  );

  const isPublishedUpdate = event?.event_status === EventsStatuses.Published || isActive;

  const onRemoveEvent = useCallback(async () => {
    deleteEvent(event, {
      onSuccess: () => {
        onClose ? onClose() : navigate('/events');
      },
    });
  }, [deleteEvent, event, navigate, onClose]);

  const startTimeOfStartDate = dateTimes[0]?.[0];

  const recurrenceError = useMemo(() => {
    if (!recurringState || !startDate) return false;

    const recurrenceStartDate = newDateTimezoneOffset(startDate);
    const {
      ends_on,
      ends_after,
      repeat_every: { occurrence, repeat_count },
    } = recurringState;

    if (ends_on) {
      return isBefore(newDateTimezoneOffset(recurringState.ends_on), recurrenceStartDate);
    }

    if (!ends_after) return true;

    const isByDayOfMoth = isDayOfMothOccurrence(occurrence);

    const recurrenceEndDates = getFollowingDatesByMonth({
      ends: ends_after,
      repeatCount: repeat_count,
      startDate: recurrenceStartDate,
      dayOfMonth: isByDayOfMoth ? recurrenceStartDate.getDate() : undefined,
    });

    const endDate = recurrenceEndDates.at(-1);

    return endDate ? isBefore(endDate, recurrenceStartDate) : true;
  }, [recurringState, startDate]);

  const startDateError = useMemo(
    () =>
      (event?.event_status === EventsStatuses.Draft &&
        isDateInPast(startDate, startTimeOfStartDate ?? null)) ||
      recurrenceError,
    [event?.event_status, recurrenceError, startDate, startTimeOfStartDate],
  );

  const onEditorStateChange = useCallback(
    (editorState: string) => {
      setIsPublishing(false);
      form.setValue('description', editorState, { shouldValidate: true });
    },
    [form],
  );

  const onSubmit = useCallback<SubmitHandler<EventForm>>(
    async (d, e) => {
      if (hasTimeSlotSignUp && !d.date_times?.length) {
        setValue('date_times', [['', '']]);
        showError({ reason: $t({ id: 'events-SignUp-Duration-NoTimeSlots' }) });
        trigger('date_times');
        return;
      }
      const { date_times, start, end, recurring_state } = d;
      const recurringState = isSingleDateSelected && recurring_state ? recurring_state : null;

      if (startDateError) return;
      if (hasTimeValidationError(date_times, start, end)) return;

      const nativeEvent = e?.nativeEvent;

      const publishAction = Boolean(
        nativeEvent instanceof SubmitEvent && nativeEvent.submitter?.hasAttribute('data-publish'),
      );

      const updateAction = Boolean(
        nativeEvent instanceof SubmitEvent && nativeEvent.submitter?.hasAttribute('data-update'),
      );

      setPublishSubmitted(publishAction);

      if (publishAction || updateAction) {
        setIsPublishing(true);
        const isValid = await form.trigger();

        if (!isValid) return;
      }

      // if a Sign up is specified, need to check it's form manually
      if (!eventId && withSignUp) {
        const isValid = await signUpForm.trigger();
        if (!isValid) return;
      }

      if (consentFormShowed) {
        const isValid = await consentForm.trigger();
        if (!isValid) return;
      }

      const consentFormPayload = consentFormShowed ? consentForm.getValues() : undefined;

      if (nativeEvent instanceof SubmitEvent) {
        const { group, ...criteria } = d.criteria;

        if (publishAction) {
          await publishEvent({
            ...d,
            criteria: {
              ...criteria,
              groups: group,
            },
            recurring_state: recurringState,
            originalEvent: event,
            signUpPayload: withSignUp ? signUpForm.getValues() : undefined,
            consentFormPayload,
          });

          return onClose ? onClose() : close();
        }

        if (!eventId) {
          await createEvent({
            ...d,
            recurring_state: recurringState,
            signUpPayload: withSignUp ? signUpForm.getValues() : undefined,
            criteria: {
              ...criteria,
              groups: group,
            },
            consentFormPayload,
          });

          if (onClose) onClose();
          else navigate('/events');
        }

        if (isPublishedUpdate && Object.values(d.criteria).every((v) => !v.length)) {
          setPublishSubmitted(true);
          return;
        }

        updateEvent(
          {
            ...d,
            recurring_state: recurringState,
            signUpPayload: withSignUp ? signUpForm.getValues() : undefined,
            originalEvent: event,
            consentFormPayload,
          },
          {
            onSuccess: () => {
              onClose ? onClose() : close();
            },
          },
        );
      }
    },
    [
      hasTimeSlotSignUp,
      isSingleDateSelected,
      startDateError,
      eventId,
      withSignUp,
      consentFormShowed,
      consentForm,
      setValue,
      showError,
      $t,
      trigger,
      form,
      signUpForm,
      isPublishedUpdate,
      updateEvent,
      event,
      publishEvent,
      onClose,
      close,
      createEvent,
      navigate,
    ],
  );

  useEffect(() => {
    if (!dateTimes?.length) {
      signUpForm.setValue('duration', undefined);
    }
  }, [dateTimes?.length, signUpForm]);

  const disabled = creating || updating || deleting || publishing;

  const { defaultValidity } = useSchoolYears();

  const validityEndDate = defaultValidity ? newDateTimezoneOffset(defaultValidity.end) : null;

  const { data } = useGetSchoolYears(schoolId ?? '', {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const schoolPeriodPresets = useSchoolYearPresets({
    startDate: newDateTimezoneOffset(startDate),
    years: data?.school_years ?? [],
  });

  const footerContent = useMemo(() => {
    if (isPublishedUpdate) {
      return (
        <Button
          type="submit"
          startIcon={updating && <Spin />}
          disabled={disabled}
          data-test-id="modal-update"
          data-update
        >
          <FormattedMessage id="action-Update" />
        </Button>
      );
    }
    return (
      <>
        {!!eventId && (
          <Button
            startIcon={deleting ? <Spin /> : <DeleteIcon />}
            variant="outlined"
            disabled={disabled}
            onClick={onRemoveEvent}
            data-test-id="modal-delete"
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        )}
        <Stack direction="row" gap={1}>
          <Button
            type="submit"
            startIcon={(updating || creating) && <Spin />}
            disabled={disabled}
            data-test-id="modal-submit"
            variant="outlined"
          >
            <FormattedMessage
              id={
                addingFollowingEvents
                  ? $t(
                      {
                        id: 'events-recurring-SaveEvents',
                      },
                      {
                        eventsCount: totalEvents,
                      },
                    )
                  : 'action-Save'
              }
            />
          </Button>
          <Button
            startIcon={publishing && <Spin />}
            disabled={disabled}
            data-test-id="modal-Publish"
            type="submit"
            data-publish
          >
            <FormattedMessage id="action-Publish" />
          </Button>
        </Stack>
      </>
    );
  }, [
    isPublishedUpdate,
    eventId,
    deleting,
    disabled,
    onRemoveEvent,
    updating,
    creating,
    addingFollowingEvents,
    $t,
    totalEvents,
    publishing,
  ]);

  const eventInviteeType = event?.invitee_type ?? undefined;

  const signUpHasConsentForm = event?.sign_ups?.some((s) => !!s.consent_form);
  const hasConsentForm = !!event?.consent_form || !!signUpHasConsentForm;
  const hasSignUp = !!event?.sign_ups?.[0];
  const isDraft = event ? event?.event_status === EventsStatuses.Draft : true;

  const canCreateSignUp = !hasSignUp && isDraft && !isHoliday && startDate && !recurringState;
  const canCreateConsentForm = !hasConsentForm && !isHoliday && startDate && isDraft;

  if ((eventId && !canEdit) || (!eventId && !canCreate)) {
    return <ModalAccessDenied />;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, () => signUpForm.trigger())}>
        <ModalHeader
          active
          title={
            <ControlModalHeaderInput
              control={form.control}
              name="title"
              rules={{
                required: false,
                validate: (value) => {
                  if (value && `${value}`.length > 250) {
                    return $t({ id: 'input-ErrorMaxLengthValue' }, { value: 250 });
                  }

                  // the "Save" action can skip validation, only on "Publish" it's mandatory
                  if (!isPublishing) {
                    return undefined;
                  }

                  return value ? undefined : $t({ id: 'input-ErrorRequired' });
                },
              }}
              placeholder={$t({ id: isHoliday ? 'event-holiday-name' : 'event-name' })}
              autoFocus
            />
          }
          sx={{
            ' .MuiStack-root': { gap: 0.5 },
          }}
        >
          {isHoliday && (
            <Tooltip title={$t({ id: 'event-holiday-hint' })}>
              <Icon sx={{ color: 'text.secondary', mr: 2.5 }}>
                <HolidayIcon />
              </Icon>
            </Tooltip>
          )}
          {isPublishedUpdate && (
            <Chip
              sx={(theme) => ({
                color: `${theme.palette.background.default} !important`,
                background: theme.palette.common.grey,
                px: 0.5,
              })}
              avatar={
                <Icon
                  sx={(theme) => ({
                    color: `${theme.palette.background.default} !important`,
                    pt: 0.25,
                  })}
                >
                  <LockIcon />
                </Icon>
              }
              label={
                <Typography variant="h3" color="inherit">
                  <FormattedMessage id="events-action-ActiveEventWarning" />
                </Typography>
              }
            />
          )}
          <IconButton onClick={close}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <input
              {...form.register('description', {
                required: false,
                validate: (value) => {
                  const text = value ? value.replaceAll(HTML_TAGS_REGEXP, '') : '';

                  // Can not rely on a standard `minLength` rule as the value might contain HTML tags
                  // Should check length only if the value is presented, empty value is allowed in case of Holiday
                  if (text && text.length < 50) {
                    return $t({ id: 'input-ErrorMinLengthValue' }, { value: 50 });
                  }

                  // the "Save" action can skip validation, only on "Publish" it's mandatory
                  if (!isPublishing) {
                    return undefined;
                  }

                  // in Event it's required, in Holiday it's optional
                  return !isHoliday && !text ? $t({ id: 'input-ErrorRequired' }) : undefined;
                },
              })}
              type="hidden"
            />
            <Stack>
              <RichTextWrapper error={!!descriptionError}>
                {!descriptionText && (
                  <Box
                    position="absolute"
                    sx={{
                      top: 60,
                      right: 40,
                    }}
                  >
                    <Typography
                      color="text.secondary"
                      variant="h3"
                      sx={{
                        fontStyle: 'italic',
                      }}
                    >
                      <FormattedMessage id={isHoliday ? 'input-optional' : 'input-required'} />
                    </Typography>
                  </Box>
                )}
                <RichText
                  placeholder={$t({ id: 'event-descriptionPlaceholder' })}
                  value={description}
                  onChange={onEditorStateChange}
                  withoutAttachments
                />
              </RichTextWrapper>
              {descriptionError && (
                <Typography
                  color="error.main"
                  variant="body2"
                  sx={{
                    pl: 2,
                    pt: 0.25,
                  }}
                >
                  {descriptionError}
                </Typography>
              )}
            </Stack>

            <EventDateAndTimeWrapper
              fromDateError={startDateError}
              recurrenceError={recurrenceError}
              dates={dates}
              times={times}
              isPublished={event?.event_status === EventsStatuses.Published}
              helperText={
                showRecurringDateRangeWarning
                  ? $t({ id: 'events-recurring-EventDateRangeWarning' })
                  : undefined
              }
            >
              {!isHoliday && isSingleDateSelected && (
                <Stack flex="0 0 50%" overflow="hidden">
                  <RecurringSelect
                    entity={{
                      name: $t({ id: 'event-type' }),
                      namePlural: $t({ id: 'event-type-plural' }),
                    }}
                    title={title}
                    presets={schoolPeriodPresets}
                    startDate={newDateTimezoneOffset(startDate)}
                    endDate={validityEndDate}
                    name="recurring_state"
                  />
                </Stack>
              )}
            </EventDateAndTimeWrapper>
            <Stack pt={1.5}>
              <InviteesForm
                isActive={isActive}
                isHoliday={isHoliday}
                isActiveWarning={$t({ id: 'events-ActiveEventInviteesWarning' })}
                inviteeType={isHoliday ? inviteeType : eventInviteeType}
                inviteeDescription={$t({ id: 'events-InviteesDescription' })}
                isPublishing={isPublishing}
                criteriaValidate={(criteria) =>
                  Object.values(criteria).every((v) => !v?.length) && publishSubmitted
                    ? $t({ id: 'input-ErrorRequired' })
                    : undefined
                }
              />
            </Stack>

            {Boolean(event?.sign_ups?.length || event?.consent_form) && (
              <Stack pt={4} gap={4}>
                {event?.sign_ups?.map((signUp) => (
                  <PreviewModalCard
                    key={signUp.id}
                    path={`/signups/${signUp.id}`}
                    title={signUp.title}
                  >
                    <SignUpShortPreview signUp={signUp} />
                  </PreviewModalCard>
                ))}
                {linkedConsentForm && (
                  <PreviewModalCard
                    path={`/consent-form/${linkedConsentForm.id}`}
                    title={$t({ id: 'consentForms-title' })}
                  >
                    <ShowHtml
                      html={linkedConsentForm.description}
                      sx={{
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                      }}
                    />
                  </PreviewModalCard>
                )}
              </Stack>
            )}

            <Stack pt={withSignUp || consentFormShowed ? 4 : 0} gap={2}>
              {withSignUp && (
                <FormProvider {...signUpForm}>
                  <Stack gap={1.5}>
                    <Stack direction="row" justifyContent="space-between" gap={1}>
                      <Typography variant="h2">
                        <FormattedMessage id="events-header-SignUp-Title" />
                      </Typography>

                      <IconButton inverse onClick={() => setWithSignUp(false)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>

                    <Stack direction="row" gap={1}>
                      <SignUpTypeSelector />
                      <SignUpDateSelector />
                      <SignUpPlaces />
                      <SignUpDuration />
                    </Stack>
                  </Stack>
                </FormProvider>
              )}

              <ConsentForm
                visible={consentFormShowed}
                onDelete={handleHideConsentForm}
                consentForm={consentForm}
              />
            </Stack>

            <Stack direction="row" gap={2} pt={4}>
              {canCreateSignUp && !withSignUp && (
                <ButtonWithDescription
                  startIcon={<PlusIcon />}
                  title={$t({ id: 'events-header-SignUp-Title' })}
                  description={$t({ id: 'events-AddSignUpDescription' })}
                  onClick={() => setWithSignUp(true)}
                  oneLine={consentFormShowed}
                />
              )}
              {canCreateConsentForm && !consentFormShowed && (
                <ButtonWithDescription
                  onClick={showConsentForm}
                  startIcon={<PlusIcon />}
                  oneLine={withSignUp}
                  title={$t({ id: 'consentForms-title' })}
                  description={$t({ id: 'consentForms-titleDescription' })}
                />
              )}
            </Stack>
          </ModalContent>
        </ModalMain>

        <ModalFooter
          active
          sx={{
            justifyContent: isPublishedUpdate || !eventId ? 'flex-end' : 'space-between',
          }}
        >
          {footerContent}
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
