import { Stack } from '@mui/material';
import { AgeGroup, AssessmentToCreate, GroupSubject, ReportForAssessment } from '@schooly/api';
import { ScrollArrows } from '@schooly/components/data-grid';
import { AssessmentStatuses } from '@schooly/constants';
import { ModalContent, ModalMain, TypographyWithOverflowHint } from '@schooly/style';
import { FC, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ReportForm } from '../../../context/report/WithReportEditContext';
import {
  AgeGroupHeader,
  AreaHeader,
  ColumnCell,
  HEADER_HEIGHT,
  HighlightRef,
  MatrixHighlight,
  MatrixOverlay,
  SubjectCell,
  SUBJECTS_COLUMN_WIDTH,
} from './MatrixComponents';
import { Arrow, handleArrowClick } from './ReportCreateModalMatrixScrollArrows';

type MatrixStateContentProps = {
  report?: ReportForAssessment;
  subjects: GroupSubject[];
  ageGroups: AgeGroup[];
  form: UseFormReturn<ReportForm>;
  assessments: AssessmentToCreate[];
};

export const MatrixStateContent: FC<MatrixStateContentProps> = ({
  report,
  form,
  subjects,
  ageGroups,
  assessments,
}) => {
  const { $t } = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);
  const highlightRef = useRef<HighlightRef>(null);

  const areas = form.watch('areas_of_learning');
  const ageGroupIds = form.watch('age_group_ids');
  const subjectIds = form.watch('subject_ids');
  const withTutorFeedback = form.watch('with_tutor_feedback');

  const areasWithDisabled = useMemo(() => {
    if (!report) return areas;

    return areas.map((a) => ({
      ...a,
      disabled:
        a.assessment_id &&
        report.assessments?.find(({ id }) => id === a.assessment_id)?.assessment_status ===
          AssessmentStatuses.Published,
    }));
  }, [areas, report]);

  const tableData = useMemo(() => {
    const filteredSubjects = subjects.filter((s) => !s.archived && subjectIds.includes(s.id));

    const filteredAgeGroups = ageGroups
      .filter((g) => !g.archived && ageGroupIds.includes(g.id))
      .sort((a, b) => a.order - b.order);

    // No age groups
    if (!filteredAgeGroups.length) {
      const assessmentsSubjectIds = assessments.reduce<string[]>(
        (acc, assessment) => [...acc, ...assessment.subjects.map((s) => s.id)],
        [],
      );

      const emptyAgeGroupColumns = [
        {
          name: $t({ id: 'reports-AllStudents' }),
          id: 'empty-age-group',
          hasTutorGroup: assessments.some((a) => a.is_tutor),
          availableSubjectsIds: filteredSubjects
            .map((s) => s.id)
            .filter((id) => assessmentsSubjectIds.includes(id)),
        },
      ];

      return {
        ageGroupColumns: emptyAgeGroupColumns,
        subjects: filteredSubjects,
      };
    }

    const allAgeGroupColumns = filteredAgeGroups.map((ageGroup) => {
      const availableSubjectsIds = assessments.reduce<string[]>((acc, assessment) => {
        const relatedAgeGroup = assessment.age_groups.find((ag) => ag.id === ageGroup.id);

        if (!relatedAgeGroup || !relatedAgeGroup.subjects) return acc;

        return [
          ...acc,
          ...relatedAgeGroup.subjects.map((s) => s.id).filter((id) => !acc.includes(id)),
        ];
      }, []);

      return {
        ...ageGroup,
        hasTutorGroup: assessments.some(
          (a) => a.is_tutor && a.age_groups.some((ag) => ag.id === ageGroup.id),
        ),
        availableSubjectsIds,
      };
    });

    const ageGroupColumns = allAgeGroupColumns.reduce<
      (AgeGroup & { availableSubjectsIds: string[]; hasTutorGroup: boolean })[]
    >((acc, ageGroup) => {
      const prevAgeGroup = acc.length && acc[acc.length - 1];

      if (
        !prevAgeGroup ||
        prevAgeGroup.availableSubjectsIds.join(',') !== ageGroup.availableSubjectsIds.join(',') ||
        (withTutorFeedback && prevAgeGroup.hasTutorGroup !== ageGroup.hasTutorGroup)
      )
        return [...acc, ageGroup];

      prevAgeGroup.name += ` + ${ageGroup.name}`;

      return acc;
    }, []);

    return {
      ageGroupColumns,
      subjects: filteredSubjects,
    };
  }, [$t, ageGroupIds, ageGroups, assessments, subjectIds, subjects, withTutorFeedback]);

  return (
    <ModalMain position="relative">
      <ModalContent active sx={{ paddingTop: 0, position: 'relative' }} ref={containerRef}>
        <Stack minWidth="fit-content">
          <Stack
            sx={(theme) => ({
              position: 'sticky',
              flexDirection: 'row',
              top: 0,
              backgroundColor: 'white',
              borderBottom: `1px solid ${theme.palette.common.light3}`,
              zIndex: 2,
              height: HEADER_HEIGHT,
              paddingLeft: `${SUBJECTS_COLUMN_WIDTH}px`,
            })}
          >
            {tableData.ageGroupColumns?.map((g) => (
              <AgeGroupHeader key={g.id} name={g.name} hasBottomBorder={!!areas.length}>
                <AreaHeader areas={areasWithDisabled} />
              </AgeGroupHeader>
            ))}
          </Stack>
          <Stack
            sx={(theme) => ({
              flexDirection: 'row',
              flex: 1,
              borderBottom: `1px solid ${theme.palette.divider}`,
            })}
          >
            <Stack
              sx={{
                position: 'sticky',
                width: SUBJECTS_COLUMN_WIDTH,
                left: 0,
                backgroundColor: 'white',
                zIndex: 3,
              }}
            >
              {withTutorFeedback && (
                <SubjectCell>
                  <TypographyWithOverflowHint variant="h3" color="text.primary">
                    {$t({ id: 'reports-TutorsFeedback' })}
                  </TypographyWithOverflowHint>
                </SubjectCell>
              )}
              {tableData.subjects.map((s, i) => (
                <SubjectCell key={s.id + i}>
                  <TypographyWithOverflowHint variant="h3" color="text.primary">
                    {s.name}
                  </TypographyWithOverflowHint>
                </SubjectCell>
              ))}
            </Stack>

            {tableData?.ageGroupColumns?.map((ageGroupColumn, agi) => (
              <Stack
                key={ageGroupColumn.id + agi}
                sx={(theme) => ({
                  borderRight: `1px solid ${theme.palette.common.light3}`,
                  flex: 1,
                })}
              >
                {withTutorFeedback && (
                  <ColumnCell
                    setHoveredCell={(t) => {
                      highlightRef.current?.show(t);
                    }}
                    isTutor
                    areas={areasWithDisabled}
                    hasValue={ageGroupColumn.hasTutorGroup}
                  />
                )}
                {tableData.subjects.map((subject, i) => (
                  <ColumnCell
                    setHoveredCell={(t) => {
                      highlightRef.current?.show(t);
                    }}
                    key={subject.id + i}
                    areas={areasWithDisabled}
                    hasValue={ageGroupColumn.availableSubjectsIds.includes(subject.id)}
                  />
                ))}
              </Stack>
            ))}
          </Stack>
        </Stack>
      </ModalContent>
      <ScrollArrows
        ArrowComponent={Arrow}
        onArrowClick={handleArrowClick}
        cellSelector=".head-cell"
        containerRef={containerRef}
      />

      <MatrixHighlight ref={highlightRef} containerRef={containerRef} />
      <MatrixOverlay />
    </ModalMain>
  );
};
