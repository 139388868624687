import { Box, Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FilterKeys, useGetAssessmentQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { RecurrenceInfo } from '@schooly/components/recurring';
import { DATE_FORMAT } from '@schooly/constants';
import {
  CrossIcon,
  EditIcon,
  IncompleteSvg,
  Loading,
  LockIcon,
  ReportGeneratedIcon,
  SimpleButton,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { ModalNavigation } from '../../../components/common/ModalNavigation';
import { ModalLarge, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import AssessmentStatuses from '../../../constants/assessmentStatuses';
import { useCollapsiblePanel } from '../../../context/collapsiblePanel/useCollapsiblePanel';
import { useRouter } from '../../../context/router/useRouter';
import { allowRenderAssessments } from '../../../helpers/renderRoutesByPermissions';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { AssessmentsPreviewContextLocation } from '../../../redux/slices/assessments/previewSlice';
import { AssessmentsReportsList } from '../AssessmentsReportsList/AssessmentsReportsList';
import { AssessmentsPreviewActions } from './AssessmentsPreviewActions';
import { AssessmentsPreviewExport } from './AssessmentsPreviewExport/AssessmentsPreviewExport';
import { AssessmentsPreviewModalContent } from './AssessmentsPreviewModalContent';
import { AssessmentsPreviewModalSideBar } from './AssessmentsPreviewModalSideBar';

// TODO: hide body scroll
// TODO: adjust entries spin (brings content vertical scroll)
// TODO: remake router stack mechanim
const AssessmentsPreviewModal: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const location = useAppLocation<AssessmentsPreviewContextLocation>();
  const { id } = useParams<'id'>();
  const { closeAndClean } = useRouter();
  const { permissions } = useAuth();
  const invalidateQueries = useInvalidateListQueriesFor('assessment');
  const { group_id } = useQueryStringParams<'group_id'>();
  const { state } = useCollapsiblePanel();
  const isPanelOpen = state.isOpen;

  const {
    data,
    isFetching: fetching,
    refetch,
  } = useGetAssessmentQuery(
    { id: id ?? '' },
    {
      enabled: Boolean(id),
      refetchOnMount: 'always',
    },
  );

  const isIncomplete =
    (data?.assessment_status !== AssessmentStatuses.Published && !data?.methods.length) ||
    !data?.groups.length;

  const autoGeneratedFromReport = data?.autogenerated;

  const userHasAccess = allowRenderAssessments(permissions);

  const showEditButton = useMemo(() => {
    return Boolean(
      permissions.includes('assessment_viewer') &&
        data?.can_be_edited &&
        data.assessment_status !== AssessmentStatuses.Published,
    );
  }, [data?.assessment_status, data?.can_be_edited, permissions]);

  const handleEditButtonClick = useCallback(() => {
    navigate(`/assessments/${id}/edit`, { state: location.state });
  }, [id, location.state, navigate]);

  const handleActionUpdate = useCallback(async () => {
    await refetch();
    invalidateQueries();
  }, [invalidateQueries, refetch]);

  const date = useMemo(
    () => format(newDateTimezoneOffset(data?.assessment_date), DATE_FORMAT),
    [data?.assessment_date],
  );

  const types = useMemo(
    () =>
      data?.methods
        .map((method) => $t({ id: `assessments-Type-${method.method_type}` }))
        .join('  + '),

    [data?.methods, $t],
  );

  const currentGroup = group_id && data ? data.groups.find((g) => g.id === group_id) : undefined;

  const renderContent = () => {
    if (fetching) {
      return <Loading />;
    }

    if (!userHasAccess) {
      return <AccessDenied />;
    }

    return (
      <>
        <ModalHeaderV2
          title={
            <Stack direction="row" gap={0.75}>
              {data?.name}
              {data?.recurring_state && (
                <RecurrenceInfo
                  recurringState={data.recurring_state}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      {
                        pathname: '/assessments',
                      },
                      {
                        state: { [FilterKeys.RecurrenceId]: data.recurring_state?.recurrence_id },
                      },
                    );
                  }}
                />
              )}
            </Stack>
          }
          withGoBack
          active
          subHeader={
            <Stack flexDirection="row" gap={5} mt={0.5}>
              <Typography color="text.primary">{date}</Typography>
              <Typography
                sx={{
                  whiteSpace: 'pre-wrap',
                }}
                color="text.primary"
              >
                {types}
              </Typography>

              {data?.reports?.length ? <AssessmentsReportsList reports={data?.reports} /> : null}
            </Stack>
          }
        >
          <AssessmentsPreviewActions
            assessment={data}
            isIncomplete={isIncomplete}
            onUpdate={handleActionUpdate}
          />

          {Boolean(data?.group_ids?.length) && <AssessmentsPreviewExport data={data} />}
          {showEditButton &&
            (autoGeneratedFromReport ? (
              <Tooltip
                title={
                  <Stack alignItems="flex-start" gap={1}>
                    <Typography>{$t({ id: 'assessments-AutoGenerated' })}</Typography>
                    <SimpleButton
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/reports/${data?.generated_from_report_id}`);
                      }}
                      sx={(theme) => ({
                        '.svg-icon': {
                          width: theme.spacing(2),
                          height: theme.spacing(2),
                        },
                        backgroundColor: 'transparent',
                        fontSize: theme.spacing(1.5),
                      })}
                      startIcon={<ReportGeneratedIcon />}
                    >
                      {$t({ id: 'assessments-GoToReport' })}
                    </SimpleButton>
                  </Stack>
                }
              >
                <Box
                  sx={(theme) => ({
                    ':hover > .MuiIconButton-root': { color: theme.palette.primary.main },
                  })}
                >
                  <IconButton inverse disabled>
                    <LockIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            ) : (
              <IconButton onClick={handleEditButtonClick}>
                <EditIcon />
              </IconButton>
            ))}
          <IconButton onClick={closeAndClean}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>
        <ModalMain>
          {isIncomplete ? (
            <Stack alignItems="center" justifyContent="center" gap={2.5} width="100%">
              <IncompleteSvg />
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ textAlign: 'center', maxWidth: 300 }}
              >
                {$t({ id: 'assessments-NotConfigured' })}
              </Typography>
              {showEditButton && (
                <Button
                  startIcon={<EditIcon />}
                  onClick={() => navigate('edit', { state: { replace: true } })}
                >
                  {$t({ id: 'action-ContinueSetup' })}
                </Button>
              )}
            </Stack>
          ) : (
            <>
              <CollapsiblePanel width={266} getSxProps={() => ({ maxWidth: 266 })}>
                <AssessmentsPreviewModalSideBar assessment={data} />
              </CollapsiblePanel>
              <AssessmentsPreviewModalContent
                groupId={group_id}
                assessment={data}
                isFetching={fetching}
              />
            </>
          )}
        </ModalMain>
        {!!currentGroup && !isPanelOpen && !!data?.groups.length && (
          <ModalNavigation
            selectedItem={currentGroup}
            items={data.groups}
            onClick={(g) => {
              navigate({ search: `?group_id=${g.id}` }, { state: location.state });
            }}
            getItemLabel={(r) => r.name}
          />
        )}
      </>
    );
  };

  return (
    <ModalLarge open onClose={closeAndClean}>
      {renderContent()}
    </ModalLarge>
  );
};

export default AssessmentsPreviewModal;
