import { encodeBase64 } from '@schooly/utils/encode-base64';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useState } from 'react';

import { DEFAULT_DATE_FORMAT_FNS } from '../constants';
import {
  ListProfileArguments,
  ListProfileReturn,
  SaveFilterResponse,
} from './apiTypes/endpoints/people';
import {
  ApiError,
  EmploymentHistoryEntry,
  PagedResponse,
  StringOrNull,
  UserType,
} from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { UserRolePermissions } from './apiTypes/userRoles';
import {
  FilterKeys,
  FilterSection,
  NewAdult,
  NewFamilyMember,
  SavedFilter,
  SyncUser,
} from './apiTypes/users';
import * as api from './requests';
import { removeObjectFalsyValues } from './utils/removeObjectFalsyValues';
const SYNC_URL = '/user/sync/';
const PROFILE_URL = '/profile/';
const USER_URL = '/user/';

const DEFAULT_PAGE_SIZE = 50;

export interface UserSyncResponse {
  user: SyncUser;
  build_date: string;
  commit_sha: string;
  version: string;
  permissions: UserRolePermissions[];
}

export function userSync(): Promise<UserSyncResponse> {
  return api.post(SYNC_URL, {});
}

export const SYNC_USER_QUERY = `${USER_URL}SYNC_USER_QUERY`;

export const useSyncUserQuery = (options?: RQUseQueryOptions<UserSyncResponse>) => {
  return useQuery<UserSyncResponse, ApiError>([SYNC_USER_QUERY], userSync, options);
};

export function getUser(userId: string, schoolId?: string): Promise<SyncUser> {
  // TODO: `school_id` query parameter should filter relations of the user in the provided School
  const params = {
    school_id: schoolId,
  };

  return api.get(USER_URL + userId, { params });
}

export const GET_USER_QUERY = `${USER_URL}GET_USER_QUERY`;

type GetUserQueryParams = { userId: string; schoolId?: string };

export const useGetUserQuery = (
  params: GetUserQueryParams,
  options?: RQUseQueryOptions<SyncUser | undefined>,
) => {
  return useQuery<SyncUser | undefined, ApiError>(
    [GET_USER_QUERY, params.userId, params],
    () => getUser(params.userId, params.schoolId),
    options,
  );
};

export type CreateUnverifiedEmailParams = {
  given_name?: string;
  last_name: string;
  email: string;
  country?: number | null;
  password?: string;
  verify?: boolean;
};

export function createUnverifiedEmail({
  given_name,
  last_name,
  email,
  country,
  verify,
}: CreateUnverifiedEmailParams): Promise<boolean> {
  return api.post(`${USER_URL}create-unverified-email`, {
    given_name,
    last_name,
    email,
    verify,
    country: country || 0,
  });
}

type UpdateSchoolUserParams = {
  userId: string;
  schoolId?: string;
  update: Partial<SyncUser>;
};

function updateSchoolUser({ schoolId, update, userId }: UpdateSchoolUserParams): Promise<SyncUser> {
  const params = {
    school_id: schoolId,
  };

  return api.patch(USER_URL + userId, update, { params });
}

export const useUpdateSchoolUserMutation = (
  options?: RQUseMutationOptions<SyncUser, UpdateSchoolUserParams>,
): RQUseMutationResult<SyncUser, UpdateSchoolUserParams> => {
  const queryClient = useQueryClient();

  return useMutation(updateSchoolUser, {
    ...options,
    onSuccess: (result, variables, ...data) => {
      options?.onSuccess?.(result, variables, ...data);
      queryClient.invalidateQueries([GET_USER_QUERY, variables.userId]);
    },
  });
};

export function listProfiles<T extends UserType>({
  schoolId,
  type,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
  query,
  excludeSchoolUserTypes,
  lastNameForSuggestion,
  min = false,
  token,
}: ListProfileArguments<T>): Promise<PagedResponse<ListProfileReturn[T]>> {
  const params = removeObjectFalsyValues({
    page_size: pageSize,
    page_number: pageNumber,
    query,
    filter: type,
    school_id: schoolId,
    exclude_school_relation_types: excludeSchoolUserTypes?.join(','),
    last_name_for_suggestion: lastNameForSuggestion,
    min: Number(min),
  });

  return api.get(`${PROFILE_URL}list/`, { params, cancelToken: token });
}

export const GET_PROFILES_QUERY = `${PROFILE_URL}GET_PROFILES_QUERY`;

export const useGetProfilesQuery = <T extends UserType>(
  initialParams: ListProfileArguments<T>,
  options?: RQUseInfiniteQueryOptions<PagedResponse<ListProfileReturn[T]>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<ListProfileReturn[T]>, ApiError>(
    [GET_PROFILES_QUERY, params],
    ({ pageParam }) => listProfiles({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function removeUser(userId: string, schoolId: string) {
  return api.remove(USER_URL + userId, { school_id: schoolId });
}

export function getEmploymentHistory(
  userId: string,
  schoolId: string,
): Promise<PagedResponse<EmploymentHistoryEntry>> {
  const params = {
    school_id: schoolId,
  };

  return api.get(`${USER_URL}${userId}/employment-history`, { params });
}

type AddFamilyParams = {
  schoolId: string;
  family: {
    adults: NewAdult[];
    children: NewFamilyMember[];
  };
};

type AddFamilyResponse = {
  adults: string[];
  children: string[];
  associations: string[];
};

export function addFamily(
  schoolId: AddFamilyParams['schoolId'],
  family: AddFamilyParams['family'],
): Promise<AddFamilyResponse> {
  // `profile_picture` is not allowed here
  const data = {
    school_id: schoolId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    adults: family.adults.map(({ profile_picture, ...adult }) => ({
      school_id: schoolId,
      ...adult,
    })),
    children: family.children.map((child) => ({
      school_id: schoolId,
      ...child,
    })),
  };

  return api.post(`${PROFILE_URL}add-family`, data);
}

export const useAddFamilyMutation = (
  options?: RQUseMutationOptions<AddFamilyResponse, AddFamilyParams>,
): RQUseMutationResult<AddFamilyResponse, AddFamilyParams> => {
  return useMutation(
    (params: AddFamilyParams) => addFamily(params.schoolId, params.family),
    options,
  );
};

export function editFamily(
  schoolId: string,
  child_id: string,
  adults: NewFamilyMember[],
): Promise<{
  adults: string[];
  child_id: string[];
  associations: string[];
}> {
  const data = {
    school_id: schoolId,
    adults,
    child_id,
  };

  return api.patch(`${PROFILE_URL}edit-family`, data);
}

export function verifyEmailSignUp(
  email: string,
  verificationCode: string,
  password: string,
): Promise<boolean> {
  const params = {
    email,
    verification_code: verificationCode,
    password,
  };

  return api.post(`${USER_URL}verify-email-signup`, params);
}

export function acceptInvite(inviteId: string): Promise<string> {
  const params = {
    invite_id: encodeBase64(inviteId),
  };

  return api.post(`${USER_URL}accept-invite`, params);
}

export function verifyEmailForUsername(email: string, verificationCode: string): Promise<string> {
  const params = {
    email,
    verification_code: verificationCode,
  };

  return api.post(`${USER_URL}verify-email-for-username`, params);
}

export function verifyEmail(email: string, code: string): Promise<string> {
  const params = {
    email,
    verification_code: code,
  };

  return api.post(`${USER_URL}verify-email`, params);
}

export function createUnverifiedEmailForUsername(email?: StringOrNull): Promise<boolean> {
  const params = {
    email,
  };

  return api.post(`${USER_URL}create-unverified-email-for-username`, params);
}

export function verifyContactEmail(email: string, verificationCode: string): Promise<string> {
  const params = {
    email,
    verification_code: verificationCode,
  };

  return api.post(`${USER_URL}verify-contact-email`, params);
}

export function createContactEmail(email?: StringOrNull): Promise<boolean> {
  const params = {
    email,
  };

  return api.post(`${USER_URL}set-contact-email`, params);
}

export function requestPasswordReset(email: string): Promise<string> {
  const params = {
    email,
  };

  return api.post(`${USER_URL}create-password-reset-token`, params);
}

export function checkResetToken(token: string, email: string) {
  const params = {
    token,
    email,
  };

  return api.get(`${USER_URL}/check-password-reset-token`, { params });
}

export function updateUserPassword(token: string, email: string, password: string) {
  const params = {
    token,
    email,
    password,
  };
  return api.patch(`${USER_URL}update-user-password`, params);
}

export function checkUserHealth(): Promise<{ ok: boolean }> {
  return api.get(`${USER_URL}healthcheck`);
}

export interface IGetFilters {
  schoolId: string;
  relationId: string;
  section?: FilterSection;
  defaultOnly?: boolean;
}

export interface IDeleteFilters {
  id: string;
  schoolId: string;
  relationId: string;
}

export const getFilters = ({
  schoolId,
  relationId,
  section,
  defaultOnly,
}: IGetFilters): Promise<SavedFilter[]> => {
  const params = {
    school_id: schoolId,
    relation_id: relationId,
    default_only: defaultOnly,
    section,
  };

  return api.get(`${USER_URL}filters`, { params });
};

export const GET_FILTERS_QUERY = `${USER_URL}GET_FILTERS_QUERY`;

export const useGetFiltersQuery = (
  params: IGetFilters,
  options?: RQUseQueryOptions<SavedFilter[]>,
) => {
  return useQuery<SavedFilter[], ApiError>(
    [GET_FILTERS_QUERY, params],
    () => getFilters(params),
    options,
  );
};

export const createFilter = ({
  schoolId,
  relationId,
  section,
  name,
  filter,
  is_default,
}: CreateFilterMutationParams): Promise<SaveFilterResponse> => {
  const params = {
    relation_id: relationId,
    section,
    name,
    is_default,
    filter: {
      ...filter,
      // BE requires this filter to be defined :/
      [FilterKeys.Date]: filter.date || [],
    },
  };

  return api.post(`${USER_URL}filters?school_id=${schoolId}`, params);
};

export type CreateFilterMutationParams = Omit<UpdateFilterMutationParams, 'id'>;

export const useCreateFilterMutation = (
  options?: RQUseMutationOptions<SaveFilterResponse, CreateFilterMutationParams>,
): RQUseMutationResult<SaveFilterResponse, CreateFilterMutationParams> => {
  const queryClient = useQueryClient();

  return useMutation((params: CreateFilterMutationParams) => createFilter(params), {
    ...options,
    onSuccess: (result, variables, ...data) => {
      options?.onSuccess?.(result, variables, ...data);
      queryClient.invalidateQueries([GET_FILTERS_QUERY]);
    },
  });
};

export const updateFilter = ({
  id,
  schoolId,
  section,
  name,
  is_default,
  filter,
}: UpdateFilterMutationParams): Promise<SaveFilterResponse> => {
  const params = {
    section,
    name,
    is_default,
    filter: {
      ...filter,
      // BE requires this filter to be defined :/
      [FilterKeys.Date]: filter.date || [],
    },
  };

  return api.patch(`${USER_URL}filters/${id}?school_id=${schoolId}`, params);
};

type UpdateFilterMutationParams = {
  schoolId: string;
  relationId: string;
  section: FilterSection;
} & SavedFilter;

export const useUpdateFilterMutation = (
  options?: RQUseMutationOptions<SaveFilterResponse, UpdateFilterMutationParams>,
): RQUseMutationResult<SaveFilterResponse, UpdateFilterMutationParams> => {
  const queryClient = useQueryClient();

  return useMutation((params: UpdateFilterMutationParams) => updateFilter(params), {
    ...options,
    onSuccess: (result, variables, ...data) => {
      options?.onSuccess?.(result, variables, ...data);
      queryClient.invalidateQueries([GET_FILTERS_QUERY]);
    },
  });
};

export const removeFilter = ({ id, schoolId, relationId }: IDeleteFilters) => {
  const params = {
    relation_id: relationId,
  };

  return api.remove(`${USER_URL}filters/${id}?school_id=${schoolId}`, params);
};

export const useRemoveFilterMutation = (
  options?: RQUseMutationOptions<any, IDeleteFilters>,
): RQUseMutationResult<any, IDeleteFilters> => {
  const queryClient = useQueryClient();

  return useMutation((params: IDeleteFilters) => removeFilter(params), {
    ...options,
    onSuccess: (result, variables, ...data) => {
      options?.onSuccess?.(result, variables, ...data);
      queryClient.invalidateQueries([GET_FILTERS_QUERY]);
    },
  });
};

export const closeUserAccount = (id: string): Promise<{ success: string }> => {
  return api.remove(`${USER_URL}${id}/account`);
};

type PostFeedbackParams = Partial<{
  source: string;
  email: string;
  name: string;
  role: string;
  organisation: string;
  question: string;
  country: string;
  phoneNumber: string;
  id: string;
  school_name: string;
}>;

export const postFeedback = (params: PostFeedbackParams): Promise<{ success: string }> => {
  return api.post(`${PROFILE_URL}feedback`, {
    ...params,
    date: format(new Date(), DEFAULT_DATE_FORMAT_FNS),
  });
};

export const usePostFeedbackMutation = (
  options?: RQUseMutationOptions<any, PostFeedbackParams>,
): RQUseMutationResult<any, PostFeedbackParams> => {
  return useMutation(postFeedback, options);
};
