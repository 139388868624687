import { Button, IconButton, Stack, Typography } from '@mui/material';
import { CrossIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

export interface RegistrationStatusWarning {
  statusId: string;
  statusName: string;
  statusIndex: number;
  shown: boolean;
}

interface RegistrationStatusWarningProps {
  status: RegistrationStatusWarning;
  onClick: () => void;
  onRemove: () => void;
}

export const RegistrationStatusWarning: FC<RegistrationStatusWarningProps> = ({
  status,
  onClick,
  onRemove,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      direction="row"
      sx={(theme) => ({
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        backgroundColor: theme.palette.primary.main,
        paddingX: 1.5,
        paddingY: 1,
        borderRadius: 1.25,
        width: '100%',
      })}
    >
      <Typography variant="h3" sx={{ color: 'white' }}>
        {formatMessage(
          { id: 'students-RegistrationInformationSkippedSteps' },
          { statusName: status.statusName },
        )}
      </Typography>

      <Stack direction="row" gap={1} flexShrink={0}>
        <Button
          onClick={onClick}
          variant="text"
          sx={(theme) => ({
            height: theme.spacing(3.75),
            marginY: 'auto',
            flex: '0 0 auto',
            padding: theme.spacing(1, 1.5),
            bgcolor: theme.palette.common.white,
          })}
        >
          <Typography sx={(theme) => ({ fontSize: '12px', color: theme.palette.primary.main })}>
            {formatMessage({ id: 'schoolProperty-AddStatus' })}
          </Typography>
        </Button>

        <IconButton sx={{ color: 'white' }} onClick={onRemove}>
          <CrossIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
