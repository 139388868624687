import { Box, Button, Icon, Stack, Typography } from '@mui/material';
import { useCheckXeroAuthQuery, useInitializeXeroAuth, XeroTenant } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { Attention2Icon, Loading, ModalConfirm, ModalFooter } from '@schooly/style';
import React, { FC, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import FormSelect2 from '../../../../components/ui/Input/FormSelect2';
import { ModalContent } from '../../../../components/uikit-components/Modal/Modal.styled';

export type TenantForm = {
  tenantId: XeroTenant['id'];
};

type StartIntegrationModalProps = {
  schoolId: string;
  onSubmit: (f: XeroTenant) => void;
  onCancel: () => void;
  onConnect: (xeroAuthUri: string) => void;
  onClose: () => void;
};

export const StartIntegrationModal: FC<StartIntegrationModalProps> = ({
  schoolId,
  onSubmit,
  onConnect,
  onCancel,
  onClose,
}) => {
  const { $t } = useIntl();
  const { showError } = useNotifications();

  const { data: xeroData, isLoading: isLoadingXeroData } = useInitializeXeroAuth(
    { schoolId },
    { onError: showError },
  );
  const { data, isFetching: isLoadingTenants } = useCheckXeroAuthQuery(
    { schoolId, vacantTenants: true },
    { refetchOnMount: 'always', onError: showError },
  );

  const isLoading = isLoadingXeroData || isLoadingTenants;

  const form = useForm<TenantForm>();
  const selectedTenant = form.watch('tenantId');
  const connectedTenants =
    !isLoadingTenants && !!data?.connected_tenants?.length ? data.connected_tenants : null;

  const handleSubmit = useCallback(
    ({ tenantId }: TenantForm) => {
      const currentTenant = connectedTenants?.find((t) => t.id === tenantId);
      if (!currentTenant) return;
      onSubmit(currentTenant);
    },
    [connectedTenants, onSubmit],
  );

  return (
    <ModalConfirm open fullWidth onClose={onClose}>
      {isLoading ? (
        <Box py={12}>
          <Loading />
        </Box>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalContent active>
              <Typography variant="h1">
                {$t({
                  id: 'legalEntities-CreateWithXeroTitle',
                  //TR-6076 Temporary wording. Connect Xero not only on create will be available in future
                  // id: 'legalEntities-ConnectToXeroTitle',
                })}
              </Typography>

              {/* TR-6076 Temporary warning. Connect Xero not only on create will be available in future */}
              <Stack direction="row" alignItems="center" gap={1} pt={2.5}>
                <Icon
                  sx={{
                    color: 'primary.main',
                    '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                  }}
                >
                  <Attention2Icon />
                </Icon>
                <Typography variant="h3">{$t({ id: 'legalEntity-CreateWarning' })}</Typography>
              </Stack>

              {connectedTenants && (
                <Stack pt={2.25} gap={1}>
                  <Typography noWrap variant="h3" color="common.grey2">
                    {$t({ id: 'legalEntities-SelectTenant' })}
                  </Typography>
                  <Stack direction="row" gap={1}>
                    <FormSelect2
                      name="tenantId"
                      labelTextId="legalEntities-XeroTenant"
                      options={connectedTenants.map((tenant) => ({
                        value: tenant.id,
                        label: tenant.name,
                      }))}
                      noRequiredLabel
                    />
                  </Stack>
                </Stack>
              )}
            </ModalContent>
            <ModalFooter
              active
              sx={{
                justifyContent: 'space-between',
                border: 'none',
                gap: 1,
                pt: 0,
                pb: 2.25,
              }}
            >
              <Button disabled={isLoading} variant="outlined" onClick={onCancel} fullWidth>
                {$t({ id: 'legalEntities-ContinueWithoutXero' })}
              </Button>

              <Button
                disabled={isLoading || (!selectedTenant && !xeroData?.auth_uri)}
                onClick={!selectedTenant ? () => onConnect(xeroData?.auth_uri ?? '') : undefined}
                fullWidth
                type={selectedTenant ? 'submit' : undefined}
              >
                {$t({
                  id: selectedTenant
                    ? 'legalEntities-ConnectExistingTenant'
                    : 'legalEntities-ConnectNewTenant',
                })}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      )}
    </ModalConfirm>
  );
};
