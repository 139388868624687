import { ChipProps } from '@mui/material';
import { ProductTypeId, useGetProductQuery } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type ProductTypeTagSelectProps = {
  id: ProductTypeId;
} & Omit<ChipProps, 'label' | 'id'>;

export const ProductTypeTagSelect: FC<ProductTypeTagSelectProps> = ({ id, ...props }) => {
  const { data } = useGetProductQuery(id.productId);

  const relatedProductType = data?.types.find((t) => t.id === id.typeId);

  return (
    <TagSelect
      label={relatedProductType ? `${data?.name} • ${relatedProductType.name}` : <Spin />}
      {...props}
    />
  );
};
