import { ChipProps } from '@mui/material';
import { PayableFeeStatus } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type FeeStatusTagSelectProps = {
  status: PayableFeeStatus;
} & Omit<ChipProps, 'label'>;

export const FeeStatusTagSelect: FC<FeeStatusTagSelectProps> = ({ status, ...props }) => {
  const { $t } = useIntl();

  return <TagSelect label={$t({ id: `payableFees-status-${status}` })} {...props} />;
};
