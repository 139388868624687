import { Application, ApplicationChild, StringOrNull } from '@schooly/api';
import { CreateChildForm, CreateParentForm } from '@schooly/components/applications';
import { CreateCustomField } from '@schooly/components/applications';
import { createContext } from 'react';

import { ApplicationAction } from '../../redux/slices/applicationSlice';

export interface ApplicationRouteState {
  type: 'application';
  actionType: ApplicationAction;
  parents: CreateParentForm[];
}
export interface ApplyStudentsForm {
  children: Array<
    {
      childId: string;
      relationId: StringOrNull;
      school_year_id?: string;
      half_day?: boolean;
      house_property_id?: string;
      age_group_property_id?: string;
      statuses: Array<{
        school_property_id: string;
        applies_from: string;
        applies_to: string;
        formId?: string;
      }>;
    } & Pick<ApplicationChild, 'given_name' | 'last_name' | 'preferred_start_date'>
  >;
}

export interface RejectApplicationForm {
  share_with_parent: boolean;
  rejection_reason: string;
}
export interface CreateApplicationProps {
  children: CreateChildForm[];
  parents: CreateParentForm[];
  generalCustomFields: CreateCustomField[];
}

export enum ApplicationsTab {
  Open,
  Converted,
  Rejected,
}

export interface ApplicationContextProps {
  application?: Application;
  fetching: boolean;
  applicationId?: string;
  handleApplyStudents: (formData: ApplyStudentsForm) => Promise<string | undefined>;
  canEdit: boolean;
  canView: boolean;
  updateApplicationChildNotes: (child: ApplicationChild) => Promise<string | undefined>;
  updating: Record<string, boolean>;
  rejectApplication: (data: RejectApplicationForm) => Promise<string | undefined>;
  isApplicationManager: boolean;
  activeTab: ApplicationsTab;
  setActiveTab: (tab: ApplicationsTab) => void;
  createApplication: (data: CreateApplicationProps) => Promise<string | undefined>;
  updateApplication: (data: CreateApplicationProps) => Promise<boolean | undefined>;
}

export const ApplicationContext = createContext<ApplicationContextProps>({
  application: undefined,
  fetching: false,
  applicationId: undefined,
  handleApplyStudents: async () => undefined,
  canEdit: false,
  canView: false,
  updateApplicationChildNotes: async () => undefined,
  updating: {},
  rejectApplication: async () => undefined,
  isApplicationManager: false,
  activeTab: ApplicationsTab.Open,
  setActiveTab: () => {},
  createApplication: async () => undefined,
  updateApplication: async () => undefined,
});

ApplicationContext.displayName = 'ApplicationContext';
