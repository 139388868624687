import { Stack } from '@mui/material';
import { InvoicesReportingDayPastDue } from '@schooly/api';
import { FC, useCallback, useRef } from 'react';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { renderDayPastDueTags } from './DayPastDueExpandedSelect';

type DayPastDueSelectProps = {
  selectedTypes: InvoicesReportingDayPastDue[];
  onSelectType: (v: InvoicesReportingDayPastDue) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const DAY_PAST_DUE_TYPES: InvoicesReportingDayPastDue[] = [
  'awaiting_payment',
  'paid_on_time',
  'paid_less_than_2_weeks_late',
  'paid_less_than_4_weeks_late',
  'paid_more_than_4_weeks_late',
  'overdue_by_less_than_2_weeks',
  'overdue_by_less_than_4_weeks',
  'overdue_by_more_than_4_weeks',
];

export const DayPastDueSelectMultiple: FC<DayPastDueSelectProps> = ({
  selectedTypes,
  onSelectType,
  placeholder,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderDayPastDueTags({
          types: DAY_PAST_DUE_TYPES,
          tagProps: (type) => ({
            variant: selectedTypes.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      placeholder={placeholder}
      hasValues={selectedTypes.length > 0}
      renderContent={renderContent}
    >
      {() =>
        renderDayPastDueTags({
          types: selectedTypes,
          tagProps: { sx: { maxWidth: 200 }, size: 'small' },
          onDelete: onSelectType,
        })
      }
    </DropdownSelect>
  );
};
