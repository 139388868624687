import { Box, Stack, TableCellProps, Typography } from '@mui/material';
import { AttendanceEntryForPeriod, useGetAttendanceCodesQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { HashIcon, Loading, PercentIcon } from '@schooly/style';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import PersonCardBasic from '../../../components/common/PersonCard/PersonCardBasic';
import { GridCell, GridHeaderCellContent } from '../../../components/uikit-components/Grid/Grid';
import { Table, TableCell } from '../../../components/uikit-components/Table/Table';
import { useGroupAttendanceStatistics } from '../../../context/groups/useGroupAttendanceStatistics';
import { useAttendanceStatistics } from '../../../hooks/useAttendanceStatistics';
import { getAbsentTotal, getPresentTotal } from '../../Attendance/attendance-utils';
import {
  PercentButton,
  PercentIconButton,
} from '../../Attendance/AttendanceStatistics/AttendanceStats.styled';
import { GroupAttendanceStatisticsEmptyStub } from './GroupAttendanceStatisticsEmptyStub/GroupAttendanceStatisticsEmptyStub';
import { GroupAttendanceStatisticsModalToggleMode } from './GroupAttendanceStatisticsModalToggleMode';

const LEFT_COL_WIDTH = 260;

export const GroupAttendanceStatisticsModalListStudents: FC = () => {
  const { schoolId = '' } = useAuth();
  const { data: codes } = useGetAttendanceCodesQuery(schoolId);
  const {
    students,
    query,
    isFetching,
    isFetchingNextPage,
    canShowMore,
    actions,
    showByPresentAbsent,
    showByPercent,
  } = useGroupAttendanceStatistics();

  const { renderCount, getTotalCount } = useAttendanceStatistics(1, showByPercent);

  const loaderRef = useInfiniteScroll(isFetching || isFetchingNextPage, actions.handleShowMore);
  const isLoading = isFetching && !students;

  const entries = useMemo(
    () =>
      students?.pages.reduce<AttendanceEntryForPeriod[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? [],
    [students?.pages],
  );

  /* Students attendance map by codes */
  const studentsAttendance = useMemo(
    () =>
      entries.reduce<
        Record<string, { codes: Record<string, number>; total: number; noStatus: number }>
      >((prev, student) => {
        prev[student.relation_id] = { codes: {}, total: 0, noStatus: 0 };

        student.attendance_details.forEach((item) => {
          if (item.attendance_code_id) {
            prev[student.relation_id].codes[item.attendance_code_id] = item.count;
          } else {
            prev[student.relation_id].noStatus += item.count;
          }
          prev[student.relation_id].total += item.count;
        });

        return prev;
      }, {}),
    [entries],
  );

  const attendanceCodes = useMemo(() => codes ?? [], [codes]);

  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'Header',
        renderContent: (props) => (
          <GridCell
            noHorizontalPadding
            sx={{
              maxWidth: LEFT_COL_WIDTH,
              minWidth: LEFT_COL_WIDTH,
              '&.MuiTableCell-head': { pb: 1 },
            }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <GroupAttendanceStatisticsModalToggleMode />
            </GridHeaderCellContent>
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
      ...(!showByPresentAbsent
        ? attendanceCodes.map((code) => ({
            id: code.id,
            renderContent: (props: TableCellProps) => (
              <GridCell
                key={`th-${code.code}`}
                borderBottom
                sx={(theme) => ({
                  minWidth: theme.spacing(6.5),
                  width: `${100 / attendanceCodes.length}%`,
                  '&.MuiTableCell-head': { pb: 1 },
                })}
                {...props}
              >
                <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                  {code.name}
                </GridHeaderCellContent>
              </GridCell>
            ),
          }))
        : [
            {
              id: 'attendance-Present',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  noHorizontalPadding
                  sx={{
                    maxWidth: LEFT_COL_WIDTH,
                    minWidth: LEFT_COL_WIDTH,
                    '&.MuiTableCell-head': { pb: 1 },
                  }}
                  {...props}
                >
                  <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                    <FormattedMessage id="attendance-Present" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
            {
              id: 'attendance-Absent',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  noHorizontalPadding
                  sx={{
                    maxWidth: LEFT_COL_WIDTH,
                    minWidth: LEFT_COL_WIDTH,
                    '&.MuiTableCell-head': { pb: 1 },
                  }}
                  {...props}
                >
                  <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
                    <FormattedMessage id="attendance-Absent" />
                  </GridHeaderCellContent>
                </GridCell>
              ),
            },
          ]),
      {
        id: 'attendance-NoStatus',
        renderContent: (props) => (
          <GridCell
            sx={{
              minWidth: 90,
              '&.MuiTableCell-head': {
                pb: 1,
              },
            }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <FormattedMessage id="attendance-NoStatus" />
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
      {
        id: 'attendance-Total',
        renderContent: (props) => (
          <GridCell
            borderBottom
            sx={{
              minWidth: 90,
              '&.MuiTableCell-head': { pb: 1, pr: 0 },
            }}
            {...props}
          >
            <GridHeaderCellContent sx={{ lineHeight: '14px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <FormattedMessage id="attendance-Total" />

                <PercentButton variant="outlined">
                  <PercentIconButton onClick={() => actions.setShowByPercent(!showByPercent)}>
                    {showByPercent ? <HashIcon /> : <PercentIcon />}
                  </PercentIconButton>
                </PercentButton>
              </Stack>
            </GridHeaderCellContent>
          </GridCell>
        ),
      },
    ],
    [attendanceCodes, showByPresentAbsent, showByPercent, actions],
  );

  const rows = useMemo(() => {
    return entries.map((student) => {
      const inSchoolCount = getPresentTotal(attendanceCodes ?? [], student.attendance_details);
      const outOfSchoolCount = getAbsentTotal(attendanceCodes ?? [], student.attendance_details);
      const totalCount = studentsAttendance?.[student.relation_id]?.total;

      const cellArray: TableCell[] = [
        {
          id: 'students',
          renderContent: (props) => (
            <GridCell
              noVerticalPadding
              sx={{ maxWidth: LEFT_COL_WIDTH, minWidth: LEFT_COL_WIDTH }}
              {...props}
            >
              <Box
                sx={{
                  width: LEFT_COL_WIDTH,
                  '& .card-body': { pt: '6px !important', pb: '6px !important' },
                }}
              >
                <PersonCardBasic user={student} userType="student" isListItem isUsernameClickable />
              </Box>
            </GridCell>
          ),
        },
        ...(!showByPresentAbsent
          ? attendanceCodes.map((code) => ({
              renderContent: (props: TableCellProps) => (
                <GridCell key={`td-${student.relation_id}-${code.id}`} py={1.5} {...props}>
                  {renderCount(
                    studentsAttendance?.[student.relation_id]?.codes[code.id],
                    totalCount,
                  )}
                </GridCell>
              ),
              id: code.id,
            }))
          : [
              {
                id: 'attendance-Present',
                renderContent: (props: TableCellProps) => (
                  <GridCell noHorizontalPadding py={1.5} {...props}>
                    {renderCount(inSchoolCount, totalCount)}
                  </GridCell>
                ),
              },
              {
                id: 'attendance-Absent',
                renderContent: (props: TableCellProps) => (
                  <GridCell noHorizontalPadding py={1.5} {...props}>
                    {renderCount(outOfSchoolCount, totalCount)}
                  </GridCell>
                ),
              },
            ]),
        {
          id: 'attendance-NoStatus',
          renderContent: (props) => (
            <GridCell noVerticalPadding borderLeft {...props}>
              {!!studentsAttendance?.[student.relation_id]?.noStatus ? (
                <Typography color="text.primary">
                  {renderCount(studentsAttendance?.[student.relation_id]?.noStatus, totalCount)}
                </Typography>
              ) : (
                <Typography color="text.secondary">-</Typography>
              )}
            </GridCell>
          ),
        },
        {
          id: 'attendance-Total',
          renderContent: (props) => (
            <GridCell noVerticalPadding {...props}>
              <Typography color="text.primary">{getTotalCount(totalCount)}</Typography>
            </GridCell>
          ),
        },
      ];

      return { id: student.relation_id, cells: cellArray };
    });
  }, [
    attendanceCodes,
    getTotalCount,
    renderCount,
    entries,
    studentsAttendance,
    showByPresentAbsent,
  ]);

  const total = students?.pages[0]?.count;

  const isEmpty = !isLoading && !total;

  return (
    <Stack
      sx={{
        overflowY: 'auto',
        flexGrow: isEmpty || isLoading ? 1 : 0,
        mx: 2.5,
      }}
    >
      <Table
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        isEmpty={isEmpty}
        renderEmptyContent={
          query ? <NoSearchResultsFound type="small" /> : <GroupAttendanceStatisticsEmptyStub />
        }
        emptyContentSxProps={{
          mx: 0,
        }}
      />

      {canShowMore && (
        <>
          {isFetchingNextPage && (
            <Box py={3}>
              <Loading />
            </Box>
          )}
          <div ref={loaderRef} />
        </>
      )}
    </Stack>
  );
};
