import { useCallback, useContext } from 'react';

import { ConfirmationDialogContext, IDialogConfig } from './ConfirmationDialogContext';

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = useCallback(
    ({ ...options }: Omit<IDialogConfig, 'callback'>): Promise<boolean | undefined> =>
      new Promise((res) => {
        openDialog({ callback: res, ...options });
      }),
    [openDialog],
  );

  return { getConfirmation };
};
