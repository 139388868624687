import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { ApplicationStatus, useGetApplicationsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { CrossIcon, ModalHeader, ModalLarge } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import HeaderFilter from '../../../components/common/HeaderFilter/HeaderFilter';
import { ApplicationsTab } from '../../../context/applications/ApplicationsContext';
import { useApplication } from '../../../context/applications/useApplication';
import { WithFilters } from '../../../context/filters/WithFilters';
import { ApplicationListContent } from './ApplicationListContent';
import { ApplicationsSearchContent } from './ApplicationsSearchContent';

const selectedApplicationTabParams: { [key in ApplicationsTab]: ApplicationStatus } = {
  [ApplicationsTab.Open]: 'open',
  [ApplicationsTab.Converted]: 'converted',
  [ApplicationsTab.Rejected]: 'rejected',
};

export const ApplicationListModal: FC = () => {
  const { schoolId = '' } = useAuth();
  const { setActiveTab, fetching, activeTab } = useApplication();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { data: applicationList, isLoading } = useGetApplicationsQuery(
    {
      school_id: schoolId,
      status: selectedApplicationTabParams[activeTab],
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <WithFilters type="applications">
      <ModalLarge open>
        <ModalHeader active title={formatMessage({ id: 'applications-Title-plural' })}>
          <IconButton onClick={() => navigate('/students')}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <Stack direction="row" px={2.5} pt={2.5}>
          <Tabs sx={{ height: 36, minHeight: 36 }} value={activeTab} onChange={handleTabChange}>
            <Tab label={formatMessage({ id: 'applications-Status-Open' })} />
            <Tab label={formatMessage({ id: 'applications-Status-Converted' })} />
            <Tab label={formatMessage({ id: 'applications-Status-Rejected' })} />
          </Tabs>
          <Box
            sx={{
              '& .HeaderFilter, .search-wrapper': { maxWidth: '100%' },
              flexGrow: 1,
              pl: 10,
            }}
          >
            <HeaderFilter
              forceZIndex
              isModalOpen
              customSearchRenderer={(onCloseWithDelay) => (
                <ApplicationsSearchContent
                  applicationList={applicationList ?? []}
                  onClose={onCloseWithDelay}
                />
              )}
            />
          </Box>
        </Stack>
        <ApplicationListContent
          applicationsFetching={fetching || isLoading}
          applications={applicationList}
        />
      </ModalLarge>
    </WithFilters>
  );
};
