import { Box, IconButton, Stack, Typography } from '@mui/material';
import { getTypedObjectKeys, PayableFee } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import { EmptyListSvg } from '@schooly/style';
import {
  ChevronUpIcon,
  Counter,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, getMonth, getYear } from 'date-fns';
import React, { FC, ReactNode, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { useWithRef } from '../../../hooks/useWithRef';
import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';
import { payableFeeHasInvoice } from './helpers';
import { PayableFeesRelationType } from './PayableFees';
import { PayableFeeTable } from './PayableFeeTable';

type PayableFeesContentProps = {
  data: PayableFee[];
  relationType: PayableFeesRelationType;
  isCurrentYearSelected: boolean;
  schoolId: string;
};

export const PayableFeesContent: FC<PayableFeesContentProps> = ({
  data,
  relationType,
  isCurrentYearSelected,
  schoolId,
}) => {
  const payableFeesMap = useMemo(
    () =>
      data
        .sort((a, b) => {
          const dateA = newDateTimezoneOffset(b.issue_date);
          const dateB = newDateTimezoneOffset(a.issue_date);
          const byYear = getYear(dateA) - getYear(dateB);
          if (byYear !== 0) return byYear;

          return getMonth(dateA) - getMonth(dateB);
        })
        .reduce<Record<string, PayableFee[]>>((acc, next) => {
          const date = newDateTimezoneOffset(next.issue_date);
          const label = `${format(date, 'MMM')} ${getYear(date)}`;
          const data = acc[label];

          return { ...acc, [label]: data ? [...data, next] : [next] };
        }, {}),
    [data],
  );

  const monthLabels = getTypedObjectKeys(payableFeesMap);
  const { $t } = useIntl();
  const currentMonthLabel = `${format(newDateTimezoneOffset(), 'MMM')} ${getYear(
    newDateTimezoneOffset(),
  )}`;

  const isMultipleItemsView = relationType === 'parent';

  if (!monthLabels.length) {
    return (
      <Stack flex={1} alignItems="center" gap={2.5} p={2} pt={5}>
        <EmptyListSvg />
        <Typography textAlign="center" variant="h3" color="text.primary" maxWidth={280}>
          {$t({ id: 'profile-NoResultsMatching' })}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={3.75}>
      {monthLabels.map((monthLabel, i) => {
        const isCurrentMonth = isCurrentYearSelected ? currentMonthLabel === monthLabel : false;
        const isFirstMonth = !isCurrentYearSelected && monthLabels.length > 1 && i === 0;
        return (
          <Box key={monthLabel}>
            <MonthAccordion
              monthLabel={monthLabel}
              payableFees={payableFeesMap[monthLabel]}
              opened={isCurrentMonth || monthLabels.length === 1 || isFirstMonth}
              scrollTop={isCurrentMonth}
            >
              {(opened) =>
                opened ? (
                  <Stack gap={3} pt={1.5}>
                    {payableFeesMap[monthLabel].map((payableFee) => {
                      const [student] = payableFee.students;
                      const key = `${payableFee.status}-${payableFee.due_date}-${
                        payableFee.issue_date
                      }-${student ? student.id : ''}`;

                      return (
                        <PayableFeeTable
                          key={key}
                          payableFee={payableFee}
                          relationType={relationType}
                          isMultipleItemsView={isMultipleItemsView}
                          schoolId={schoolId}
                        />
                      );
                    })}
                  </Stack>
                ) : null
              }
            </MonthAccordion>
          </Box>
        );
      })}
    </Stack>
  );
};

type MonthAccordionProps = {
  payableFees: PayableFee[];
  monthLabel: string;
  children: (opened: boolean) => ReactNode;
  opened: boolean;
  scrollTop?: boolean;
};

export const MonthAccordion: FC<MonthAccordionProps> = ({
  monthLabel,
  payableFees,
  children,
  opened: initialOpened,
  scrollTop,
}) => {
  const [opened, open, hide] = useFlag(initialOpened);
  const ref = useRef<HTMLDivElement>(null);
  const { containerRef: mainContainerRef } = useWithRef();

  const { sum, counter } = useMemo(
    () => ({
      sum: payableFees.reduce<Record<string, number>>(
        (acc, { currency, total_payment }) => {
          const symbol = getCurrencySymbol(currency);
          const total = acc[symbol] ? acc[symbol] + total_payment : total_payment;

          return { ...acc, [symbol]: total };
        },

        {},
      ),
      counter: payableFees.reduce((acc, next) => {
        let items = next.items;
        if (payableFeeHasInvoice(next)) {
          items = next.items.filter((item) => !item.is_deleted);
        }
        return acc + items.length;
      }, 0),
    }),
    [payableFees],
  );

  useEffect(() => {
    if (scrollTop && mainContainerRef?.current) {
      const top =
        (ref.current?.getBoundingClientRect().top ?? 0) -
        mainContainerRef.current.getBoundingClientRect().top;

      mainContainerRef?.current?.scrollTo({
        top: top > 0 ? top : undefined,
        behavior: 'smooth',
      });
    }
  }, [mainContainerRef, scrollTop]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        onClick={opened ? hide : open}
        ref={ref}
        alignItems="center"
        sx={(theme) => ({
          ...(opened && {
            [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
              color: 'common.grey2',
            },
          }),

          '& .counter': {
            ...(!opened && {
              color: 'primary.main',
              bgcolor: 'transparent',
              border: `1px solid ${theme.palette.primary.main}`,
            }),
          },

          ':hover': {
            cursor: 'pointer',
            [` .${PRICE_SUBTEXT_CLASS_NAME}`]: {
              color: 'common.grey2',
            },
            '.arrowIcon': {
              color: 'primary.main',
            },
          },
        })}
      >
        <Stack direction="row" alignItems="center">
          <TypographyWithOverflowHint variant="h3" width={76}>
            {monthLabel.toLocaleUpperCase()}
          </TypographyWithOverflowHint>
          <Counter
            className="counter"
            sx={{ minWidth: 20, minHeight: 20, fontWeight: 500, textAlign: 'center', ml: 0 }}
          >
            {counter}
          </Counter>
        </Stack>

        <Stack direction="row" alignItems="center" pr={1} gap={6.25}>
          <Stack direction="row" alignItems="center">
            {Object.entries(sum).map(([currency, priceNumber], i, arr) => {
              const price = (
                <Price
                  currency={currency}
                  price={priceNumber}
                  color={opened ? 'primary.main' : 'common.grey2'}
                />
              );
              if (arr.length === 1 || i === 0) return price;

              return (
                <React.Fragment key={currency}>
                  {i !== arr.length - 1 ? (
                    <>
                      {' ,'}
                      {price}
                    </>
                  ) : (
                    <Stack direction="row" alignItems="center">
                      <Typography variant="h3" px={1} color="common.grey2">
                        &
                      </Typography>
                      {price}
                    </Stack>
                  )}
                </React.Fragment>
              );
            })}
          </Stack>

          <IconButton
            size="small"
            className="arrowIcon"
            sx={(theme) => ({
              transform: !opened ? 'rotate(180deg)' : undefined,
              color: opened ? theme.palette.primary.main : undefined,
            })}
            inverse
          >
            <ChevronUpIcon />
          </IconButton>
        </Stack>
      </Stack>
      {children(opened)}
    </>
  );
};
