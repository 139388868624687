import { ChipProps } from '@mui/material';
import { useGetProductQuery } from '@schooly/api';
import { Spin, TagSelect } from '@schooly/style';
import { FC } from 'react';

export type ProductTagSelectProps = {
  id: string;
} & Omit<ChipProps, 'label'>;

export const ProductTagSelect: FC<ProductTagSelectProps> = ({ id, ...props }) => {
  const { data } = useGetProductQuery(id);

  return <TagSelect label={data ? data.name : <Spin />} {...props} />;
};
