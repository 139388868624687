import { Stack } from '@mui/material';
import { InvoicesReportingDayPastDue } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { DAY_PAST_DUE_TYPES } from './DayPastDueSelectMultiple';
import { DayPastDueTagSelect, DayPastDueTagSelectProps } from './DayPastDueTagSelect';

type DayPastDueExpandedSelectProps = PropsWithChildren<{
  selectedTypes: InvoicesReportingDayPastDue[];
  onSelectType: (v: InvoicesReportingDayPastDue) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const DayPastDueExpandedSelect: FC<DayPastDueExpandedSelectProps> = ({
  selectedTypes,
  onSelectType,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderDayPastDueTags({
          types: DAY_PAST_DUE_TYPES,
          tagProps: (type) => ({
            variant: selectedTypes.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedTypes.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
    >
      {renderDayPastDueTags({
        types: selectedTypes,
        tagProps: { sx: { maxWidth: 200 }, size: 'small' },
        onDelete: onSelectType,
      })}
    </ExpandedSelect>
  );
};

type RenderDayPastDueTagsParams = {
  types: InvoicesReportingDayPastDue[];
  onDelete?: (v: InvoicesReportingDayPastDue) => void;
  onClick?: (v: InvoicesReportingDayPastDue) => void;
  tagProps?:
    | Omit<DayPastDueTagSelectProps, 'type'>
    | ((v: InvoicesReportingDayPastDue) => Omit<DayPastDueTagSelectProps, 'type'>);
};

export const renderDayPastDueTags = ({
  types,
  onDelete,
  onClick,
  tagProps,
}: RenderDayPastDueTagsParams) =>
  types.map((v) => (
    <DayPastDueTagSelect
      key={v}
      type={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
