import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup';
import { changeGroupSubjectAttendanceDefault, GET_GROUP_SUBJECTS_QUERY } from '@schooly/api';
import { ApiError, FilterKeys, FilterValue } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { Attention2Icon, CheckIcon, Spin } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash.isequal';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FilterSelect from '../../../../components/common/HeaderFilter/FilterSelect';
import usePrevious from '../../../../hooks/usePrevious';
import { DELAY_BEFORE_CHANGE_STATUS } from '../../../ProfileModal/ProfileModalHeader/InviteButton';

export const SchoolAttendanceAvailability: FC = () => {
  const { $t } = useIntl();

  const { schoolId = '' } = useAuth();
  const { subjects: availableSubjects } = useSubjects(
    { schoolId },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );
  const queryClient = useQueryClient();

  const defaultSubjects =
    availableSubjects?.filter((s) => !!s.attendance_default).map(({ id }) => id) ?? [];

  const prevSubjects = usePrevious(defaultSubjects);

  const [type, setType] = useState<'once' | 'twice' | 'period'>('once');
  const [subjects, setSubjects] = useState<FilterValue[]>(defaultSubjects);
  const [loading, setLoading] = useState(false);
  const [changesCompleted, setChangesCompleted] = useState(false);

  const { showError } = useNotifications();

  const subjectOptions = useMemo(
    () =>
      availableSubjects?.map((s) => ({
        value: s.id,
        label: s.name,
        item: s,
        archived: s.archived,
      })) ?? [],
    [availableSubjects],
  );

  const statusIcon = useMemo(() => {
    if (loading) {
      return <Spin />;
    }

    return changesCompleted ? <CheckIcon /> : null;
  }, [changesCompleted, loading]);

  const handleTypeChange = useCallback<Exclude<RadioGroupProps['onChange'], undefined>>(
    (event, value) => {
      setType(value as 'once' | 'twice' | 'period');
    },
    [],
  );

  /** disabled while 'Every period' option is not implemented on BE */
  const onSubmit = useCallback(async () => {
    if (!schoolId || isEqual(prevSubjects, subjects)) {
      return;
    }
    setLoading(true);
    try {
      const data = [
        ...(prevSubjects
          ?.filter((s) => !subjects.includes(s))
          .map((subject_id) => ({ subject_id, attendance_default: false })) ?? []),

        ...subjects.map((id) => ({ subject_id: id as string, attendance_default: true })),
      ];

      await changeGroupSubjectAttendanceDefault({
        schoolId,
        subjects: data,
      });

      queryClient.invalidateQueries([GET_GROUP_SUBJECTS_QUERY]);

      setLoading(false);
      setChangesCompleted(true);

      setTimeout(() => {
        setChangesCompleted(false);
      }, DELAY_BEFORE_CHANGE_STATUS);
    } catch (err) {
      showError(err as ApiError);
      setSubjects(prevSubjects ?? []);
      setLoading(false);
    }

    setLoading(false);
  }, [prevSubjects, schoolId, queryClient, showError, subjects]);

  return (
    <Card>
      <Stack sx={{ p: 2.5 }} direction="row" alignItems="stretch">
        <Box>
          <Typography variant="h2" whiteSpace="nowrap">
            <FormattedMessage id="attendance-AvailableFor" />
          </Typography>
        </Box>

        <FormControl sx={{ flex: '0 0 auto', pt: 0.5, pl: 5 }}>
          <RadioGroup value={type} onChange={handleTypeChange}>
            <FormControlLabel
              value="once"
              control={<Radio />}
              label={$t({ id: 'attendance-AvailableFor-once' })}
            />
            <FormControlLabel
              value="twice"
              control={<Radio />}
              disabled
              label={
                <>
                  <FormattedMessage id="attendance-AvailableFor-twice" />{' '}
                  <Tooltip
                    title={$t({ id: 'attendance-AvailableFor-optionDisabled' })}
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    <IconButton inverse sx={{ '& circle, & rect': { color: 'common.white' } }}>
                      <Attention2Icon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
            <FormControlLabel
              value="period"
              control={<Radio />}
              disabled
              label={
                <>
                  <FormattedMessage id="attendance-AvailableFor-period" />{' '}
                  <Tooltip
                    title={$t({ id: 'attendance-AvailableFor-optionDisabled' })}
                    sx={{ whiteSpace: 'pre-wrap' }}
                  >
                    <IconButton inverse sx={{ '& circle, & rect': { color: 'common.white' } }}>
                      <Attention2Icon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          </RadioGroup>
        </FormControl>

        {type === 'period' && (
          <Stack
            flex="1 1 100%"
            justifyContent="flex-end"
            whiteSpace="nowrap"
            minWidth={350}
            pl={5}
            mb={-1}
          >
            <FilterSelect
              value={subjects}
              filterKey={FilterKeys.Subject}
              onChange={setSubjects}
              popupTitleTextId=""
              options={subjectOptions}
              customPlaceholderTextId="school-sections-Attendance-SetSubjects"
              onClose={onSubmit}
            />
          </Stack>
        )}

        <Icon sx={{ ml: 2, height: '100%' }} color="primary">
          {statusIcon}
        </Icon>
      </Stack>
    </Card>
  );
};
