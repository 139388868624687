import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { EventsStatuses, FilterKeys, GetEventsQueryFilters, RepetitionType } from '@schooly/api';
import {
  DropdownSelectFilter,
  EventRecurrenceSelect,
  EventsParticipationOptions,
  EventsStatusOptions,
  getEventsStatusLabel,
  getParticipantLabel,
} from '@schooly/components/filters';
import { EventsInvite } from '@schooly/constants';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { useSchool } from '../../../../hooks/useSchool';

type GroupPreviewModalEventsFiltersPopupProps = {
  groupId: string;
  onClose: () => void;
  onSetFilters: (v: GetEventsQueryFilters) => void;
  filters: GetEventsQueryFilters;
};

export const GroupPreviewModalEventsFiltersPopup: FC<GroupPreviewModalEventsFiltersPopupProps> = ({
  groupId,
  onClose,
  onSetFilters,
  filters: initialFilters,
}) => {
  const { $t } = useIntl();
  const { schoolId } = useSchool();
  const [filters, setFilters] = useState<GetEventsQueryFilters>(initialFilters);

  const handleApply = useCallback(() => {
    onSetFilters(filters);
    onClose();
  }, [onSetFilters, filters, onClose]);

  const { onSelectStatus, onClearStatus } = useMemo(() => {
    return {
      onSelectStatus: (id: EventsStatuses) =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.EventStatus]: filters[FilterKeys.EventStatus]?.includes(id)
            ? filters[FilterKeys.EventStatus]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.EventStatus] || []), id],
        })),
      onClearStatus: () =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.EventStatus]: [],
        })),
    };
  }, []);

  const { onSelectParticipation, onClearParticipation } = useMemo(() => {
    return {
      onSelectParticipation: (id: EventsInvite) =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.InviteType]: filters[FilterKeys.InviteType]?.includes(id)
            ? filters[FilterKeys.InviteType]?.filter((ct) => ct !== id)
            : [...(filters[FilterKeys.InviteType] || []), id],
        })),
      onClearParticipation: () =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.InviteType]: [],
        })),
    };
  }, []);

  const { onSelectRecurrence, onClearRecurrence } = useMemo(() => {
    return {
      onSelectRecurrence: (id: string) =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [id],
        })),
      onClearRecurrence: () =>
        setFilters((filters) => ({
          ...filters,
          [FilterKeys.RecurrenceId]: [],
        })),
    };
  }, []);

  if (!schoolId) return null;
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: 600,
        },
      }}
      open
      onClose={onClose}
    >
      <ModalHeader active title={$t({ id: 'groups-Conduct-FilterConduct' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalContent active>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box flex={3}>
            <Typography color="common.grey2">{$t({ id: 'eventSignUps-Participation' })}</Typography>
          </Box>
          <Box flex={7}>
            <DropdownSelectFilter
              borderOnHover
              getLabel={(s) => $t({ id: getParticipantLabel(s) })}
              onSelect={onSelectParticipation}
              selected={filters[FilterKeys.InviteType] || []}
              options={EventsParticipationOptions}
              onClear={onClearParticipation}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box flex={3}>
            <Typography color="common.grey2">{$t({ id: 'events-status' })}</Typography>
          </Box>
          <Box flex={7}>
            <DropdownSelectFilter
              borderOnHover
              getLabel={(s) => $t({ id: getEventsStatusLabel(s) })}
              onSelect={onSelectStatus}
              selected={filters[FilterKeys.EventStatus] || []}
              options={EventsStatusOptions}
              onClear={onClearStatus}
            />
          </Box>
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Box flex={3}>
            <Typography color="common.grey2">{$t({ id: 'events-recurring' })}</Typography>
          </Box>
          <Box flex={7}>
            <EventRecurrenceSelect
              borderOnHover
              onClear={onClearRecurrence}
              schoolId={schoolId}
              onSelectRecurrence={onSelectRecurrence}
              selectedId={filters[FilterKeys.RecurrenceId]?.[0]}
              filters={{
                [FilterKeys.RepetitionType]: [RepetitionType.FirstOfRecurrence],
                [FilterKeys.Group]: [groupId],
              }}
            />
          </Box>
        </Stack>
      </ModalContent>
      <ModalFooter active>
        <Button onClick={handleApply}>{$t({ id: 'filter-Apply' })}</Button>
      </ModalFooter>
    </Dialog>
  );
};
