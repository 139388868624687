import { useGetGroupDetailsQuery } from '@schooly/api';
import { Loading, ModalSmall } from '@schooly/style';
import { FC, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useEvent } from '../../../context/events/WithEvent';
import { useRouter } from '../../../context/router/useRouter';
import { defaultValues, EventCreateContent, EventCreateContentProps } from './EventCreateContent';

export interface EventCreateModalProps extends EventCreateContentProps {}

export const EVENT_CREATE_GROUP_ID_SEARCH_PARAM = 'group_id';

export const EventCreateModal: FC<EventCreateModalProps> = ({
  defaultValues: propsDefaultValues,
  onClose: propsOnClose,
  ...rest
}) => {
  const { fetching } = useEvent();
  const [searchParams] = useSearchParams();
  const { goBack } = useRouter();

  const groupId = searchParams.get(EVENT_CREATE_GROUP_ID_SEARCH_PARAM);
  const onClose = groupId ? goBack : propsOnClose;

  const { data, isLoading: isGroupLoading } = useGetGroupDetailsQuery(
    {
      id: groupId || '',
    },
    { enabled: !!groupId },
  );

  const eventCreateDefaultValues = useMemo(() => {
    if (!groupId || !data) return propsDefaultValues;

    return {
      ...defaultValues,
      ...propsDefaultValues,
      criteria: {
        ...defaultValues.criteria,
        ...propsDefaultValues?.criteria,
        group: [
          {
            id: groupId,
            name: data.group.name,
          },
        ],
      },
    };
  }, [data, groupId, propsDefaultValues]);

  return (
    <ModalSmall open>
      {fetching || (!!groupId && isGroupLoading) ? (
        <Loading />
      ) : (
        <EventCreateContent defaultValues={eventCreateDefaultValues} onClose={onClose} {...rest} />
      )}
    </ModalSmall>
  );
};
