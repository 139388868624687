import { useAuth } from '@schooly/components/authentication';
import Formbricks from '@schooly/formbricks';
import { FC, useEffect } from 'react';

import { ENV_TYPE, IS_DEV } from '../../config';

export const FormbricksSurvey: FC = () => {
  const { currentUser } = useAuth();

  const userId = currentUser?.user_id;
  const email = currentUser?.account_email;

  useEffect(() => {
    if (!userId || !email) return;

    Formbricks.init({
      userId,
      email,
      isDev: IS_DEV || ENV_TYPE !== 'prod',
    }).then(Formbricks.showSurvey);
  }, [userId, email]);

  return null;
};
