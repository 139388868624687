import React from 'react';

import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import { useGroup } from '../../../context/groups/useGroup';
import { AddGroupModalSection } from '../AddGroupModal/AddGroupModal';
import { ViewGroupModalTabs } from '../utils';
import { GroupPreviewModalAssessments } from './GroupPreviewModalAssessments/GroupPreviewModalAssessments';
import { GroupPreviewModalAttendance } from './GroupPreviewModalAttendance/GroupPreviewModalAttendance';
import { GroupPreviewModalConduct } from './GroupPreviewModalConduct/GroupPreviewModalConduct';
import { GroupPreviewModalEvents } from './GroupPreviewModalEvents/GroupPreviewModalEvents';
// import { GroupPreviewModalSignUps } from './GroupPreviewModalEvents/GroupPreviewModalSignUps';
import { GroupPreviewModalMembers } from './GroupPreviewModalMembers/GroupPreviewModalMembers';
import { GroupPreviewModalMessages } from './GroupPreviewModalMessages/GroupPreviewModalMessages';

export interface ViewGroupModalContentProps {
  showStaffEditButton?: boolean;
  showStudentsEditButton?: boolean;
  handleEditGroupClick: (section: AddGroupModalSection) => void;
}

export const GroupPreviewModalContent: React.FC<ViewGroupModalContentProps> = ({
  showStaffEditButton,
  showStudentsEditButton,
  handleEditGroupClick,
}) => {
  const { activeTab } = useGroup();

  const renderContent = () => {
    switch (activeTab) {
      case ViewGroupModalTabs.Attendance:
        return <GroupPreviewModalAttendance />;
      case ViewGroupModalTabs.Members:
        return (
          <GroupPreviewModalMembers
            onGroupEdit={handleEditGroupClick}
            showStaffEditButton={showStaffEditButton}
            showStudentsEditButton={showStudentsEditButton}
          />
        );
      case ViewGroupModalTabs.Assessments:
        return <GroupPreviewModalAssessments />;
      case ViewGroupModalTabs.Messages:
        return <GroupPreviewModalMessages />;
      case ViewGroupModalTabs.Conduct:
        return <GroupPreviewModalConduct />;
      case ViewGroupModalTabs.Events:
        return <GroupPreviewModalEvents />;
      // Signups are disabled untill group filter is available on BE for signups
      // case ViewGroupModalTabs.SignUps:
      //   return <GroupPreviewModalSignUps />;
      default:
        return null;
    }
  };

  return (
    <ModalContent
      sx={{
        paddingTop: (theme) => theme.spacing(1.5),
      }}
    >
      {renderContent()}
    </ModalContent>
  );
};
