import { useAuth } from '@schooly/components/authentication';
import React, { FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import { SchoolUserRole } from '../../constants/school';
import {
  allowRenderAssessments,
  allowRenderAttendance,
  allowRenderCompanies,
  allowRenderConduct,
  allowRenderEvents,
  allowRenderGroups,
  allowRenderMessages,
  allowRenderParents,
  allowRenderPayableFees,
  allowRenderPlanner,
  allowRenderReports,
  allowRenderSignUps,
  allowRenderStaff,
  allowRenderStudents,
  allowRenderUserRoles,
} from '../../helpers/renderRoutesByPermissions';
import useAppLocation, { AppLocation } from '../../hooks/useAppLocation';
import { useProfileSchoolContextType } from '../../hooks/useSegment';
import AccessDeniedPage from '../../pages/AccessDenied';
import { AnnualPlannerPage } from '../../pages/AnnualPlanner/AnnualPlannerPage';
import { AssessmentsPage } from '../../pages/Assessments/AssessmentsPage';
import { AttendancePage } from '../../pages/Attendance/AttendancePage';
import { CompaniesPage } from '../../pages/Companies/CompaniesPage';
import { ConductPage } from '../../pages/Conduct/ConductPage';
import { EventsPage } from '../../pages/Events/EventsPage';
import GroupsPage from '../../pages/Groups';
import MessagesPage from '../../pages/Messages';
import ParentsPage from '../../pages/Parents';
import { PayableFeesPage } from '../../pages/PayableFees/PayableFeesPage';
import ReportsPage from '../../pages/Reports';
import { SchoolPage } from '../../pages/School/SchoolPage';
import { SignUpsPage } from '../../pages/SignUps/SignUpsPage';
import StaffPage from '../../pages/Staff';
import StudentsPage from '../../pages/Students';
import UserRolesPage from '../../pages/UserRoles';
import { ROUTER_BASE_PATHS, RouterContext, RouterContextProps } from './RouterContext';

export const WithRouter: FC<PropsWithChildren> = ({ children }) => {
  const location = useAppLocation();
  const profileSchoolContextType = useProfileSchoolContextType();
  const { permissions } = useAuth();

  const lastBasePath = useRef<ROUTER_BASE_PATHS | undefined>(undefined);

  const backgroundPages = useMemo<RouterContextProps['backgroundPages']>(() => {
    const students = allowRenderStudents(permissions) ? <StudentsPage /> : <AccessDeniedPage />;
    const parents = allowRenderParents(permissions) ? <ParentsPage /> : <AccessDeniedPage />;
    const staff = allowRenderStaff(permissions) ? <StaffPage /> : <AccessDeniedPage />;
    const companies = allowRenderCompanies(permissions) ? <CompaniesPage /> : <AccessDeniedPage />;
    const groups = allowRenderGroups(permissions) ? <GroupsPage /> : <AccessDeniedPage />;
    const assessments = allowRenderAssessments(permissions) ? (
      <AssessmentsPage />
    ) : (
      <AccessDeniedPage />
    );
    const messages = allowRenderMessages(permissions) ? <MessagesPage /> : <AccessDeniedPage />;
    const reports = allowRenderReports(permissions) ? <ReportsPage /> : <AccessDeniedPage />;
    const userroles = allowRenderUserRoles(permissions) ? <UserRolesPage /> : <AccessDeniedPage />;
    const attendance = allowRenderAttendance(permissions) ? (
      <AttendancePage />
    ) : (
      <AccessDeniedPage />
    );
    const conduct = allowRenderConduct(permissions) ? <ConductPage /> : <AccessDeniedPage />;
    const events = allowRenderEvents(permissions) ? <EventsPage /> : <AccessDeniedPage />;
    const signups = allowRenderSignUps(permissions) ? <SignUpsPage /> : <AccessDeniedPage />;
    const payablefees = allowRenderPayableFees(permissions) ? (
      <PayableFeesPage />
    ) : (
      <AccessDeniedPage />
    );
    const planner = allowRenderPlanner(permissions) ? <AnnualPlannerPage /> : <AccessDeniedPage />;
    const settings = <SchoolPage />;

    return {
      adults: profileSchoolContextType === SchoolUserRole.Staff ? staff : parents,
      children: students,
      applications: students,
      students,
      parents,
      staff,
      companies,
      groups,
      assessments,
      messages,
      reports,
      userroles,
      attendance,
      conduct,
      events,
      signups,
      settings,
      planner,
      payablefees,
    };
  }, [permissions, profileSchoolContextType]);

  const getBackground = useCallback(
    (l: AppLocation = location) => {
      const basePath = l.pathname.replace(/^[/\\]([^/\\]+).*/, '$1') as ROUTER_BASE_PATHS;
      const isBasePath = `/${basePath}` === l.pathname;

      const actualPath = isBasePath ? basePath : lastBasePath.current ?? basePath;

      const element = backgroundPages[actualPath] ?? null;
      const path = backgroundPages[actualPath] ? `/${actualPath}` : '/';

      lastBasePath.current = actualPath;

      return { element, path };
    },
    [backgroundPages, location],
  );

  const setBackground = useCallback((path: ROUTER_BASE_PATHS) => {
    lastBasePath.current = path;
  }, []);

  return (
    <RouterContext.Provider
      value={{
        backgroundPages,
        getBackground,
        setBackground,
      }}
    >
      {children}
    </RouterContext.Provider>
  );
};
