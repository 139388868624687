import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.37737 7.17422C6.34091 7.09089 6.32268 7.00234 6.32268 6.90859V6.60781C6.32268 6.51146 6.34091 6.42161 6.37737 6.33828C6.41643 6.25495 6.47242 6.18854 6.54534 6.13906C6.61825 6.08698 6.7107 6.06094 6.82268 6.06094C6.93466 6.06094 7.02841 6.08698 7.10393 6.13906C7.17945 6.18854 7.23544 6.25495 7.2719 6.33828C7.30836 6.42161 7.32659 6.51146 7.32659 6.60781V6.90859C7.32659 7.00234 7.30836 7.09089 7.2719 7.17422C7.23544 7.25495 7.18075 7.32135 7.10784 7.37344C7.03492 7.42292 6.94247 7.44766 6.83049 7.44766C6.71591 7.44766 6.62086 7.42292 6.54534 7.37344C6.47242 7.32135 6.41643 7.25495 6.37737 7.17422Z"
      fill="#24275B"
    />
    <path
      d="M9.10784 10.3773C9.06617 10.294 9.04534 10.2042 9.04534 10.1078V9.80703C9.04534 9.6638 9.087 9.5375 9.17034 9.42812C9.25367 9.31875 9.37997 9.26406 9.54924 9.26406C9.71851 9.26406 9.84482 9.31875 9.92815 9.42812C10.0115 9.5375 10.0532 9.6638 10.0532 9.80703V10.1078C10.0532 10.2042 10.0375 10.294 10.0063 10.3773C9.97763 10.4607 9.92685 10.5284 9.85393 10.5805C9.78362 10.6299 9.68466 10.6547 9.55706 10.6547C9.45289 10.6547 9.36174 10.6299 9.28362 10.5805C9.2081 10.5284 9.1495 10.4607 9.10784 10.3773Z"
      fill="#24275B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6 4.80156H11.2C10.7582 4.80156 10.4 4.44339 10.4 4.00156V1.60156H4.00002C3.11637 1.60156 2.40002 2.31791 2.40002 3.20156V12.8016C2.40002 13.6852 3.11637 14.4016 4.00002 14.4016H12C12.8837 14.4016 13.6 13.6852 13.6 12.8016V4.80156ZM5.74065 6.01797C5.6469 6.19505 5.60002 6.39167 5.60002 6.60781V6.90859C5.60002 7.11953 5.6469 7.31354 5.74065 7.49062C5.8344 7.66771 5.97242 7.80964 6.15471 7.91641C6.33961 8.02318 6.56487 8.07656 6.83049 8.07656C7.09612 8.07656 7.31877 8.02318 7.49846 7.91641C7.68075 7.80964 7.81747 7.66771 7.90862 7.49062C8.00237 7.31354 8.04924 7.11953 8.04924 6.90859V6.60781C8.04924 6.39167 8.00237 6.19505 7.90862 6.01797C7.81747 5.84089 7.68075 5.69896 7.49846 5.59219C7.31617 5.48542 7.09091 5.43203 6.82268 5.43203C6.55966 5.43203 6.337 5.48542 6.15471 5.59219C5.97242 5.69896 5.8344 5.84089 5.74065 6.01797ZM8.46331 9.22109C8.36956 9.39818 8.32268 9.59349 8.32268 9.80703V10.1078C8.32268 10.3214 8.36956 10.5167 8.46331 10.6937C8.55966 10.8708 8.69898 11.0128 8.88128 11.1195C9.06617 11.2263 9.29143 11.2797 9.55706 11.2797C9.82268 11.2797 10.0453 11.2263 10.225 11.1195C10.4073 11.0128 10.544 10.8708 10.6352 10.6937C10.7289 10.5167 10.7758 10.3214 10.7758 10.1078V9.80703C10.7758 9.59349 10.7289 9.39818 10.6352 9.22109C10.544 9.04401 10.4073 8.90208 10.225 8.79531C10.0427 8.68854 9.81747 8.63516 9.54924 8.63516C9.28622 8.63516 9.06357 8.68854 8.88128 8.79531C8.69898 8.90208 8.55966 9.04401 8.46331 9.22109ZM6.51018 10.4828L7.04143 10.7758L9.81878 6.33047L9.28753 6.0375L6.51018 10.4828Z"
      fill="#24275B"
    />
    <path
      d="M13.6 3.20156C13.6 2.31791 12.8837 1.60156 12 1.60156H11.2V4.00156H13.6V3.20156Z"
      fill="#24275B"
    />
  </svg>
);
