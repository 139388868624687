import { Stack } from '@mui/material';
import { PaymentFrequencyType } from '@schooly/api';
import { FC, PropsWithChildren, useCallback } from 'react';

import { ExpandedSelect } from '../ExpandedSelect';
import { PAYMENT_FREQUENCY_TYPES } from './FrequencySelectMultiple';
import { FrequencyTagSelect, FrequencyTagSelectProps } from './FrequencyTagSelect';

type FrequencyExpandedSelectProps = PropsWithChildren<{
  selectedTypes: PaymentFrequencyType[];
  onSelectType: (v: PaymentFrequencyType) => void;
  onClear: () => void;
  onClose: () => void;
}>;
export const FrequencyExpandedSelect: FC<FrequencyExpandedSelectProps> = ({
  selectedTypes,
  onSelectType,
  onClose,
  onClear,
}) => {
  const renderContent = useCallback(() => {
    return (
      <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
        {renderFrequencyTags({
          types: PAYMENT_FREQUENCY_TYPES,
          tagProps: (type) => ({
            variant: selectedTypes.includes(type) ? 'filled' : undefined,
          }),
          onClick: onSelectType,
        })}
      </Stack>
    );
  }, [onSelectType, selectedTypes]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedTypes.length > 0}
      renderContent={renderContent}
      onClose={onClose}
      onClear={onClear}
    >
      {renderFrequencyTags({
        types: selectedTypes,
        tagProps: { sx: { maxWidth: 200 }, size: 'small' },
        onDelete: onSelectType,
      })}
    </ExpandedSelect>
  );
};

type RenderFrequencyTagsParams = {
  types: PaymentFrequencyType[];
  onDelete?: (v: PaymentFrequencyType) => void;
  onClick?: (v: PaymentFrequencyType) => void;
  tagProps?:
    | Omit<FrequencyTagSelectProps, 'type'>
    | ((v: PaymentFrequencyType) => Omit<FrequencyTagSelectProps, 'type'>);
};

export const renderFrequencyTags = ({
  types,
  onDelete,
  onClick,
  tagProps,
}: RenderFrequencyTagsParams) =>
  types.map((v) => (
    <FrequencyTagSelect
      key={v}
      type={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));
