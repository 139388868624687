import { joiResolver } from '@hookform/resolvers';
import { Backdrop } from '@mui/material';
import { closeUserAccount as closeUserAccountReq } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { useUserContext } from '@schooly/components/authentication';
import { ConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { CloseIcon, DeleteFilledIcon, Loading } from '@schooly/style';
import { Auth } from 'aws-amplify';
import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import useFlag from '../../../hooks/useFlag';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import { $UIKitPrefix } from '../../../styles/variables';
import { PasswordInput } from '../../ui/Input';
import { Button } from '../../uikit/Button/Button';
import { CloseAccountFormData, schema } from './schema';

import './CloseAccountModal.scss';

const $class = `${$UIKitPrefix}CloseAccountForm`;

interface CloseAccountFormProps {
  onClose: VoidFunction;
}

export const CloseAccountForm: React.FC<CloseAccountFormProps> = ({ onClose }) => {
  const { user, logout } = useUserContext();

  const { showError } = useNotifications();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();

  const [closeUserAccount, isAccountClosing, error] = useRequestWithProgress(
    closeUserAccountReq,
    undefined,
    true,
  );

  const handleConfirmationModalClose = useCallback(async () => {
    if (error) {
      hideConfirmationDialog();
    } else {
      logout();
    }
  }, [logout, error, hideConfirmationDialog]);

  const form = useForm<CloseAccountFormData>({
    resolver: joiResolver(schema),
    mode: 'all',
  });

  const onSubmit = useCallback(
    async (data: CloseAccountFormData) => {
      setIsLoading(true);
      try {
        await Auth.signIn(user?.account_email!, data.password);
        await closeUserAccount(user?.user_id!);
        showConfirmationDialog();
      } catch (err) {
        showError(err as ApiError);
      } finally {
        setIsLoading(false);
      }
    },
    [closeUserAccount, user?.account_email, user?.user_id, showConfirmationDialog, showError],
  );

  const imageName = useMemo(() => (error ? 'error' : 'success'), [error]);
  const confirmationTitle = useMemo(
    () => error?.message || error?.reason || formatMessage({ id: 'login-SuccessfullyClosed' }),
    [error?.message, error?.reason, formatMessage],
  );

  if (isLoading || isAccountClosing) {
    return (
      <>
        <Backdrop open invisible />
        <Loading />
      </>
    );
  }

  return (
    <>
      <FormProvider {...form}>
        <PasswordInput name="password" labelTextId="login-Password" required />
        <Button
          onClick={form.handleSubmit(onSubmit)}
          className={`${$class}__button`}
          startIcon={<DeleteFilledIcon />}
        >
          <FormattedMessage id="login-CloseMyAccount" />
        </Button>
      </FormProvider>
      <ConfirmationDialog
        className={`${$class}__confirmation-dialog`}
        isOpen={isConfirmationDialogOpen}
        message={confirmationTitle}
        cancelTextId={error ? 'action-GoBack' : 'action-Close'}
        onlyCancelButton
        cancelButtonStartIcon={error ? null : <CloseIcon />}
        onCancel={handleConfirmationModalClose}
      >
        <picture>
          <source srcSet={`/images/${imageName}.png, /images/${imageName}@2x.png 2x`} />
          <img
            className={`${$class}__image`}
            src={`/images/${imageName}.png`}
            alt={confirmationTitle}
          />
        </picture>
      </ConfirmationDialog>
    </>
  );
};
