import { Button, FormControlLabel, IconButton, Stack, Switch, TextField } from '@mui/material';
import { GET_GROUP_SUBJECTS_QUERY } from '@schooly/api';
import { updateSubjects } from '@schooly/api';
import { ApiError, GroupSubject } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { CheckIcon, CrossIcon, DragIcon, PlusIcon, RollBackIcon, Spin } from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { DndListWrapper } from '../../../../components/uikit/Dnd/dnd.styled';
import { ControlTextField } from '../../../../components/uikit-components/FormTextField/ControlTextField';
import {
  ModalContent,
  ModalFooter,
  ModalMain,
  ModalSmall,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../../context/router/useRouter';
import { SchoolSettingsLayout } from '../../SchoolSettingsLayout';
import { SchoolGeneralRowAction } from '../SchoolGeneralRowAction';

export interface SchoolGeneralSubjectsForm {
  subjects?: (Partial<GroupSubject> & {
    originId?: GroupSubject['id'];
  })[];
}

const getEmptySubject = () => ({
  id: '',
  originId: '',
  name: '',
  attendance_default: false,
  order_for_assessments: 0,
  archived: false,
  can_be_archived: true,
});

export const SchoolGeneralSubjectsModal: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { goBack } = useRouter();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId = '' } = useAuth();
  const { subjects } = useSubjects({ schoolId }, { refetchOnMount: 'always', enabled: !!schoolId });
  const [showArchived, setShowArchived] = useState(false);
  const autoSwitchedArchivedOn = useRef(false);

  const queryClient = useQueryClient();
  const form = useForm<SchoolGeneralSubjectsForm>({
    defaultValues: {
      subjects: subjects.length
        ? subjects.map((s) => ({ ...s, originId: s.id }))
        : [getEmptySubject()],
    },
  });
  const { fields, append, remove, replace, move, update } = useFieldArray({
    control: form.control,
    name: 'subjects',
  });
  const [saving, setSaving] = useState(false);

  // (TR-4613): Have to spread active & archived fields to two different lists and
  // render/manage them separately.
  // Instead of `activeSubjects` and `archivedSubjects` from `useSchool()` (which have already been
  // saved on BE side), these are temporary lists, which live only in the current UI, while the
  // Edit modal is open.
  const [activeFields, archivedFields] = useMemo(
    () =>
      fields.reduce<Array<Array<{ index: number; field: typeof fields[number] }>>>(
        (prev, field, index) => {
          prev[field.archived ? 1 : 0].push({ index, field });
          return prev;
        },
        [[], []],
      ),
    [fields],
  );

  const prevActiveFields = usePrevious(activeFields);

  const firstActiveField = form.getValues(`subjects.${activeFields[0]?.index}`);

  const shouldFocusLastItem = Boolean(
    activeFields.length === 1
      ? !firstActiveField.name
      : prevActiveFields && activeFields && activeFields.length - prevActiveFields.length === 1,
  );

  const handleClose = useCallback(async () => {
    if (saving) {
      return;
    }

    if (
      form.formState.isDirty &&
      !(await getConfirmation({ textId: 'school-edit-CloseUnsavedConfirmation' }))
    ) {
      return;
    }

    goBack();
  }, [goBack, saving, form.formState.isDirty, getConfirmation]);

  const handleAddSubject = useCallback(() => {
    append(getEmptySubject());
  }, [append]);

  const handleDeleteSubject = useCallback(
    (index: number) => {
      if (fields.length > 1) {
        remove(index);
      } else {
        replace([getEmptySubject()]);
      }
    },
    [fields, remove, replace],
  );

  const handleArchiveSubjects = useCallback(
    async (index: number) => {
      const field = form.getValues(`subjects.${index}`);

      const isConfirmed = await getConfirmation({
        textId: 'school-tabs-Subjects-ArchiveConfirmation',
        textValues: { name: field?.name ?? '' },
        confirmTextId: 'yes',
        cancelTextId: 'no',
      });

      if (isConfirmed) {
        update(index, { ...field, archived: true });

        // TR-4613: should switch on `Show archived` first time if no archived fields were before
        if (!archivedFields.length && !autoSwitchedArchivedOn.current) {
          setShowArchived(true);
          autoSwitchedArchivedOn.current = true;
        }
      }
    },
    [archivedFields.length, form, getConfirmation, update],
  );

  const handleRestoreSubjects = useCallback(
    (index: number) => async () => {
      const field = form.getValues(`subjects.${index}`);
      // can not just use `update` as need to move to the end
      remove(index);
      append({ ...field, archived: false });
    },
    [append, form, remove],
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move],
  );

  const handleSubmit = useCallback<SubmitHandler<SchoolGeneralSubjectsForm>>(
    async (data) => {
      if (!schoolId || !data.subjects) {
        return;
      }

      setSaving(true);

      try {
        await updateSubjects(
          schoolId,
          data.subjects.map((subject, index) => ({
            subject_id: subject.id || undefined, // BE do not accept empty string here
            name: subject.name ?? '',
            order_for_assessments: index + 1,
            archive: subject.archived,
          })),
        );

        queryClient.invalidateQueries([GET_GROUP_SUBJECTS_QUERY]);
        goBack();
      } catch (err) {
        console.error(err);
        showError(err as ApiError);
      }

      setSaving(false);
    },
    [goBack, queryClient, schoolId, showError],
  );

  return (
    <SchoolSettingsLayout>
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader title={$t({ id: 'school-tabs-Subjects' })} active>
              {archivedFields.length > 0 && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={showArchived}
                      onChange={(event, value) => setShowArchived(value)}
                    />
                  }
                  label={$t({ id: 'action-ShowArchived' })}
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      whiteSpace: 'nowrap',
                    },
                  }}
                />
              )}
              {!saving && (
                <IconButton onClick={handleClose}>
                  <CrossIcon />
                </IconButton>
              )}
            </ModalHeader>
            <ModalMain>
              <ModalContent flat active>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="SubjectDroppable">
                    {(provided) => (
                      <DndListWrapper
                        {...provided.droppableProps}
                        sx={{ overflowX: 'hidden' }}
                        ref={provided.innerRef}
                        alignItems="flex-start"
                        gap={2}
                        p={2.5}
                      >
                        {activeFields?.map(({ field, index }, arrayIndex) => (
                          <Draggable key={field.id} draggableId={field.id} index={index}>
                            {(provided) => (
                              <Stack
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                direction="row"
                                alignItems="center"
                                gap={1}
                                sx={{
                                  width: '100%',
                                  bgcolor: 'background.paper',
                                }}
                                style={provided.draggableProps.style}
                              >
                                {activeFields.length > 1 && (
                                  <IconButton inverse {...provided.dragHandleProps}>
                                    <DragIcon />
                                  </IconButton>
                                )}
                                <ControlTextField
                                  name={`subjects.${index}.name`}
                                  control={form.control}
                                  rules={{
                                    required: true,
                                    validate: (value, formValues) => {
                                      // Check for name uniqueness
                                      for (let i = 0; i < (formValues.subjects?.length ?? 0); i++) {
                                        if (i === index) {
                                          continue;
                                        }

                                        const field = formValues.subjects![i];

                                        if (
                                          field.name?.trim().toLowerCase() ===
                                          `${value ?? ''}`.trim().toLowerCase()
                                        ) {
                                          return $t({
                                            id: field.archived
                                              ? 'school-tabs-Subjects-ArchivedExists'
                                              : 'school-tabs-Subjects-ActiveExists',
                                          });
                                        }
                                      }

                                      return true;
                                    },
                                  }}
                                  label={$t({ id: 'school-tabs-Subjects-SubjectName' })}
                                  autoFocus={
                                    shouldFocusLastItem && arrayIndex === activeFields.length - 1
                                  }
                                  fullWidth
                                  canClear
                                />

                                {activeFields.length ? (
                                  <SchoolGeneralRowAction
                                    id={field.originId}
                                    index={index}
                                    isLocked={field.attendance_default}
                                    lockMessage={$t({
                                      id: 'school-tabs-Subjects-Tooltip-DefaultFilter',
                                    })}
                                    onArchive={handleArchiveSubjects}
                                    onDelete={handleDeleteSubject}
                                  />
                                ) : null}
                              </Stack>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}

                        <Button
                          variant="outlined"
                          startIcon={<PlusIcon />}
                          onClick={handleAddSubject}
                        >
                          <FormattedMessage id="school-tabs-Subjects-AddSubject" />
                        </Button>

                        {showArchived &&
                          archivedFields.map(({ field, index }) => (
                            <Stack
                              key={field.id}
                              direction="row"
                              alignItems="center"
                              gap={1}
                              pl={3.5}
                              width="100%"
                            >
                              <TextField
                                value={field.name}
                                fullWidth
                                disabled
                                label={$t({ id: 'school-tabs-Subjects-SubjectName' })}
                              />

                              <IconButton onClick={handleRestoreSubjects(index)}>
                                <RollBackIcon />
                              </IconButton>
                            </Stack>
                          ))}
                      </DndListWrapper>
                    )}
                  </Droppable>
                </DragDropContext>
              </ModalContent>
            </ModalMain>
            <ModalFooter sx={{ justifyContent: 'space-between' }} active>
              <Button variant="outlined" disabled={saving} onClick={handleClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button type="submit" disabled={saving} endIcon={saving ? <Spin /> : <CheckIcon />}>
                <FormattedMessage id="action-Save" />
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    </SchoolSettingsLayout>
  );
};
