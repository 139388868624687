import {
  Backdrop,
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import {
  ANNUAL_PLAN_FILTER_KEYS,
  ASSESSMENTS_FILTER_KEYS,
  ATTENDANCE_REGISTERS_FILTER_KEYS,
  CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
  DefaultSchoolYear,
  EVENTS_FILTER_KEYS,
  FilterKeys,
  FilterSection,
  getTypedObjectKeys,
  GROUPS_QUERY_FILTER_KEYS,
  MESSAGES_FILTER_KEYS,
  PARENT_QUERY_FILTER_KEYS,
  PAYABLE_FEES_QUERY_FILTER_KEYS,
  REPORTS_QUERY_FILTER_KEYS,
  SavedFilter,
  SchoolRelation,
  SIGN_UPS_FILTER_KEYS,
  STAFF_QUERY_FILTER_KEYS,
  STUDENTS_QUERY_FILTER_KEYS,
  useGetFiltersQuery,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { PROPERTIES_TEXT_IDS, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import {
  CheckIcon,
  EditIcon,
  MarkIcon,
  TagSelect,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { isToday } from 'date-fns';
import {
  forwardRef,
  MouseEventHandler,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { renderAssessmentStatusTags } from '../AssessmentStatus/AssessmentStatusExpandedSelect';
import { CheckOverflow } from '../CheckOverflow';
import { renderConductTypeTags } from '../ConductType/ConductTypeExpandedSelect';
import { renderConductVisibilityTags } from '../ConductVisibility/ConductVisibilityExpandedSelect';
import { DateRangeTagSelect } from '../Date/DateRangeTagSelect';
import { DateTagSelect } from '../Date/DateTagSelect';
import { renderDayPastDueTags } from '../DayPastDue/DayPastDueExpandedSelect';
import { renderFeeStatusTags } from '../FeeStatus/FeeStatusExpandedSelect';
import { renderFrequencyTags } from '../Frequency/FrequencyExpandedSelect';
import { renderGenderTags } from '../Gender/GenderExpandedSelect';
import { renderGroupTags } from '../Group/GroupExpandedSelect';
import { renderInviteStatusTags } from '../InviteStatus/InviteStatusExpandedSelect';
import { renderMessageStatusTags } from '../MessageStatus/MessageStatusExpandedSelect';
import { renderNationalityTags } from '../Nationality/NationalityExpandedSelect';
import { renderProductTags } from '../Product/ProductExpandedSelect';
import { renderPropertyGroupTags } from '../PropertyType/AgeGroupExpandedSelect';
import { renderPropertyTypeTags } from '../PropertyType/PropertyTypeExpandedSelect';
import { renderReportTags } from '../Report/ReportExpandedSelect';
import { renderReportStatusTags } from '../ReportStatus/ReportStatusExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { renderSubjectTags } from '../Subject/SubjectExpandedSelect';
import { renderUserTags } from '../User/UserExpandedSelect';
import { renderUserRoleTags } from '../UserRole/UserRoleExpandedSelect';
import { getSelectedItemsWithGrouping, SelectedItem, SelectedItemWithGrouping } from '../utils';
import { PersonalFilterModal } from './PersonalFilterModal';

// Resolves problems related to the difference in filter data between BE and FE
export type FilterAdapter = (filter: SavedFilter['filter']) => {
  incoming: SavedFilter['filter'];
  normalized: SavedFilter['filter'];
};

type PersonalFiltersDropdownProps = {
  currentUser?: SchoolRelation;
  schoolId: string;
  relationId: string;
  section: FilterSection;
  filters: SavedFilter['filter'];
  onSetFilters: (v: SavedFilter['filter']) => void;
  onOpenFilters: () => void;
  filterAdapter?: FilterAdapter;
  defaultSchoolYear?: DefaultSchoolYear;
  accessMap?: Partial<Record<keyof SavedFilter['filter'], boolean>>;
  getOptionLabelId?: (o: FilterKeys) => string;
  onSaveFilter: () => void;
} & Partial<TooltipProps>;

export type PersonalFiltersDropdown = {
  saveFilter: () => void;
};

const SECTION_FILTER_KEYS: { [k in FilterSection]: readonly FilterKeys[] } = {
  [FilterSection.Student]: STUDENTS_QUERY_FILTER_KEYS,
  [FilterSection.Staff]: STAFF_QUERY_FILTER_KEYS,
  [FilterSection.Parent]: PARENT_QUERY_FILTER_KEYS,
  [FilterSection.Group]: GROUPS_QUERY_FILTER_KEYS,
  [FilterSection.Assessment]: ASSESSMENTS_FILTER_KEYS,
  [FilterSection.Message]: MESSAGES_FILTER_KEYS,
  [FilterSection.Report]: REPORTS_QUERY_FILTER_KEYS,
  [FilterSection.Attendance]: ATTENDANCE_REGISTERS_FILTER_KEYS,
  [FilterSection.Conduct]: CONDUCT_ENTRIES_QUERY_FILTER_KEYS,
  [FilterSection.Events]: EVENTS_FILTER_KEYS,
  [FilterSection.SignUps]: SIGN_UPS_FILTER_KEYS,
  [FilterSection.AnnualPlanner]: ANNUAL_PLAN_FILTER_KEYS,
  [FilterSection.PayableFees]: PAYABLE_FEES_QUERY_FILTER_KEYS,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonalFiltersDropdown = forwardRef<
  PersonalFiltersDropdown,
  PersonalFiltersDropdownProps
>(
  (
    {
      section,
      filters,
      onSetFilters,
      onOpenFilters,
      currentUser,
      schoolId,
      relationId,
      filterAdapter,
      defaultSchoolYear,
      getOptionLabelId,
      onSaveFilter,
      accessMap,
      ...tooltipProps
    },
    ref,
  ) => {
    const { $t } = useIntl();
    const { showError } = useNotifications();
    const [opened, open, close] = useFlag();
    const [filterToEdit, setFilterIdToEdit] =
      useState<(Omit<SavedFilter, 'id'> & { id?: string }) | null>(null);

    const filterKeys = useMemo(
      () =>
        SECTION_FILTER_KEYS[section].filter((key) =>
          accessMap && key in accessMap ? accessMap[key] : true,
        ),
      [accessMap, section],
    );

    useImperativeHandle(
      ref,
      () => ({
        saveFilter: () =>
          setFilterIdToEdit({
            name: '',
            section,
            is_default: false,
            filter: getTypedObjectKeys(filters).reduce((acc, key) => {
              const canAccess = accessMap && key in accessMap ? accessMap[key] : true;
              return canAccess ? { ...acc, [key]: filters[key] } : acc;
            }, {}),
          }),
      }),
      [filters, accessMap, section],
    );

    const { data, isLoading } = useGetFiltersQuery(
      { schoolId, relationId, section },
      { refetchOnMount: 'always', onError: showError },
    );

    const handleApplyFilter = useCallback(
      (v: SavedFilter) => {
        onSetFilters(filterAdapter ? filterAdapter(v.filter).normalized : v.filter);
        close();
      },
      [onSetFilters, filterAdapter, close],
    );

    const handleSaveFilter: MouseEventHandler = useCallback(
      (e) => {
        e.stopPropagation();
        onSaveFilter();
        close();
      },
      [close, onSaveFilter],
    );

    const appliedFilter = useMemo(() => {
      if (!data) return;

      return data.find((v) => {
        const savedFilter = filterAdapter
          ? {
              ...v,
              filter: filterAdapter(v.filter).normalized,
            }
          : v;

        return (
          !filterKeys.some((key) => {
            const currentFiltersForKey = [...(filters[key] || [])];
            const filterFiltersForKey = [...(savedFilter.filter[key] || [])];

            if (
              key === FilterKeys.Date &&
              filterFiltersForKey.length === 0 &&
              currentFiltersForKey.length === 1 &&
              isToday(new Date(currentFiltersForKey[0]! as string | number))
            )
              return false;

            return currentFiltersForKey.sort().join('') !== filterFiltersForKey.sort().join('');
          }) &&
          filters.arrange_by === v.filter.arrange_by &&
          filters.group_by === v.filter.group_by
        );
      });
    }, [data, filterAdapter, filterKeys, filters]);

    const handleEditFilter = useCallback(
      (filter: SavedFilter) => {
        setFilterIdToEdit(
          filterAdapter ? { ...filter, filter: filterAdapter(filter.filter).normalized } : filter,
        );
        close();
      },
      [close, filterAdapter],
    );

    const renderContent = useCallback(() => {
      const saveFilterButton = (
        <Button
          size="small"
          sx={(theme) => ({ '.svg-icon': { color: theme.palette.warning.main } })}
          startIcon={<MarkIcon />}
          variant="outlined"
          onClick={handleSaveFilter}
        >
          <FormattedMessage id="filter-save-button" />
        </Button>
      );

      if (isLoading)
        return (
          <Box p={2}>
            <SelectContentSkeleton />
          </Box>
        );

      if (!data?.length)
        return (
          <Stack py={6} gap={2} justifyContent="center" alignItems="center">
            <Typography variant="h3" align="center" maxWidth={200}>
              <FormattedMessage id="filter-NoFavorite" />
            </Typography>
            {saveFilterButton}
          </Stack>
        );

      const { defaultFilter, rest } = data.reduce<{
        defaultFilter?: SavedFilter;
        rest: SavedFilter[];
      }>(
        (acc, filter) => ({
          ...acc,
          ...(filter.is_default ? { defaultFilter: filter } : { rest: [...acc.rest, filter] }),
        }),
        { rest: [] },
      );

      return (
        <Stack p={2} gap={1}>
          {defaultFilter && (
            <FilterRow
              currentUser={currentUser}
              isApplied={defaultFilter.id === appliedFilter?.id}
              filterKeys={filterKeys}
              schoolId={schoolId}
              filter={defaultFilter}
              onEdit={handleEditFilter}
              onApply={handleApplyFilter}
            />
          )}
          {!!rest.length && (
            <>
              <Typography variant="h4">{$t({ id: 'filter-save-list-title' })}</Typography>
              <Stack gap={0.5}>
                {rest.map((filter) => (
                  <FilterRow
                    currentUser={currentUser}
                    isApplied={filter.id === appliedFilter?.id}
                    filterKeys={filterKeys}
                    schoolId={schoolId}
                    filter={filter}
                    key={filter.id}
                    onEdit={handleEditFilter}
                    onApply={handleApplyFilter}
                  />
                ))}
              </Stack>
            </>
          )}
          <Divider sx={{ my: 1 }} />
          <Box>{saveFilterButton}</Box>
        </Stack>
      );
    }, [
      isLoading,
      data,
      handleSaveFilter,
      currentUser,
      appliedFilter,
      filterKeys,
      schoolId,
      handleEditFilter,
      handleApplyFilter,
      $t,
    ]);

    return (
      <>
        <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
        <ClickAwayListener onClickAway={close}>
          <div>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={close}
              open={opened}
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    width: 690,
                    maxWidth: 690,
                    borderRadius: theme.spacing(1),
                    border: `1px solid ${theme.palette.common.light3}`,
                    padding: 0,
                    overflow: 'hidden',
                    margin: `${theme.spacing(0.5, 0, 0)} !important`,
                  }),
                },
              }}
              TransitionComponent={Fade}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={renderContent()}
              {...tooltipProps}
            >
              <Stack
                sx={(theme) => ({
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: opened ? theme.palette.primary.main : undefined,
                  borderRadius: theme.spacing(1),
                  width: theme.spacing(3.75),
                  height: theme.spacing(3.75),
                })}
              >
                <IconButton
                  sx={(theme) => ({
                    color: opened
                      ? 'white'
                      : !!appliedFilter
                      ? theme.palette.warning.main
                      : undefined,
                  })}
                  inverse
                  onClick={open}
                >
                  <MarkIcon />
                </IconButton>
              </Stack>
            </Tooltip>
          </div>
        </ClickAwayListener>
        {filterToEdit && (
          <PersonalFilterModal
            currentUser={currentUser}
            filterKeys={filterKeys}
            onClose={() => setFilterIdToEdit(null)}
            relationId={relationId}
            schoolId={schoolId}
            section={section}
            filter={filterToEdit}
            onApply={handleApplyFilter}
            filterAdapter={filterAdapter}
            defaultSchoolYear={defaultSchoolYear}
            getOptionLabelId={getOptionLabelId}
          />
        )}
      </>
    );
  },
);

const filterTagProps = {
  size: 'small',
  sx: {
    maxWidth: '100px',
    backgroundColor: 'white',
  },
} as const;

type FilterRowProps<T> = {
  currentUser?: SchoolRelation;
  filterKeys: readonly T[];
  filter: SavedFilter;
  onEdit: (filter: SavedFilter) => void;
  onApply: (filter: SavedFilter) => void;
  schoolId: string;
  isApplied?: boolean;
};

const FilterRow = <T extends FilterKeys>({
  currentUser,
  filter: savedFilter,
  filterKeys,
  onApply,
  onEdit,
  schoolId,
  isApplied,
}: FilterRowProps<T>) => {
  const { $t } = useIntl();
  const { filter, name, is_default } = savedFilter;

  const {
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
    getAgeGroupsByLevelId,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups: SelectedItem[] = [];

    filter[FilterKeys.AgeGroup]?.forEach((id) => {
      const ageGroup = getAgeGroupById(id);
      if (ageGroup?.id) {
        selectedAgeGroups.push({
          id: ageGroup.id,
          groupId: ageGroup.level_id,
        });
      }
    });

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filter, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  const [hiddenTags, setHiddenTags] = useState<Set<number>>(new Set());
  const setTagHidden = useCallback((index: number, isHidden: boolean) => {
    setHiddenTags((tags) => {
      isHidden ? tags.add(index) : tags.delete(index);
      return new Set(tags);
    });
  }, []);

  const tags = useMemo(() => {
    const nodes: React.ReactNode[] = [];

    for (const key of filterKeys) {
      const value = filter[key];
      if (!value?.length) continue;

      switch (key) {
        case FilterKeys.Date: {
          const fromDate = filter[FilterKeys.Date]![0]!;
          const toDate = filter[FilterKeys.Date]![1]!;

          if (!toDate) {
            nodes.push(<DateTagSelect key="date" {...filterTagProps} date={fromDate} />);
            break;
          }
          nodes.push(
            <DateRangeTagSelect
              key="date"
              {...filterTagProps}
              sx={{ ...filterTagProps.sx, maxWidth: '100%' }}
              date={[fromDate, toDate]}
            />,
          );
          break;
        }
        case FilterKeys.Subject: {
          nodes.push(
            ...renderSubjectTags({
              ids: filter[FilterKeys.Subject] || [],
              tagProps: { schoolId, ...filterTagProps },
            }),
          );
          break;
        }
        case FilterKeys.AgeGroup:
          nodes.push(
            ...renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              getProperty: (i: SelectedItemWithGrouping) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i: SelectedItemWithGrouping) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                ...filterTagProps,
              },
            }),
          );
          break;

        case FilterKeys.House:
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.House] || [],
              tagProps: { userRole: SchoolUserRole.Student, schoolId, ...filterTagProps },
            }),
          );
          break;

        case FilterKeys.Status:
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.Status] || [],
              tagProps: { userRole: SchoolUserRole.Student, schoolId, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.StudentStatus: {
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.StudentStatus] || [],
              tagProps: { userRole: SchoolUserRole.Student, schoolId, ...filterTagProps },
            }),
          );
          break;
        }
        case FilterKeys.StudentAgeGroup:
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.StudentAgeGroup] || [],
              tagProps: { userRole: SchoolUserRole.Student, schoolId, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.StudentHouse:
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.StudentHouse] || [],
              tagProps: { userRole: SchoolUserRole.Student, schoolId, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.Department: {
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.Department] || [],
              tagProps: { userRole: SchoolUserRole.Staff, schoolId, ...filterTagProps },
            }),
          );
          break;
        }
        case FilterKeys.StaffStatus: {
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.StaffStatus] || [],
              tagProps: { userRole: SchoolUserRole.Staff, schoolId, ...filterTagProps },
            }),
          );
          break;
        }
        case FilterKeys.StaffHouse:
          nodes.push(
            ...renderPropertyTypeTags({
              ids: filter[FilterKeys.StaffHouse] || [],
              tagProps: { userRole: SchoolUserRole.Staff, schoolId, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.Gender:
          nodes.push(
            ...renderGenderTags({
              genders: filter[FilterKeys.Gender] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.AssessmentStatus:
          nodes.push(
            ...renderAssessmentStatusTags({
              statuses: filter[FilterKeys.AssessmentStatus] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.ConductStatus:
          nodes.push(
            ...renderConductVisibilityTags({
              visibilities: filter[FilterKeys.ConductStatus] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.ConductType:
          nodes.push(
            ...renderConductTypeTags({
              ids: filter[FilterKeys.ConductType] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.Creator:
          nodes.push(
            ...renderUserTags({
              ids: filter[FilterKeys.Creator] || [],
              tagProps: { schoolId, currentUser, userType: 'staff' as const, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.Group:
          nodes.push(
            ...renderGroupTags({
              ids: filter[FilterKeys.Group] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.InviteStatus:
          nodes.push(
            ...renderInviteStatusTags({
              statuses: filter[FilterKeys.InviteStatus] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.MessagesStatus:
          nodes.push(
            ...renderMessageStatusTags({
              statuses: filter[FilterKeys.MessagesStatus] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.Nationality:
          nodes.push(
            ...renderNationalityTags({
              nationalities: filter[FilterKeys.Nationality] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.RecipientType:
          nodes.push(
            ...renderUserRoleTags({
              roles: filter[FilterKeys.RecipientType] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.Report:
          nodes.push(
            ...renderReportTags({
              ids: filter[FilterKeys.Report] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.ReportStatus:
          nodes.push(
            ...renderReportStatusTags({
              statuses: filter[FilterKeys.ReportStatus] || [],
              tagProps: filterTagProps,
            }),
          );
          break;
        case FilterKeys.Staff:
          nodes.push(
            ...renderUserTags({
              ids: filter[FilterKeys.Staff] || [],
              tagProps: { schoolId, currentUser, userType: 'staff' as const, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.Student:
          nodes.push(
            ...renderUserTags({
              ids: filter[FilterKeys.Student] || [],
              tagProps: { schoolId, userType: 'student' as const, ...filterTagProps },
            }),
          );
          break;
        case FilterKeys.OnlyTutorGroups:
          if (!filter[FilterKeys.OnlyTutorGroups]?.length) break;

          nodes.push(
            <TagSelect
              key={FilterKeys.OnlyTutorGroups}
              {...filterTagProps}
              label={$t({
                id: PROPERTIES_TEXT_IDS[FilterKeys.OnlyTutorGroups],
              })}
            />,
          );
          break;
        case FilterKeys.Product:
          if (!filter[FilterKeys.Product]?.length) break;

          nodes.push(
            nodes.push(
              ...renderProductTags({
                ids: filter[FilterKeys.Product] || [],
                tagProps: filterTagProps,
              }),
            ),
          );
          break;
        case FilterKeys.Frequency:
          if (!filter[FilterKeys.Frequency]?.length) break;

          nodes.push(
            nodes.push(
              ...renderFrequencyTags({
                types: filter[FilterKeys.Frequency] || [],
                tagProps: filterTagProps,
              }),
            ),
          );
          break;
        case FilterKeys.DayPastDue:
          if (!filter[FilterKeys.DayPastDue]?.length) break;

          nodes.push(
            nodes.push(
              ...renderDayPastDueTags({
                types: filter[FilterKeys.DayPastDue] || [],
                tagProps: filterTagProps,
              }),
            ),
          );
          break;
        case FilterKeys.FeeStatus:
          if (!filter[FilterKeys.FeeStatus]?.length) break;

          nodes.push(
            nodes.push(
              ...renderFeeStatusTags({
                statuses: filter[FilterKeys.FeeStatus] || [],
                tagProps: filterTagProps,
              }),
            ),
          );
          break;
        default:
          break;
      }
    }

    if (filter.group_by) {
      nodes.push(
        <TagSelect
          key={filter.group_by}
          {...filterTagProps}
          label={`${$t({ id: 'filter-GroupBy' })}: ${$t({
            id: PROPERTIES_TEXT_IDS[filter.group_by],
          })}`}
        />,
      );
    }

    if (filter.arrange_by) {
      nodes.push(
        <TagSelect
          key={filter.arrange_by}
          {...filterTagProps}
          label={`${$t({ id: 'filter-ArrangeBy' })}: ${$t({
            id: PROPERTIES_TEXT_IDS[filter.arrange_by],
          })}`}
        />,
      );
    }

    return nodes;
  }, [
    $t,
    currentUser,
    filter,
    filterKeys,
    getAgeGroupById,
    getAgeGroupsByLevelId,
    getSchoolLevelById,
    schoolId,
    selectedItemsForAgeGroups,
  ]);

  return (
    <Stack
      sx={(theme) => ({
        flexDirection: 'row',
        gap: 1,
        alignItems: 'center',
        borderRadius: theme.spacing(0.5),
        minHeight: theme.spacing(4),
        transition: 'all .2s',

        '&:hover': {
          '.name': {
            maxWidth: isApplied ? 360 : 320,
            '.MuiTypography-h3': {
              color: 'primary.main',
            },
          },
          '.mark-icon': {
            color: !isApplied ? theme.palette.text.primary : undefined,
          },
          '.edit-icon:not(:hover)': {
            color: theme.palette.text.primary,
          },
          backgroundColor: theme.palette.background.default,
        },
        '&:not(:hover)': {
          '.name': {
            maxWidth: 600,
          },
          '.edit-icon, .apply-button, .tags': {
            position: 'absolute',
            visibility: 'hidden',
          },
        },
      })}
    >
      <Stack flexDirection="row" flex={1} pl={0.5} gap={1} justifyContent="space-between">
        <Stack flexDirection="row" className="name" alignItems="center" gap={1}>
          {is_default && (
            <Icon
              className="mark-icon"
              sx={(theme) => ({
                color: isApplied ? theme.palette.warning.main : theme.palette.common.grey,
              })}
            >
              <MarkIcon />
            </Icon>
          )}
          <TypographyWithOverflowHint
            variant="h3"
            noWrap
            color={isApplied ? 'primary.main' : 'text.primary'}
          >
            {name}
          </TypographyWithOverflowHint>
        </Stack>
        <Stack flexDirection="row" alignItems="center" className="tags">
          {tags.map((tagNode, i) =>
            i === 0 ? (
              tagNode
            ) : (
              <CheckOverflow index={i} onHideTag={setTagHidden} key={`_${i}`}>
                {tagNode}
              </CheckOverflow>
            ),
          )}{' '}
          {!!hiddenTags.size && (
            <TagSelect
              size="small"
              sx={{ backgroundColor: 'white', ml: 0.5 }}
              label={`+${hiddenTags.size}`}
            />
          )}
        </Stack>
      </Stack>
      <IconButton inverse className="edit-icon" onClick={() => onEdit(savedFilter)}>
        <EditIcon />
      </IconButton>

      {isApplied ? (
        <Icon sx={{ mr: 0.5 }}>
          <CheckIcon />
        </Icon>
      ) : (
        <Button size="small" className="apply-button" onClick={() => onApply(savedFilter)}>
          {$t({ id: 'filter-Apply' })}
        </Button>
      )}
    </Stack>
  );
};
