import { Icon, Skeleton, Stack } from '@mui/material';
import {
  FilterKeys,
  FilterSection,
  GetPayableFeesArrangeBy,
  GetPayableFeesQueryFilters,
  PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
  PAYABLE_FEES_QUERY_FILTER_KEYS,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useChartOpenFromSearchParams, WithCharts } from '@schooly/components/charts';
import {
  ArrangedByCollapsableSectionSkeleton,
  PageHeader,
  pickOnlyParamsFromFilterKeys,
} from '@schooly/components/filters';
import { MarkIcon } from '@schooly/style';
import { FC, useMemo } from 'react';

import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { PayableFeesContent } from './PayableFeesContent';

export const PayableFeesPage: FC = () => {
  const { schoolId = '', relationId = '' } = useAuth();
  const { data: filters, isLoading } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.PayableFees },
    { enabled: !!schoolId && !!relationId },
  );

  const initialFiltersAndArrangeBy = useMemo(() => {
    const initialFilters: GetPayableFeesQueryFilters = {};
    let initialArrangeBy: GetPayableFeesArrangeBy = FilterKeys.Month;

    if (isLoading) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      for (const key of PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS) {
        if (key === defaultFilter.filter.arrange_by) initialArrangeBy = key;
      }

      return {
        initialFilters: pickOnlyParamsFromFilterKeys(
          PAYABLE_FEES_QUERY_FILTER_KEYS,
          defaultFilter.filter,
        ),
        initialArrangeBy,
      };
    }

    return { initialFilters, initialArrangeBy };
  }, [filters, isLoading]);

  const isChartsOpen = useChartOpenFromSearchParams();

  const renderContent = () => {
    if (!initialFiltersAndArrangeBy) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-payableFees" />
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <div>
            <ArrangedByCollapsableSectionSkeleton />
          </div>
        </Stack>
      );
    }

    return (
      <WithCharts schoolId={schoolId ?? ''} isOpen={isChartsOpen}>
        <PayableFeesContent {...initialFiltersAndArrangeBy} />
      </WithCharts>
    );
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
