import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.4 2C11.9346 2 14.8 4.86538 14.8 8.4C14.8 11.9346 11.9346 14.8 8.4 14.8C4.86538 14.8 2 11.9346 2 8.4C2 4.86538 4.86538 2 8.4 2ZM8.8999 5.4001C8.8999 5.12395 8.67604 4.9001 8.3999 4.9001C8.12376 4.9001 7.8999 5.12395 7.8999 5.4001V9.4001V9.9001H8.3999H11.3999C11.676 9.9001 11.8999 9.67624 11.8999 9.4001C11.8999 9.12395 11.676 8.9001 11.3999 8.9001H8.8999V5.4001Z"
      fill="#F2994A"
    />
  </svg>
);
