import { FormControlLabel, Icon, IconButton, Stack, Switch, Typography } from '@mui/material';
import {
  ApiError,
  DEFAULT_DATE_FORMAT_FNS,
  ReportForAssessment,
  ReportStatuses,
  UpdateReportParams,
  useValidateReportMutation,
  ValidateReportError,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import {
  DateSelect,
  SubjectSelectMultiple,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { AgeGroupSelectMultiple } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { AssessmentStatuses, SchoolUserRole } from '@schooly/constants';
import {
  CrossIcon,
  ModalContent,
  ModalHeader,
  ModalHeaderInput,
  ModalMain,
  Spin,
} from '@schooly/style';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { format } from 'date-fns';
import isEqual from 'lodash.isequal';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ReportForm } from '../../../context/report/WithReportEditContext';
import { ReportCreateModalAreasOfLearning } from './ReportCreateModalAreasOfLearning';

export type InitiallyFocusedInput = 'subject_ids' | 'name' | 'scheduled_publish_date';

type FormStateContentProps = PropsWithChildren<{
  schoolId: string;
  isAdmin: boolean;
  hasActiveSubjects: boolean;
  hasActiveAgeGroups: boolean;
  subjectsConfigured: boolean;
  ageGroupsConfigured: boolean;
  onClose: () => void;
  onNext: (v: ReportForm) => void;
  renderFooter: (disabled: boolean) => React.ReactNode;
  form: UseFormReturn<ReportForm>;
  report?: ReportForAssessment;
  focusedInput?: InitiallyFocusedInput;
  onClearFocusedInput?: () => void;
}>;
export const FormStateContent: FC<FormStateContentProps> = ({
  schoolId,
  form,
  onClose,
  onNext,
  focusedInput: initialFocusField,
  ageGroupsConfigured,
  subjectsConfigured,
  isAdmin,
  hasActiveSubjects,
  hasActiveAgeGroups,
  renderFooter,
  children,
  report,
  onClearFocusedInput,
}) => {
  const { $t } = useIntl();

  useEffect(() => {
    if (initialFocusField) {
      onClearFocusedInput?.();
    }
  }, [initialFocusField, onClearFocusedInput]);

  const ageGroupIds = form.watch('age_group_ids');
  const subjectIds = form.watch('subject_ids');
  const withTutorFeedback = form.watch('with_tutor_feedback');
  const scheduledPublishDate = form.watch('scheduled_publish_date');
  const [previousSubjectIds, setPreviousSubjectIds] = useState<string[]>([]);
  const { validateReportAssessmentsEntries, isValidating } = useValidateReportAssessmentsEntries();

  const hasTutorsPublishedAssessment = useMemo(() => {
    return report?.assessments?.some(
      (a) =>
        a.generated_from_report_id === report.id &&
        a.autogenerated &&
        a.assessment_status === AssessmentStatuses.Published &&
        a.name.includes('Tutors'),
    );
  }, [report?.assessments, report?.id]);

  useEffect(() => {
    if (!ageGroupsConfigured || ageGroupIds.length || !hasActiveAgeGroups) return;

    const { subject_ids, with_tutor_feedback } = form.getValues();

    subject_ids.length && form.setValue('subject_ids', []);
    !!with_tutor_feedback && form.setValue('with_tutor_feedback', false);
  }, [form, ageGroupIds.length, ageGroupsConfigured, hasActiveAgeGroups]);

  useEffect(() => {
    if (subjectIds.length) return;

    const { areas_of_learning } = form.getValues();

    areas_of_learning.length && form.setValue('areas_of_learning', []);
  }, [form, subjectIds.length]);

  const shouldRenderAgeGroups = !!scheduledPublishDate && ageGroupsConfigured;
  const shouldRenderSubjects =
    !!scheduledPublishDate &&
    subjectsConfigured &&
    (!ageGroupsConfigured || ageGroupIds.length || !hasActiveAgeGroups);
  const shouldRenderTutorGroup =
    !!scheduledPublishDate && (!ageGroupsConfigured || ageGroupIds.length || !hasActiveAgeGroups);
  const shouldRenderAreas = shouldRenderSubjects && !!subjectIds.length;

  const handleSubjectsClose = useCallback(
    async (opened: boolean) => {
      if (!opened || !report) return;

      const isSubjectIdsAreEqual = !previousSubjectIds || isEqual(previousSubjectIds, subjectIds);
      if (isSubjectIdsAreEqual) return;

      const reportValues = form.getValues();

      const confirmed = await validateReportAssessmentsEntries({
        reportId: report.id,
        report: {
          ...reportValues,
          with_tutor_feedback: report.with_tutor_feedback,
          areas_of_learning: reportValues.areas_of_learning.filter((a) => !!a.assessment_id),
          assessment_ids: reportValues.assessments.map((a) => a.id),
          report_status: ReportStatuses.Unpublished,
        },
      });
      if (!confirmed) form.setValue('subject_ids', previousSubjectIds);
    },
    [form, previousSubjectIds, report, subjectIds, validateReportAssessmentsEntries],
  );

  const renderAgeGroups = () => {
    if (!shouldRenderAgeGroups) return null;

    return (
      <Stack gap={1}>
        <Typography variant="h2">{$t({ id: 'reports-WhatAgeGroup' })}</Typography>
        <Controller
          control={form.control}
          name="age_group_ids"
          render={({ field, fieldState }) => {
            return (
              <AgeGroupSelectMultiple
                schoolId={schoolId}
                isAdmin={isAdmin}
                requiredLabel="optional"
                onClear={() => field.onChange([])}
                disabled={
                  (ageGroupsConfigured && !hasActiveAgeGroups) ||
                  report?.report_status === ReportStatuses.Unpublished
                }
                placeholder={$t({ id: 'schoolProperty-AgeGroup-plural' })}
                userRole={SchoolUserRole.Student}
                selectedIds={ageGroupIds}
                error={fieldState.error}
                isLoading={isValidating}
                onSelect={(ids) => {
                  field.onChange(toggleMultipleValueArrayProperty(ageGroupIds, ids));
                }}
              />
            );
          }}
        />
      </Stack>
    );
  };

  const renderSubjects = () => {
    if (!shouldRenderSubjects) return null;
    const disabled = subjectsConfigured && !hasActiveSubjects;
    const required =
      subjectsConfigured && hasActiveSubjects && !withTutorFeedback && !!ageGroupIds.length;
    return (
      <Stack gap={1}>
        <Typography variant="h2">{$t({ id: 'reports-WhatSubjects' })}</Typography>
        <Controller
          control={form.control}
          name="subject_ids"
          rules={{ required }}
          render={({ field, fieldState }) => {
            return (
              <SubjectSelectMultiple
                schoolId={schoolId}
                disabled={disabled}
                isAdmin={isAdmin}
                onClear={
                  report?.report_status === ReportStatuses.Draft
                    ? () => field.onChange([])
                    : undefined
                }
                isLoading={isValidating}
                placeholder={$t({ id: 'groups-GroupSubject' })}
                requiredLabel={required ? 'required' : 'optional'}
                selectedIds={subjectIds}
                error={fieldState.error}
                opened={(initialFocusField === 'subject_ids' && !disabled) || undefined}
                onClose={handleSubjectsClose}
                onOpen={() => {
                  setPreviousSubjectIds(subjectIds);
                }}
                onSelectSubjectId={(id) =>
                  field.onChange(
                    subjectIds.includes(id)
                      ? subjectIds.filter((i) => i !== id)
                      : [...subjectIds, id],
                  )
                }
              />
            );
          }}
        />
      </Stack>
    );
  };

  const renderAreas = () => {
    if (!shouldRenderAreas) return null;

    return (
      <Stack gap={1}>
        <Typography variant="h2">{$t({ id: 'reports-WhatAreas' })}</Typography>
        <ReportCreateModalAreasOfLearning
          isAdmin={isAdmin}
          schoolId={schoolId}
          form={form}
          reportId={report?.id}
        />
      </Stack>
    );
  };

  return (
    <form onSubmit={form.handleSubmit(onNext)}>
      <ModalHeader
        active
        title={
          <Controller
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <ModalHeaderInput
                  placeholder={$t({ id: 'reports-ReportsName' })}
                  autoFocus={!field.value || initialFocusField === 'name'}
                  error={!!fieldState.error}
                  helperText={getControllerErrorText(fieldState.error, undefined, $t)}
                  data-test-id="report-title-input"
                  {...field}
                />
              );
            }}
          />
        }
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <Stack gap={4}>
            <Controller
              control={form.control}
              name="scheduled_publish_date"
              rules={{ required: true }}
              render={({ field, fieldState }) => {
                return (
                  <DateSelect
                    ref={field.ref}
                    onSetDate={(date) => {
                      field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                    }}
                    date={field.value}
                    placeholder={$t({ id: 'reports-ReportDate' })}
                    requiredLabel="required"
                    error={fieldState.error}
                    opened={initialFocusField === 'scheduled_publish_date' || undefined}
                    onClear={() => field.onChange('')}
                    testId={'report-date-select'}
                  />
                );
              }}
            />

            {renderAgeGroups()}
            {shouldRenderTutorGroup ? (
              <TutorGroupToggle
                form={form}
                hasTutorsPublishedAssessment={hasTutorsPublishedAssessment}
                report={report}
              />
            ) : null}
            {renderSubjects()}
            {renderAreas()}
          </Stack>
        </ModalContent>
      </ModalMain>
      {children}
      {renderFooter(isValidating)}
    </form>
  );
};

type TutorGroupToggleProps = {
  form: FormStateContentProps['form'];
  hasTutorsPublishedAssessment?: boolean;
  report?: ReportForAssessment;
};

const TutorGroupToggle: FC<TutorGroupToggleProps> = ({
  form,
  hasTutorsPublishedAssessment,
  report,
}) => {
  const { validateReportAssessmentsEntries, isValidating } = useValidateReportAssessmentsEntries();

  const handleValidate = async (newValue: boolean) => {
    if (!report) return;

    const reportValues = form.getValues();

    const confirmed = await validateReportAssessmentsEntries({
      reportId: report.id,
      report: {
        ...reportValues,
        with_tutor_feedback: newValue,
        subject_ids: report.subjects.map(({ id }) => id),
        areas_of_learning: reportValues.areas_of_learning.filter((a) => !!a.assessment_id),
        assessment_ids: reportValues.assessments.map((a) => a.id),
        report_status: ReportStatuses.Unpublished,
      },
    });
    if (!confirmed) form.setValue('with_tutor_feedback', !newValue);
  };

  const { $t } = useIntl();

  return (
    <Stack flexDirection="row" gap={3} justifyContent="space-between" alignItems="center">
      <Typography variant="h2">{$t({ id: 'reports-ShouldIncludeTutor' })}</Typography>

      <Controller
        control={form.control}
        name="with_tutor_feedback"
        render={({ field }) => {
          return (
            <FormControlLabel
              sx={{
                whiteSpace: 'nowrap',
                mr: 0,
                ...(isValidating && { color: 'primary.main', pointerEvents: 'none' }),
              }}
              label={$t({ id: 'reports-TutorsFeedback' })}
              control={
                <>
                  {isValidating && (
                    <Icon sx={{ mr: 1 }}>
                      <Spin />
                    </Icon>
                  )}

                  <Switch
                    checked={field.value}
                    sx={{
                      display: isValidating ? 'none' : 'inherit',
                    }}
                    disabled={hasTutorsPublishedAssessment}
                    onChange={() => {
                      const newValue = !field.value;
                      field.onChange(newValue);
                      !newValue && handleValidate(newValue);
                    }}
                  />
                </>
              }
            />
          );
        }}
      />
    </Stack>
  );
};

const useValidateReportAssessmentsEntries = () => {
  const validateReport = useValidateReportMutation();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();

  const validateReportAssessmentsEntries = useCallback(
    async (params: UpdateReportParams): Promise<boolean> => {
      try {
        await validateReport.mutateAsync(params);

        return true;
      } catch (error) {
        const err = error as ValidateReportError | ApiError;
        if ('assessments_to_remove' in err) {
          let count = 0;
          let groupNames = '';

          for (const assessment of err.assessments_to_remove) {
            count = count + assessment.entries;

            const assessmentGroupNames = assessment.groups.length
              ? assessment.groups.reduce((acc, next) => `${acc} ${acc ? ',' : ''} ${next.name}`, '')
              : '';

            groupNames = groupNames.concat(assessmentGroupNames);
          }

          if (!count) return true;

          const isConfirmed =
            (await getConfirmation({
              textId: 'reports-DeleteEntriesForGroups',
              textValues: {
                entriesCount: count,
                groupNames: groupNames,
              },
              onClick: (e) => e.stopPropagation(),
            })) ?? false;

          return isConfirmed;
        }

        showError(err);
        return false;
      }
    },
    [getConfirmation, showError, validateReport],
  );

  return { validateReportAssessmentsEntries, isValidating: validateReport.isLoading };
};
