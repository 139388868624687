import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3627 6.07018C14.645 6.79218 14.8 7.57799 14.8 8.4C14.8 11.9346 11.9346 14.8 8.4 14.8C6.01736 14.8 3.93882 13.498 2.83705 11.5667L6.17819 8.22552L8.83947 10.8864C9.03474 11.0816 9.3513 11.0816 9.54655 10.8863L14.3627 6.07018ZM13.8975 5.12121L9.19296 9.82571L6.53169 7.16486C6.33642 6.96963 6.01986 6.96964 5.82461 7.16489L2.38852 10.601C2.13717 9.9147 2 9.17337 2 8.4C2 4.86538 4.86538 2 8.4 2C10.7362 2 12.7801 3.25178 13.8975 5.12121Z"
      fill="#24275B"
    />
  </svg>
);
