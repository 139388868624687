import { IconButton, Stack, TableRow, Tooltip, Typography } from '@mui/material';
import { Event, EventsStatuses } from '@schooly/api';
import { RecurrenceInfo } from '@schooly/components/recurring';
import {
  CalendarSettingsIcon,
  GridBody,
  GridCell,
  HolidayIcon,
  SignUpIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../../../hooks/useAppLocation';
import {
  getEventDate,
  ParticipationCellContent,
  StatusCellContent,
} from '../../../Events/EventsGrid';
type GroupPreviewModalEventRowProps = {
  event: Event;
  groupId: string;
};
export const GroupPreviewModalEventRow: FC<GroupPreviewModalEventRowProps> = ({ event }) => {
  const location = useAppLocation();
  const { $t } = useIntl();

  const date = useMemo(() => getEventDate(event), [event]);
  const recurrenceId = event.recurring_state?.recurrence_id;
  const recurringState = event.recurring_state;

  return (
    <GridBody
      sx={(theme) => ({
        width: '100%',
        '&.MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
          borderBottom: theme.mixins.borderValue(),
        },

        '&:hover': {
          '& .EventsGridRow-event td': {
            '.MuiTypography-root:not(.status, .date)': {
              color: theme.palette.primary.main,
            },

            '.date': {
              color: theme.palette.common.grey2,
            },

            '.signup:not(:hover)': {
              color: theme.palette.text.primary,
            },

            '.status': {
              color:
                event.event_status === EventsStatuses.Past ||
                event.event_status === EventsStatuses.Draft
                  ? theme.palette.primary.main
                  : undefined,
            },
            backgroundColor: theme.palette.background.default,
          },
        },
      })}
    >
      <TableRow
        component={Link}
        to={`/events/${event.id}`}
        state={{ backgroundLocation: location }}
        className="EventsGridRow-event"
      >
        <GridCell pl={1} width={110}>
          <Stack whiteSpace="nowrap">
            <Typography className="date" color="common.grey">
              {date}
            </Typography>
          </Stack>
        </GridCell>
        <GridCell>
          <Stack direction="row" gap={0.5}>
            <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
              {event.title}
            </TypographyWithOverflowHint>
            {!!recurringState && recurrenceId && <RecurrenceInfo recurringState={recurringState} />}
            {event.event_type === 'period' && (
              <Tooltip
                title={$t({ id: 'events-SystemEventDescription' })}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      padding: theme.spacing(1.25),
                    }),
                  },
                }}
                disableInteractive
              >
                <IconButton inverse>
                  <CalendarSettingsIcon />
                </IconButton>
              </Tooltip>
            )}

            {event.event_type === 'holiday' && (
              <Tooltip
                title={$t({ id: 'event-holiday-hint' })}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: (theme) => ({
                      padding: theme.spacing(1.25),
                    }),
                  },
                }}
                disableInteractive
              >
                <IconButton inverse>
                  <HolidayIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </GridCell>
        <GridCell width={160}>
          <ParticipationCellContent event={event} />
        </GridCell>
        <GridCell width={50}>
          {event.sign_ups?.[0] && (
            <Link to={`/signups/${event.sign_ups[0].id}`}>
              <Tooltip title={event.sign_ups[0].title}>
                <IconButton className="signup" inverse>
                  <SignUpIcon />
                </IconButton>
              </Tooltip>
            </Link>
          )}
        </GridCell>
        <GridCell width={120}>
          <StatusCellContent event={event} />
        </GridCell>
      </TableRow>
    </GridBody>
  );
};
