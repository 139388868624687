import { Typography } from '@mui/material';
import { Product, ProductTypeId, SORT_DIRECTION, useGetProductsListQuery } from '@schooly/api';
import { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { renderProductTags, renderProductTypeTags } from './ProductExpandedSelect';
import { ProductSelectRow } from './ProductSelectRow';

type ProductSelectProps = {
  schoolId: string;
  selectedProductIds: string[];
  selectedProductTypeIds: ProductTypeId[];
  onSelectProductId: (v: string) => void;
  onSelectProductTypeId: (v: ProductTypeId) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const ProductSelectMultiple: FC<ProductSelectProps> = ({
  schoolId,
  selectedProductIds,
  selectedProductTypeIds,
  onSelectProductId,
  onSelectProductTypeId,
  placeholder,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [queryEnabled, setQueryEnabled] = useState(false);

  const { isLoading, data, params, isFetchingNextPage, fetchNextPage, hasNextPage, setParams } =
    useGetProductsListQuery(
      { schoolId, query: '', sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC } },
      { refetchOnMount: 'always', enabled: !!schoolId && queryEnabled },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Product[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    const handleSelectProductType = (typeId: ProductTypeId) => {
      const relatedProduct = entries.find((p) => p.id === typeId.productId);

      if (
        relatedProduct &&
        selectedProductTypeIds.length + 1 === relatedProduct.types.length &&
        !selectedProductTypeIds.some((id) => id.isEqual(typeId))
      ) {
        onSelectProductId(relatedProduct.id);
        return;
      }

      onSelectProductTypeId(typeId);
    };

    return (
      <>
        {entries.map((product) => (
          <ProductSelectRow
            onSelectProduct={(id) => {
              onSelectProductId(id);
              handleChangeQuery('');
            }}
            onSelectProductType={handleSelectProductType}
            key={product.id}
            product={product}
            selectedProductTypes={selectedProductTypeIds.filter(
              (id) => id.productId === product.id,
            )}
            isSelected={selectedProductIds.includes(product.id)}
          />
        ))}
      </>
    );
  }, [
    data,
    handleChangeQuery,
    onSelectProductId,
    onSelectProductTypeId,
    selectedProductIds,
    selectedProductTypeIds,
  ]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={selectedProductIds.length > 0 || selectedProductTypeIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {renderProductTags({
            ids: selectedProductIds,
            tagProps: { size: placeholder ? 'small' : undefined, sx: { maxWidth: 200 } },
            onDelete: opened ? onSelectProductId : undefined,
          })}
          {renderProductTypeTags({
            ids: selectedProductTypeIds,
            tagProps: { sx: { maxWidth: 200 }, size: 'small' },
            onDelete: onSelectProductTypeId,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};
