import { ChipProps } from '@mui/material';
import { InvoicesReportingDayPastDue } from '@schooly/api';
import { TagSelect } from '@schooly/style';
import { FC } from 'react';
import { useIntl } from 'react-intl';

export type DayPastDueTagSelectProps = {
  type: InvoicesReportingDayPastDue;
} & Omit<ChipProps, 'label'>;

export const DayPastDueTagSelect: FC<DayPastDueTagSelectProps> = ({ type, ...props }) => {
  const { $t } = useIntl();

  return <TagSelect label={$t({ id: `payableFees-${type}` })} {...props} />;
};
