import * as amplitude from '@amplitude/analytics-browser';
import {
  GET_SCHOOL_QUERY,
  School,
  SchoolProperty,
  useChangeLastSchoolMutation,
  useCheckSystemRoleQuery,
  useGetAttendanceCodesQuery,
  useGetNotificationAttendanceSettings,
  useGetSchoolYears,
  useUpdateSchoolMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { SchoolPropertiesMap, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { createContext, FC, PropsWithChildren, useEffect } from 'react';
import { useCallback, useMemo } from 'react';
import { useMatch } from 'react-router';

import { allowRenderSchoolSettings } from '../helpers/renderRoutesByPermissions';
import { SchoolTuneStatusType } from '../pages/School/SchoolTune/tabs/statuses/scheme';
import { clearReactQueryStorageCache, queryClient } from '../queryClient';

type UseSchoolContext = {
  schoolId?: string;
  currentSchool?: School;

  staffPropertiesMap: SchoolPropertiesMap;
  staffActivePropertiesMap: SchoolPropertiesMap;
  studentPropertiesMap: SchoolPropertiesMap;
  studentActivePropertiesMap: SchoolPropertiesMap;
  studentStatuses: SchoolTuneStatusType;
  staffStatuses: SchoolTuneStatusType;
  activeStudentStatuses: SchoolTuneStatusType;
  archivedStudentStatuses: SchoolTuneStatusType;
  activeStaffStatuses: SchoolTuneStatusType;
  archivedStaffStatuses: SchoolTuneStatusType;

  hasSchoolYears: boolean;
  hasStatuses: boolean;
  hasHouses: boolean;
  hasDepartments: boolean;
  hasSystemRole?: boolean;
  isSchoolAdmin: boolean;
  isFetching: boolean;
  isCompleted?: boolean;
  isSchoolPath: boolean;
  isSchoolChanging: boolean;
  updateSchoolMutation: ReturnType<typeof useUpdateSchoolMutation>;
  onSelectSchool: (v: string) => Promise<void>;

  staffPropertiesFetching: boolean;
  studentPropertiesFetching: boolean;
};

export const SchoolContext = createContext<UseSchoolContext>({
  schoolId: undefined,
  currentSchool: undefined,

  staffPropertiesMap: {} as SchoolPropertiesMap,
  staffActivePropertiesMap: {} as SchoolPropertiesMap,
  studentPropertiesMap: {} as SchoolPropertiesMap,
  studentActivePropertiesMap: {} as SchoolPropertiesMap,
  studentStatuses: {} as SchoolTuneStatusType,
  staffStatuses: {} as SchoolTuneStatusType,
  activeStudentStatuses: {} as SchoolTuneStatusType,
  archivedStudentStatuses: {} as SchoolTuneStatusType,
  activeStaffStatuses: {} as SchoolTuneStatusType,
  archivedStaffStatuses: {} as SchoolTuneStatusType,

  hasSchoolYears: false,
  hasStatuses: false,
  hasHouses: false,
  hasDepartments: false,
  hasSystemRole: false,
  isSchoolAdmin: false,
  isFetching: false,
  isCompleted: false,
  isSchoolPath: false,
  isSchoolChanging: false,
  updateSchoolMutation: (() => {}) as unknown as ReturnType<typeof useUpdateSchoolMutation>,
  onSelectSchool: async () => {},

  staffPropertiesFetching: false,
  studentPropertiesFetching: false,
});

export const getEmptyFormStatuses = (): SchoolTuneStatusType => ({
  prospective: [],
  current: [],
  former: [],
  unsuccessful: [],
});

export const getFormStatuses = (statuses?: SchoolProperty[]) => {
  const initialState = getEmptyFormStatuses();
  const addOriginId = (status: SchoolProperty) => ({ ...status, originId: status.id });

  if (!statuses) {
    return initialState;
  }

  return statuses.reduce((prev, status) => {
    switch (status.category?.name) {
      case 'Prospective':
        prev.prospective.push(addOriginId(status));
        break;
      case 'Current':
        prev.current.push(addOriginId(status));
        break;
      case 'Former':
        prev.former.push(addOriginId(status));
        break;
      case 'Unsuccessful':
        prev.unsuccessful.push(addOriginId(status));
        break;
    }

    return prev;
  }, initialState);
};

export const WithSchool: FC<PropsWithChildren> = ({ children }) => {
  const match = useMatch('/settings/*');
  const [isSchoolChanging, startChanging, stopChanging] = useFlag(false);

  const { schoolId = '', currentSchool, permissions } = useAuth();
  const { data: hasSystemRole, isLoading: systemRoleFetching } = useCheckSystemRoleQuery(schoolId, {
    enabled: !!schoolId,
  });
  const { showError } = useNotifications();
  const changeLastSchool = useChangeLastSchoolMutation();

  const handleSelectSchool = useCallback(
    async (id: string) => {
      if (currentSchool?.id === id) return;

      startChanging();
      await changeLastSchool.mutateAsync(id, {
        onSuccess: () => {
          clearReactQueryStorageCache();
          window.location.href = '/';
        },
        onError: (e) => {
          showError(e);
          stopChanging();
        },
      });
    },
    [currentSchool?.id, startChanging, changeLastSchool, showError, stopChanging],
  );

  const updateSchoolMutation = useUpdateSchoolMutation({
    onSettled: () => {
      queryClient.invalidateQueries([GET_SCHOOL_QUERY]);
    },
  });

  const { data, isLoading: schoolYearsFetching } = useGetSchoolYears(schoolId, {
    enabled: !!schoolId,
  });

  // PREFETCH SOME COMMON DATA that is not  //

  useGetNotificationAttendanceSettings({ schoolId }, { enabled: !!schoolId });
  useGetAttendanceCodesQuery(schoolId, { enabled: !!schoolId });

  const {
    propertiesMap: studentPropertiesMap,
    activePropertiesMap: studentActivePropertiesMap,
    isLoading: studentPropertiesFetching,
  } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Student,
    },
    { enabled: !!schoolId },
  );

  const {
    propertiesMap: staffPropertiesMap,
    activePropertiesMap: staffActivePropertiesMap,
    isLoading: staffPropertiesFetching,
  } = useSchoolProperties(
    {
      schoolId,
      userType: SchoolUserRole.Staff,
    },
    { enabled: !!schoolId },
  );

  const [
    studentStatuses,
    staffStatuses,
    activeStudentStatuses,
    archivedStudentStatuses,
    activeStaffStatuses,
    archivedStaffStatuses,
  ] = useMemo(() => {
    const studentStatuses = getFormStatuses(studentPropertiesMap.status);
    const staffStatuses = getFormStatuses(staffPropertiesMap.status);

    const filteredStudentStatuses = (
      Object.keys(studentStatuses) as (keyof SchoolTuneStatusType)[]
    ).reduce<{ active: SchoolTuneStatusType; archived: SchoolTuneStatusType }>(
      (prev, key) => {
        studentStatuses[key].forEach((status) => {
          if (status.archived) {
            prev.archived[key].push(status);
          } else {
            prev.active[key].push(status);
          }
        });
        return prev;
      },
      {
        active: {
          prospective: [],
          current: [],
          former: [],
          unsuccessful: [],
        },
        archived: {
          prospective: [],
          current: [],
          former: [],
          unsuccessful: [],
        },
      },
    );

    const filteredStaffStatuses = (
      Object.keys(staffStatuses) as (keyof SchoolTuneStatusType)[]
    ).reduce<{ active: SchoolTuneStatusType; archived: SchoolTuneStatusType }>(
      (prev, key) => {
        staffStatuses[key].forEach((status) => {
          if (status.archived) {
            prev.archived[key].push(status);
          } else {
            prev.active[key].push(status);
          }
        });
        return prev;
      },
      {
        active: {
          prospective: [],
          current: [],
          former: [],
          unsuccessful: [],
        },
        archived: {
          prospective: [],
          current: [],
          former: [],
          unsuccessful: [],
        },
      },
    );

    return [
      studentStatuses,
      staffStatuses,
      filteredStudentStatuses.active,
      filteredStudentStatuses.archived,
      filteredStaffStatuses.active,
      filteredStaffStatuses.archived,
    ];
  }, [staffPropertiesMap.status, studentPropertiesMap.status]);

  useEffect(() => {
    if (!currentSchool) return;
    amplitude.setGroup('school', currentSchool.name);
  }, [currentSchool]);

  const isSchoolPath = match != null;
  const isSchoolAdmin = allowRenderSchoolSettings(permissions);

  const hasSchoolYears = Boolean(data?.school_years.length);
  const hasStatuses =
    Boolean(studentPropertiesMap.status?.length) && Boolean(staffPropertiesMap.status?.length);

  const hasHouses = Boolean(studentPropertiesMap.house?.length);
  const hasDepartments = Boolean(studentPropertiesMap.department.length);

  const isFetching =
    schoolYearsFetching ||
    systemRoleFetching ||
    staffPropertiesFetching ||
    studentPropertiesFetching;

  const isCompleted = hasSchoolYears && hasStatuses && hasSystemRole;

  return (
    <SchoolContext.Provider
      value={{
        schoolId,
        currentSchool,

        staffPropertiesMap,
        staffActivePropertiesMap,
        studentPropertiesMap,
        studentActivePropertiesMap,
        studentStatuses,
        staffStatuses,
        activeStudentStatuses,
        archivedStudentStatuses,
        activeStaffStatuses,
        archivedStaffStatuses,

        hasSchoolYears,
        hasStatuses,
        hasHouses,
        hasDepartments,
        hasSystemRole,
        isSchoolAdmin,
        isFetching,
        isCompleted,
        isSchoolPath,
        isSchoolChanging,
        updateSchoolMutation,
        onSelectSchool: handleSelectSchool,

        staffPropertiesFetching,
        studentPropertiesFetching,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};
