import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const PRICE_SUBTEXT_CLASS_NAME = 'priceSubtext';

type PriceProps = {
  price: number;
  currency?: string;
  isOldPrice?: boolean;
  showDecimal?: boolean;
} & TypographyProps;

export const Price: FC<PriceProps> = ({
  price,
  currency,
  isOldPrice,
  showDecimal = true,
  ...props
}) => {
  const [integerStr, decimalStr] = price.toFixed(2).split('.');

  return (
    <>
      {currency && (
        <Typography
          component="span"
          variant="h3"
          sx={{
            opacity: 0.6,
            textDecoration: isOldPrice ? 'line-through' : undefined,
          }}
          {...props}
          className={PRICE_SUBTEXT_CLASS_NAME}
        >
          {currency}&nbsp;
        </Typography>
      )}
      <Typography
        component="span"
        variant="h3"
        sx={{ textDecoration: isOldPrice ? 'line-through' : undefined }}
        {...props}
      >
        {Number(integerStr).toLocaleString()}

        {showDecimal && (
          <Typography
            component="span"
            variant="inherit"
            className={PRICE_SUBTEXT_CLASS_NAME}
            sx={{ opacity: 0.6, textDecoration: isOldPrice ? 'line-through' : undefined }}
          >{`.${decimalStr}`}</Typography>
        )}
      </Typography>
    </>
  );
};
