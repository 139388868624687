import { Stack, Typography } from '@mui/material';
import { useGetDependantStudentsForParentQuery, useGetStudentProductsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, PlusIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import {
  PayableFees,
  PayableFeesHeaderTitle,
  PayableFeesRelationType,
} from '../../../components/common/PayableFees/PayableFees';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { ProfileModalMode } from '../../../context/profile/helpers';

type ProfilePayableFeesProps = {
  relationId: string;
};

export const ProfilePayableFeesParent: FC<ProfilePayableFeesProps> = ({ relationId }) => {
  const { permissions } = useAuth();
  const canView =
    (permissions.includes('product_and_invoice_viewer') &&
      //Temporary solution until TR-8046 is done
      permissions.includes('payer_and_product_assignment_viewer')) ||
    permissions.includes('registration_manager');

  const { data: students, isLoading: isLoadingStudents } = useGetDependantStudentsForParentQuery(
    { id: relationId },
    { enabled: canView },
  );

  if (!canView) {
    return <AccessDenied />;
  }

  if (!students?.length && !isLoadingStudents) {
    return <PayableFeesEmptyStub relationType="parent" />;
  }
  return (
    <>
      {!students || isLoadingStudents ? (
        <>
          <PayableFeesHeaderTitle />
          <Stack flex={1}>
            <Loading />
          </Stack>
        </>
      ) : (
        <PayableFees relationType="parent" id={relationId} />
      )}
    </>
  );
};

export const ProfilePayableFeesStudent: FC<ProfilePayableFeesProps> = ({ relationId }) => {
  const { permissions } = useAuth();
  const canView =
    (permissions.includes('product_and_invoice_viewer') &&
      //Temporary solution until TR-8046 is done
      permissions.includes('payer_and_product_assignment_viewer')) ||
    permissions.includes('registration_manager');

  const { data: studentProducts, isLoading: isProductsLoading } = useGetStudentProductsQuery(
    {
      relationId,
    },
    { refetchOnMount: 'always', enabled: canView },
  );

  const products = studentProducts?.products;
  const isLoading = isProductsLoading;

  // Temporary fix to display all school years in payable fees https://schooly.atlassian.net/browse/TR-8225
  // const schoolYears = useMemo(
  //   () =>
  //     data?.enrollments.reduce<SchoolYear[]>((acc, next) => {
  //       const yearExists = acc.find((y) => y.id === next.school_year.id);

  //       return yearExists ? acc : [...acc, next.school_year];
  //     }, []),
  //   [data?.enrollments],
  // );

  // const { data, isLoading: isEnrollmentsLoading } = useGetEnrollments(
  //   {
  //     schoolId,
  //     studentId: relationId,
  //   },
  //   { refetchOnMount: 'always', enabled: canView },
  // );

  if (!canView) {
    return <AccessDenied />;
  }

  return (
    <>
      {!products?.length || isLoading ? (
        <>
          {isLoading && (
            <>
              <PayableFeesHeaderTitle />
              <Stack flex={1}>
                <Loading />
              </Stack>
            </>
          )}
          {!isLoading && !products?.length && <PayableFeesEmptyStub relationType="student" />}
        </>
      ) : (
        <PayableFees relationType="student" id={relationId} />
      )}
    </>
  );
};

type PayableFeesEmptyStubProps = {
  relationType: PayableFeesRelationType;
};

const PayableFeesEmptyStub: FC<PayableFeesEmptyStubProps> = ({ relationType }) => {
  const { permissions } = useAuth();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const canCreateSingleInvoices = permissions.includes('product_and_invoice_creator');

  return (
    <EmptyStub
      tabNameTextId="profile-PayableFees"
      mode={ProfileModalMode.PayableFees}
      sx={{
        mt: 8,
      }}
      messageTextId={
        relationType === 'parent'
          ? 'profile-ThereAreNoPayableFeesForParent'
          : 'profile-ThereAreNoActiveProductAssignmentsForStudent'
      }
      buttonTextId="profile-AddSingleInvoice"
      onButtonClick={
        canCreateSingleInvoices ? () => navigate({ pathname: 'single-invoices' }) : undefined
      }
      buttonIcon={<PlusIcon />}
      textWidth={280}
    >
      {canCreateSingleInvoices && relationType === 'student' && (
        <Typography pt={2.5} variant="h3" color="common.grey2" width={320} textAlign="center">
          {formatMessage({ id: 'singleInvoices-CreateInvoice' })}
        </Typography>
      )}
    </EmptyStub>
  );
};
