import React, { createContext } from 'react';

import { AppLocation } from '../../hooks/useAppLocation';

export type ROUTER_BASE_PATHS =
  | 'adults'
  | 'children'
  | 'students'
  | 'parents'
  | 'staff'
  | 'companies'
  | 'groups'
  | 'assessments'
  | 'messages'
  | 'reports'
  | 'events'
  | 'signups'
  | 'payablefees'
  | 'userroles'
  | 'attendance'
  | 'conduct'
  | 'settings'
  | 'planner';

export interface RouterContextProps {
  backgroundPages: Record<ROUTER_BASE_PATHS, React.ReactNode>;
  getBackground: (location?: AppLocation) => { element: React.ReactNode; path: string };
  setBackground: (path: ROUTER_BASE_PATHS) => void;
}

export const RouterContext = createContext<RouterContextProps>({
  backgroundPages: {
    adults: null,
    children: null,
    students: null,
    parents: null,
    staff: null,
    companies: null,
    groups: null,
    assessments: null,
    messages: null,
    reports: null,
    events: null,
    signups: null,
    userroles: null,
    attendance: null,
    conduct: null,
    settings: null,
    planner: null,
    payablefees: null,
  },
  getBackground: () => ({ element: null, path: '/' }),
  setBackground: () => undefined,
});

RouterContext.displayName = 'RouterContext';
