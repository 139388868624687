import { Button, IconButton, Stack } from '@mui/material';
import {
  EventsStatuses,
  GET_SIGN_UP_QUERY,
  removeObjectEmptyArrayValues,
  SignUpStatuses,
  SignUpType,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { HTML_TAGS_REGEXP } from '@schooly/components/form-rich-text';
import { useNotifications } from '@schooly/components/notifications';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ButtonWithDescription,
  CrossIcon,
  DeleteIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  PlusIcon,
  PreviewModalCard,
  ShowHtml,
  Spin,
} from '@schooly/style';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ConsentForm,
  CreateConsentForm,
} from '../../../components/common/ConsentForms/ConsentForm';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { EventFormCriteria, WithEvent } from '../../../context/events/WithEvent';
import { useRouter } from '../../../context/router/useRouter';
import { SignUpForm, useSignUp } from '../../../context/signUps/WithSignUp';
import { SignUpDateSelector } from './SignUpDateSelector';
import { SignUpDescription } from './SignUpDescription';
import { SignUpDuration } from './SignUpDuration';
import { SignUpEventSelector } from './SignUpEventSelector';
import { SignUpInvitees } from './SignUpInvitees';
import { SignUpPlaces } from './SignUpPlaces';
import { SignUpTimeSlotsModal } from './SignUpTimeSlotsModal';
import { SignUpTypeSelector } from './SignUpTypeSelector';

const defaultValue: Omit<SignUpForm, 'id'> = {
  title: '',
  description: '',
  end: '',
  withEvent: true,
};

export const SignUpCreateContent: FC = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const { $t } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { goBack } = useRouter();
  const { showNotification } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const queryClient = useQueryClient();
  const {
    signUp,
    remove,
    create,
    update,
    updateList,
    updateAndPublish,
    creating,
    isActive,
    canCreate,
    canEdit,
    canDelete,
    removing,
    updating,
    publishing,
  } = useSignUp();

  const [showTimeSlotsModal, setShowTimeSlotsModal] = useState(false);
  const [publishSubmitted, setPublishSubmitted] = useState(false);
  const [consentFormShowed, showConsentForm, hideConsentForm] = useFlag();

  const disabled = creating || updating || removing || publishing;
  const isPublishedUpdate = signUp?.status === SignUpStatuses.Open || isActive;

  const initialValue = location.state?.initialState ?? defaultValue;

  const form = useForm<SignUpForm>({
    defaultValues: signUp
      ? {
          ...signUp,
          event_id: signUp?.event?.id ?? '',
          withEvent: Boolean(signUp?.event),
        }
      : {
          ...initialValue,
          event_id: initialValue.event?.id ?? '',
          withEvent: location.state?.initialState
            ? Boolean(initialValue.event)
            : initialValue.withEvent,
        },
  });

  const event = form.watch('event');
  const withEvent = form.watch('withEvent');
  const consentForm = useForm<CreateConsentForm>({
    defaultValues: {
      description: signUp?.consent_form?.description ? signUp.consent_form.description : '',
    },
    mode: 'onChange',
  });

  const consentFormDescriptionHtml = consentForm.watch('description');
  const consentFormDescriptionText = consentFormDescriptionHtml
    ? consentFormDescriptionHtml.replaceAll(HTML_TAGS_REGEXP, '')
    : '';

  const handleHideConsentForm = useCallback(() => {
    consentForm.reset();
    hideConsentForm();
  }, [consentForm, hideConsentForm]);

  const canPublish = !withEvent || event?.event_status === EventsStatuses.Published;

  const showEventSelector = !signUp?.id || signUp?.status === SignUpStatuses.Draft || withEvent;

  useEffect(() => {
    // OMG! Why does the InviteeCriteria component operate by `group` as the BE operate by `groups`?
    // it goes from EventCreateModal and leads to such an ugly adjustments
    const { groups, ...other } = signUp?.criteria ?? {};
    const criteria = (
      signUp?.criteria
        ? removeObjectEmptyArrayValues({ ...other, group: groups })
        : { student_status: [] }
    ) as EventFormCriteria;

    form.reset(
      signUp
        ? {
            ...signUp,
            event_id: signUp.event?.id ?? '',
            withEvent: Boolean(signUp.event),
            criteria,
          }
        : {
            ...initialValue,
            event_id: initialValue.event?.id ?? '',
            withEvent: location.state?.initialState
              ? Boolean(initialValue.event)
              : initialValue.withEvent,
          },
    );
  }, [form, initialValue, location.state?.initialState, signUp]);

  const handlePublish = useCallback(
    async (e: SignUpForm) => {
      if (!publishSubmitted) setPublishSubmitted(true);

      const isConfirmed = await getConfirmation({
        textId: 'eventSignUps-notification-NotifyAfterAction',
        textValues: {
          action: 'publishing',
        },
      });

      const consentFormPayload = consentFormShowed ? consentForm.getValues() : undefined;

      const res = await updateAndPublish({
        ...e,
        notify_parents: Boolean(isConfirmed),
        consentFormPayload,
      });
      if (!res) return;

      updateList();
      showNotification({
        textId: 'eventSignUps-notification-Published',
        type: 'success',
        values: { name: e.title },
        actions: [
          {
            textId: 'eventSignUps-notification-View',
            handler: () => navigate(`/signups/${res.id}`),
            buttonColor: 'light',
          },
        ],
      });

      goBack();
      return;
    },
    [
      consentForm,
      consentFormShowed,
      getConfirmation,
      goBack,
      navigate,
      publishSubmitted,
      showNotification,
      updateAndPublish,
      updateList,
    ],
  );

  const onSubmit = useCallback<SubmitHandler<SignUpForm>>(
    async (value, event) => {
      if (event?.target !== formRef.current) {
        return;
      }

      const nativeEvent = event?.nativeEvent;
      if (consentFormShowed) {
        const isValid = await consentForm.trigger();
        if (!isValid) return;
      }

      if (nativeEvent instanceof SubmitEvent) {
        const publishAction = Boolean(nativeEvent.submitter?.hasAttribute('data-publish'));

        const consentFormPayload = consentFormShowed ? consentForm.getValues() : undefined;

        if (publishAction) {
          handlePublish(value);
          return;
        }

        if (!signUp?.id) {
          const res = await create({ ...value, consentFormPayload });

          if (!res?.id) {
            return;
          }

          showNotification({
            textId: 'eventSignUps-notification-Created',
            type: 'success',
            values: { name: value.title },
            actions: [
              {
                textId: 'eventSignUps-notification-View',
                handler: () => navigate(`/signups/${res.id}`),
                buttonColor: 'light',
              },
            ],
          });
          updateList();
          goBack();
          return;
        }

        const isConfirmed = isActive
          ? (await getConfirmation({
              textId: 'eventSignUps-notification-NotifyAfterUpdate',
            })) ?? false
          : false;

        if (isPublishedUpdate) {
          setPublishSubmitted(true);
        }

        await update({ ...value, notify_parents: isConfirmed, consentFormPayload });

        updateList();
        goBack();
        queryClient.invalidateQueries([GET_SIGN_UP_QUERY, signUp.id]);
      }
    },
    [
      consentForm,
      consentFormShowed,
      create,
      getConfirmation,
      goBack,
      handlePublish,
      isActive,
      isPublishedUpdate,
      navigate,
      queryClient,
      showNotification,
      signUp?.id,
      update,
      updateList,
    ],
  );

  const onRemoveEvent = useCallback(async () => {
    if (!signUp?.id || !canDelete) {
      return;
    }

    const linkedConsentForm = signUp.consent_form;

    if (linkedConsentForm?.id) {
      const isConfirmed = await getConfirmation({
        textId: 'consentForms-deleteSignUpWithConsentForm',
      });

      if (!isConfirmed) return;
    }

    await remove(signUp.id);

    updateList();

    navigate('/signups');
  }, [canDelete, getConfirmation, navigate, remove, signUp?.consent_form, signUp?.id, updateList]);

  const footerContent = useMemo(() => {
    if (isPublishedUpdate) {
      return (
        <Button
          type="submit"
          startIcon={updating && <Spin />}
          disabled={disabled}
          data-test-id="modal-update"
        >
          <FormattedMessage id="action-Update" />
        </Button>
      );
    }

    return (
      <>
        {signUp?.id && signUp.status === SignUpStatuses.Draft ? (
          <Button
            startIcon={removing ? <Spin /> : <DeleteIcon />}
            variant="outlined"
            disabled={disabled}
            onClick={onRemoveEvent}
            data-test-id="modal-delete"
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        ) : null}

        <Stack direction="row" gap={1}>
          <Button
            key={`save-${canPublish}`}
            type="submit"
            startIcon={(updating || creating) && <Spin />}
            disabled={disabled}
            data-test-id="modal-submit"
            variant={canPublish ? 'outlined' : 'contained'}
          >
            <FormattedMessage id="action-Save" />
          </Button>
          {canPublish && (
            <Button
              startIcon={publishing && <Spin />}
              disabled={disabled}
              data-test-id="modal-Publish"
              type="submit"
              data-publish
            >
              <FormattedMessage id="action-Publish" />
            </Button>
          )}
        </Stack>
      </>
    );
  }, [
    isPublishedUpdate,
    signUp?.id,
    signUp?.status,
    removing,
    disabled,
    onRemoveEvent,
    canPublish,
    updating,
    creating,
    publishing,
  ]);

  const linkedConsentForm = event?.consent_form ?? signUp?.consent_form;

  const isDraft = signUp?.id ? signUp.status === SignUpStatuses.Draft : true;

  const canCreateConsentForm = isDraft && !linkedConsentForm;

  if ((signUp?.id && !canEdit) || (!signUp?.id && !canCreate)) {
    return <ModalAccessDenied />;
  }

  return (
    <FormProvider {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onSubmit, () => consentForm.trigger())}>
        <ModalHeader
          active
          title={
            <ControlModalHeaderInput
              control={form.control}
              name="title"
              rules={{ required: true }}
              placeholder={$t({ id: 'eventSignUps-create-TitlePlaceholder' })}
              autoFocus
            />
          }
          sx={{
            ' .MuiStack-root': { gap: 0.5 },
          }}
        >
          <IconButton onClick={goBack}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Stack gap={2.5} sx={{ overflowX: 'hidden', height: '100%' }}>
              {showEventSelector && (
                <SignUpEventSelector hasConsentForm={!!consentFormDescriptionText} />
              )}

              <SignUpDescription />

              <Stack direction="row" gap={1}>
                <SignUpTypeSelector onNoEventDateTimes={() => setShowTimeSlotsModal(true)} />
                <SignUpDateSelector />
                <SignUpPlaces />
                <SignUpDuration />

                {showTimeSlotsModal && (
                  <WithEvent id={form.getValues('event.id')} event={form.getValues('event')}>
                    <SignUpTimeSlotsModal
                      onCancel={() => {
                        setShowTimeSlotsModal(false);
                        if (signUp?.type === SignUpType.Slots) {
                          form.setValue('type', null);
                        } else {
                          form.resetField('type');
                        }
                      }}
                      onSave={() => {
                        setShowTimeSlotsModal(false);
                      }}
                    />
                  </WithEvent>
                )}
              </Stack>

              <SignUpInvitees />

              <Stack pt={1.5}>
                {!consentFormShowed && linkedConsentForm && (
                  <PreviewModalCard
                    path={`/consent-form/${linkedConsentForm.id}`}
                    title={$t({ id: 'consentForms-title' })}
                  >
                    <ShowHtml html={linkedConsentForm.description} />
                  </PreviewModalCard>
                )}
                <ConsentForm
                  visible={consentFormShowed}
                  onDelete={handleHideConsentForm}
                  consentForm={consentForm}
                />

                {canCreateConsentForm && !consentFormShowed && (
                  <ButtonWithDescription
                    onClick={showConsentForm}
                    startIcon={<PlusIcon />}
                    oneLine
                    title={$t({ id: 'consentForms-title' })}
                    description={$t({ id: 'consentForms-titleDescription' })}
                  />
                )}
              </Stack>
            </Stack>
          </ModalContent>
        </ModalMain>
        <ModalFooter
          active
          sx={{
            justifyContent: isPublishedUpdate || !canDelete ? 'flex-end' : 'space-between',
          }}
        >
          {footerContent}
        </ModalFooter>
      </form>
    </FormProvider>
  );
};
